import { fetchEventRecipients } from 'shared/repositories/eventRecipientsRepository';
import * as assetEventsActionTypes from 'single-asset/actions/eventRecipientActionTypes';
import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';

export const fetchRecipientInfo = asyncHookActionCreator({
  PENDING_ACTION_TYPE: assetEventsActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS,
  SUCCESS_ACTION_TYPE: assetEventsActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS_SUCCEEDED,
  FAILED_ACTION_TYPE: assetEventsActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS_FAILED,
  repositoryAPI: fetchEventRecipients,
});

import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IoMenuOutline } from 'react-icons/io5';

const MobileHeader = ({ navLinks, activeLink, mobileVisibility }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    if (mobileVisibility === 'none') setMobileMenuOpen(false);
  }, [mobileVisibility]);

  return (
    <nav className="mobile-header">
      {
          !mobileMenuOpen ? (
            <IoMenuOutline
              onClick={() => setMobileMenuOpen(true)}
              className="menu-outline"
              size={50}
            />
          ) : (
            <div className={`${mobileMenuOpen ? 'open-menu' : ''}`}>
              <div
                className="void-space"
                role="button"
                aria-label="Close mobile navigation"
                tabIndex={0}
                onClick={() => setMobileMenuOpen(false)}
              />
              <div className="mobile-menu">
                <div className="link-container">
                  {
                    navLinks.map((linkItem) => {
                      const isActive = activeLink.key === linkItem.key;
                      return (
                        <div
                          key={linkItem.key}
                          className={`mobile-links ${isActive ? 'active-link' : ''}`}
                        >
                          <NavLink
                            to={linkItem.path}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {linkItem.label}
                          </NavLink>
                        </div>
                      );
                    })
                  }
                </div>
                <div className="mobile-bottom">
                  <div className={`${activeLink.key === 'Settings' ? 'active-link' : ''}`}>
                    <NavLink
                      to="/settings"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Account Settings
                    </NavLink>
                  </div>
                  <div>
                    <button onClick={() => {
                      logout({ returnTo: window.location.origin });
                      setMobileMenuOpen(false);
                    }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
    </nav>
  );
};

export default MobileHeader;

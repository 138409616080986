import {
  IoCloseOutline,
  IoTimeOutline,
  IoRepeatOutline,
} from 'react-icons/io5';
import dayjs from 'dayjs';
import { Input } from 'antd';

import FluxDropdown from 'shared/components/FluxDropdown';
import { datePickerTypes } from 'shared/constants/graphingAndStatisticsConstants';

import {
  WEEKDAYS,
  TIME_OPTIONS,
  FREQUENCY_OPTIONS,
  START_DATE_SUBHEADING,
} from 'reporting/constants/reportingConstants';
import NewReportDatePicker from 'reporting/components/new-report/NewReportDatePicker';

import './ScheduledReportOptions.scss';

const ScheduledReportOptions = ({
  finalizedDates,
  setFinalizedDates,
  scheduledReportOptions,
  setScheduledReportOptions,
  errored = { },
}) => {
  const { field = '' } = errored;
  const titleHasError = field === 'title' && !scheduledReportOptions.title;
  const frequencyHasError = field === 'frequency' && !scheduledReportOptions.frequency;
  const timeHasError = field === 'time' && !scheduledReportOptions.time;

  const now = dayjs();
  const timeIsLaterThanNow = scheduledReportOptions?.time?.value ? (
    parseInt(scheduledReportOptions?.time?.value.split(':').at(0), 10) > now.get('hour')
    || (
      parseInt(scheduledReportOptions?.time?.value.split(':').at(0), 10) === now.get('hour')
      && parseInt(scheduledReportOptions?.time?.value.split(':').at(1), 10) > now.get('minute')
    )
  ) : false;

  const handleTitleChange = ({ target: { value } }) => {
    setScheduledReportOptions({
      ...scheduledReportOptions,
      title: value,
    });
  };

  const handleTimeChange = (_, option) => {
    setScheduledReportOptions({
      ...scheduledReportOptions,
      time: option,
    });
  };

  const handleFrequencyChange = (_, option) => {
    setScheduledReportOptions({
      ...scheduledReportOptions,
      frequency: option,
    });
  };

  const handleWeekdayChange = (_, option) => {
    setScheduledReportOptions({
      ...scheduledReportOptions,
      weekday: option,
    });
  };

  const disabledStartDate = scheduledReportOptions?.frequency?.value === 'Daily';
  const disabledDateValue = parseInt(scheduledReportOptions?.time?.value.split(':').at(0), 10) > now.get('hour')
    ? 'Today' : 'Tomorrow';

  return (
    <div className="scheduled-report-options">
      <h3 className={titleHasError ? 'errored-heading' : ''}>
        Title {' '}
        <span className="required-asterisk">*</span>
      </h3>
      <Input
        className={`report-title ${titleHasError ? 'errored' : ''}`}
        size="large"
        placeholder="My Report Title"
        allowClear={{
          clearIcon: <IoCloseOutline />,
        }}
        onChange={handleTitleChange}
        value={scheduledReportOptions?.title ?? ''}
      />
      <div className="date-time-container">
        <div>
          <h3 className={frequencyHasError ? 'errored-heading' : ''}>
            Frequency { ' ' }
            <span className="required-asterisk">*</span>
          </h3>
          <p className="section-subtext">
            {START_DATE_SUBHEADING}
          </p>
          <FluxDropdown
            label="Choose a frequency"
            options={FREQUENCY_OPTIONS}
            placeholder="Select Frequency"
            icon={<IoRepeatOutline size={28} />}
            handleChange={handleFrequencyChange}
            value={scheduledReportOptions.frequency?.label}
            erroredClass={frequencyHasError ? 'errored' : ''}
          />
        </div>
        {
          scheduledReportOptions?.frequency?.value === 'Monthly'
            ? (
              <NewReportDatePicker
                errored={errored}
                finalizedDates={finalizedDates}
                popupClasses="flexed single-calendar"
                setFinalizedDates={setFinalizedDates}
                pickerType={datePickerTypes.SINGLE}
                timeIsLaterThanNow={timeIsLaterThanNow}
              />
            ) : (
              <div>
                <h3>
                  Start Date { ' ' }
                  <span className="required-asterisk">*</span>
                </h3>
                <p className="section-subtext">
                  When you would like your scheduled report to begin.
                </p>
                <FluxDropdown
                  options={WEEKDAYS}
                  popupClasses="flexed weekdays"
                  label="Choose Send Day"
                  placeholder="Select Day"
                  disabled={disabledStartDate}
                  icon={<IoTimeOutline size={28} />}
                  handleChange={handleWeekdayChange}
                  erroredClass={field === 'startTime' ? 'errored' : ''}
                  value={disabledStartDate ? disabledDateValue : scheduledReportOptions.weekday?.label}
                />
              </div>
            )
        }
      </div>
      <div className="time-selector">
        <h3 className={timeHasError ? 'errored-heading' : ''}>
          Time { ' ' }
          <span className="required-asterisk">*</span>
        </h3>
        <p className="section-subtext">
          The time each report will be generated
        </p>
        <FluxDropdown
          options={TIME_OPTIONS}
          label="Choose send time"
          placeholder="Select Time"
          icon={<IoTimeOutline size={28} />}
          handleChange={handleTimeChange}
          value={scheduledReportOptions.time?.label}
          erroredClass={field === 'time' ? 'errored' : ''}
        />
      </div>
    </div>
  );
};

export default ScheduledReportOptions;

import * as eventRecipientActionTypes from 'single-asset/actions/eventRecipientActionTypes';

export const defaultState = {
  isPending: false,
  eventRecipients: [],
};

const eventRecipientReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case eventRecipientActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case eventRecipientActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS_FAILED: {
      const { errorResponse } = action;

      return {
        ...defaultState,
        errorResponse,
      };
    }

    case eventRecipientActionTypes.EVENT_RECIPIENTS__FETCH_RECIPIENTS_SUCCEEDED: {
      return {
        ...defaultState,
        eventRecipients: action.result,
      };
    }

    default: {
      return state;
    }
  }
};

export default eventRecipientReducer;

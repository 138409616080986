import * as telemetryChartsActionTypes from 'single-asset/actions/telemetryChartsActionsTypes';
import { createChartSeriesReducer } from 'single-asset/helpers/singleAssetHelpers';
import { defaultSeriesState as defaultState } from 'single-asset/constants/singleAssetConstants';

export { defaultState };

const telemetryChartsReducer = createChartSeriesReducer({
  PENDING_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST,
  SUCCESS_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST_FAILED,
  RESET_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__RESET,
  PENDING_MODIFY: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS,
  SUCCESS_MODIFY: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS_SUCCESS,
  FAILED_MODIFY: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS_FAILED,
  RESET_REFETCH: telemetryChartsActionTypes.TELEMETRY_CHARTS__RESET_REFETCH_CHARTS,
  RESET_ERROR: telemetryChartsActionTypes.TELEMETRY_CHART__RESET_ERROR,
  defaultState,
});

export default telemetryChartsReducer;

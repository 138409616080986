import {
  GLU_ASSET_TYPE_ID,
  GLX_ASSET_TYPE_ID,
  MINIMUM_RUN_TIME_PERCENTAGE,
  MISSING_FIELD_VALUE_PLACEHOLDER,
  TELEMETRY_STATS,
} from 'shared/constants/altitudeConstants';
import { connectivityTooltips } from 'shared/constants/assetConstants';
import { getStatusClassAndText } from 'shared/helpers/assetHelpers';

export const isGasLiftAltitude = ({ asset_type_id, assetTypeId }) => [GLU_ASSET_TYPE_ID, GLX_ASSET_TYPE_ID].includes(asset_type_id || assetTypeId);

export const getSingleAssetBaseRoute = ({
  org_id,
  orgId,
  site_id,
  siteId,
  asset_id,
  assetId,
  device_id,
  deviceId,
}) => `/asset/${(org_id || orgId)}/${(site_id || siteId)}/${(asset_id || assetId)}/${(device_id || deviceId)}`;

export const getSingleAssetDefaultRoute = (asset) => `${getSingleAssetBaseRoute(asset)}${isGasLiftAltitude(asset) ? '/gas_lift' : ''}`;

export const getAssetLinkUrl = (asset) => `/asset/${asset.org_id}/${asset.site_id}/${asset.asset_id}/${asset.device_id}`;

export const getRunTimePercentageClassAndText = (percentage) => ([
  percentage >= MINIMUM_RUN_TIME_PERCENTAGE
    ? 'ok' : 'error',
  !percentage || percentage !== MISSING_FIELD_VALUE_PLACEHOLDER ? `${percentage}%` : MISSING_FIELD_VALUE_PLACEHOLDER,
]);

export const getAssetStatusClassAndTooltip = (ipAddress, connectivityStatus) => {
  if (!ipAddress) return ['error', connectivityTooltips.disconnected];
  if (connectivityStatus === 'connected') return ['ok', connectivityTooltips.connected];
  if (connectivityStatus === 'disconnected') return ['error', connectivityTooltips.disconnected];
  return ['unknown', connectivityTooltips.unknown];
};

export const getAdditionalTelemetryStyling = (title, value) => {
  if (title === TELEMETRY_STATS.rtp) return value !== '_' ? getRunTimePercentageClassAndText(value).at(0) : '';
  if (title === TELEMETRY_STATS.status) return getStatusClassAndText(value).at(0);
  return '';
};

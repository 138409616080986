import * as singleAssetActionTypes from 'single-asset/actions/singleAssetActionTypes';
import assetReducer from 'single-asset/reducers/assetReducer';
import wellProductionReducer, { defaultState as glxProduction } from 'single-asset/reducers/wellProductionReducer';
import telemetryConfigReducer, { defaultState as config } from 'single-asset/reducers/telemetryConfigReducer';
import telemetryStatsReducer, { defaultState as stats } from 'single-asset/reducers/telemetryStatsReducer';
import gasLiftStatsReducer, { defaultState as gasLiftStats } from 'single-asset/reducers/gasLiftStatsReducer';
import wellStatsReducer, { defaultState as wellStats } from 'single-asset/reducers/wellStatsReducer';
import wellConfigurationReducer, { defaultState as wellConfiguration } from 'single-asset/reducers/wellConfigurationReducer';
import telemetryChartsReducer, { defaultState as charts } from 'single-asset/reducers/telemetryChartsReducer';
import telemetryEventsReducer, { defaultState as events } from 'single-asset/reducers//telemetryEventsReducer';
import enterpriseObjectReducer, { defaultState as enterpriseObject } from 'single-asset/reducers/enterpriseObjectReducer';
import telemetryChartMetricsReducer, { defaultState as metrics } from 'single-asset/reducers/telemetryChartMetricsReducer';
import workOrdersReducer, { defaultState as workOrders } from 'single-asset/reducers/workOrdersReducer';
import eventRecipientReducer, { defaultState as eventRecipients } from 'single-asset/reducers/eventRecipientReducer';
import unitProfileInfoReducer, { defaultState as unitProfileInfo } from 'single-asset/reducers/moreInfoStatsReducer';
import unitProfileImageReducer, { defaultState as unitProfileImage } from 'single-asset/reducers/moreInfoImageReducer';
import telemetryChartsRangeReducer, { defaultState as chartsRange } from 'single-asset/reducers/telemetryChartsRangeReducer';

export const defaultState = {
  asset: {},
  gasLift: {
    production: glxProduction,
    wellStats,
    wellConfiguration,
    stats: gasLiftStats,
  },
  telemetry: {
    config,
    stats,
    charts,
    events,
    metrics,
    chartsRange,
  },
  enterpriseObject,
  workOrders,
  eventRecipients,
  unitProfileInfo,
  unitProfileImage,
};

const singleAssetReducer = (state = defaultState, action = {}) => (
  action.type === singleAssetActionTypes.SINGLE_ASSET__CLEAR_STATE
    ? defaultState
    : {
      asset: assetReducer(state.asset, action),
      gasLift: {
        production: wellProductionReducer(state?.gasLift?.production, action),
        wellStats: wellStatsReducer(state?.gasLift?.wellStats, action),
        wellConfiguration: wellConfigurationReducer(state?.gasLift?.wellConfiguration, action),
        stats: gasLiftStatsReducer(state?.gasLift?.stats, action),
      },
      telemetry: {
        config: telemetryConfigReducer(state?.telemetry?.config, action),
        stats: telemetryStatsReducer(state?.telemetry?.stats, action),
        charts: telemetryChartsReducer(state?.telemetry?.charts, action),
        events: telemetryEventsReducer(state?.telemetry?.events, action),
        metrics: telemetryChartMetricsReducer(state?.telemetry?.metrics, action),
        chartsRange: telemetryChartsRangeReducer(state?.telemetry?.chartsRange, action),
      },
      enterpriseObject: enterpriseObjectReducer(state.enterpriseObject, action),
      workOrders: workOrdersReducer(state.workOrders, action),
      eventRecipients: eventRecipientReducer(state.eventRecipients, action),
      unitProfileInfo: unitProfileInfoReducer(state.unitProfileInfo, action),
      unitProfileImage: unitProfileImageReducer(state.unitProfileImage, action),
    });

export default singleAssetReducer;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getNavLinks } from 'shared/constants/navItems';

import Header from './Header';
import SideNav from './SideNav';

import './SideNav.scss';

const AppNavigation = ({
  openProfileModal,
  user,
  canAccessReports,
  preferences,
}) => {
  const { pathname } = useLocation();
  const [navLinks, setNavLinks] = useState(getNavLinks(preferences?.homepage));

  const [activeLink, setActiveLink] = useState({
    path: pathname,
    key: navLinks.find((link) => pathname === link.path)?.key,
  });

  useEffect(() => {
    let activeKey = navLinks.find((link) => pathname === link.path)?.key;
    if (pathname.includes('asset') || pathname.includes('/dashboard')) activeKey = 'Dashboard';
    if (pathname.includes('reports')) activeKey = 'Reporting';
    setActiveLink({
      path: (pathname.includes('asset') || pathname.includes('/dashboard')) ? `/${preferences?.homepage ?? 'dashboard'}` : pathname,
      key: activeKey,
    });
  }, [pathname, navLinks]);

  useEffect(() => {
    setNavLinks(getNavLinks(preferences?.homepage));
  }, [preferences]);

  return (
    <>
      <Header
        user={user}
        navLinks={navLinks}
        activeLink={activeLink}
        openProfileModal={openProfileModal}
      />
      <SideNav
        navLinks={navLinks}
        activeLink={activeLink}
        preferences={preferences}
        canAccessReports={canAccessReports}
      />
    </>
  );
};

export default AppNavigation;

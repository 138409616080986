import React from 'react';

import FluxCustomDropdown from 'shared/components/FluxCustomDropdown';

import {
  vaporCompositionTableColumns,
  CARBON_CAPTURE_VAPOR_COMP_TITLE,
} from 'single-asset/constants/carbonCaptureConstants';
import { generateDataRows } from 'single-asset/helpers/carbonCalculatorHelpers';
import DisplayTable from 'single-asset/components/carbon-capture-calculator/modal/components/DisplayTable';

import './VaporCompositionRow.scss';

const VaporCompositionRow = ({
  details,
  showVaporCompositionRow,
  handleVaporCompositionToggle,
}) => {
  const tableDetails = generateDataRows(details);

  return (
    <div className="carbon-capture-vapor-comp-row">
      <FluxCustomDropdown
        isOpen={showVaporCompositionRow}
        header={CARBON_CAPTURE_VAPOR_COMP_TITLE}
        toggleDropdown={handleVaporCompositionToggle}
      >
        <DisplayTable
          dataRows={tableDetails}
          columns={vaporCompositionTableColumns}
        />
      </FluxCustomDropdown>
    </div>
  );
};

export default VaporCompositionRow;

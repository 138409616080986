import { useLocation } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';

import AssetList from 'dashboard/components/assetDisplay/AssetList';
import FluxStatList from 'shared/components/FluxStatList';
import { assetFieldNames } from 'shared/constants/altitudeConstants';
import {
  allAssets,
  DEFAULT_ALTITUDE_GROUP_ID,
  myAssets,
} from 'dashboard/constants/dashboardConstants';
import DashboardHeader from 'dashboard/components/DashboardHeader';
import DashboardNavigation from 'dashboard/components/navigation/DashboardNavigation';
import AssetFilterDropdowns from 'dashboard/components/filtration/AssetFilterDropdowns';
import { filterAssetsByPathParams } from 'dashboard/helpers/dashboardHelpers';
import { dashboardStatConfig } from 'dashboard/constants/dashboardStatTagConfiguration';
import { MAX_FETCH_ATTEMPTS } from 'shared/constants/errorHandlingConstants';

import './DashboardPage.scss';
import { assetListStatusOptionValues } from 'shared/constants/assetConstants';

const DashboardPage = ({
  user,
  getStats,
  dashboard,
  preferences,
  orgId: org_id,
  updateHomepage,
  getFilterGroups,
  siteId: site_id,
  resetAltitudeGroup,
  selectAltitudeGroup,
  getFilteredAssetList,
  resetFilterGroupAttempts,
}) => {
  const { pathname } = useLocation();
  const { assetList, filters } = dashboard;
  const {
    altitudeFetchCount,
    altitudeIsPending,
    altitudeGroupId,
    assetListPerAltitudeGroups,
  } = assetList;
  const [filteredAssetList, setFilteredAssetList] = useState([]);
  const [assetListFilters, setAssetListFilters] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(assetListStatusOptionValues.ALL_UNITS);

  const filterConfig = useMemo(() => ({
    ...(org_id && { org_id: parseInt(org_id, 10) }),
    ...(site_id && { site_id: parseInt(site_id, 10) }),
  }), [org_id, site_id]);

  const fetchDashboardStats = (extraFilters = {}) => {
    const filterProp = {
      filters: altitudeGroupId === DEFAULT_ALTITUDE_GROUP_ID
        ? []
        : [altitudeGroupId],
    };
    getStats(dashboardStatConfig, { ...filterProp, ...extraFilters });
  };

  const getListOfAssetFilters = (shownAssetList) => {
    const listOfFilters = [];
    site_id && listOfFilters.push({
      type: assetFieldNames.SITE_NAME,
      label: shownAssetList?.at(0)?.[assetFieldNames.SITE_NAME],
    });
    org_id && listOfFilters.push({
      type: assetFieldNames.ORG_NAME,
      label: shownAssetList?.at(0)?.[assetFieldNames.ORG_NAME],
    });
    return listOfFilters;
  };

  useEffect(() => {
    fetchDashboardStats(filterConfig);
  }, [org_id, site_id, altitudeGroupId]);

  useEffect(() => {
    !filters.filterGroups.length && filters.fetchAttempts < MAX_FETCH_ATTEMPTS && !filters.isPending && getFilterGroups();
  }, [filters]);

  useEffect(() => {
    const assetListToFilter = assetListPerAltitudeGroups?.length ? assetListPerAltitudeGroups : dashboard?.assetList?.completeAssetList;
    const newFilteredAssetList = filterAssetsByPathParams(assetListToFilter, filterConfig);
    const listFilters = getListOfAssetFilters(newFilteredAssetList);

    setFilteredAssetList(newFilteredAssetList);
    setAssetListFilters(listFilters);
  }, [assetList, org_id, site_id]);

  useEffect(() => {
    if (altitudeGroupId !== DEFAULT_ALTITUDE_GROUP_ID) {
      !altitudeIsPending && !altitudeFetchCount && getFilteredAssetList({ filters: [altitudeGroupId] });
    }
  }, [altitudeGroupId]);

  useEffect(() => {
    filters.fetchAttempts >= MAX_FETCH_ATTEMPTS && resetFilterGroupAttempts();
  }, []);

  return (
    <div className="dashboard-page">
      <DashboardHeader user={user} />
      <div className="dashboard-page__content">
        <div>
          <AssetList
            assetList={filteredAssetList}
            assetListFilters={assetListFilters}
            filterGroups={filters.filterGroups}
            resetAltitudeGroup={resetAltitudeGroup}
            selectAltitudeGroup={selectAltitudeGroup}
            selectedStatusFilter={selectedStatusFilter}
            disabled={dashboard?.assetList?.isPending || altitudeIsPending}
          >
            {
            preferences?.assets?.length ? (
              <DashboardNavigation
                email={user?.email}
                preferences={preferences}
                updateHomepage={updateHomepage}
                location={pathname.includes(allAssets.pathname)
                  ? allAssets.pathname
                  : myAssets.pathname}
              />
            ) : <p className="context-title">All Assets</p>
            }
            <AssetFilterDropdowns
              selectedGroup={altitudeGroupId}
              filterGroups={filters.filterGroups}
              resetAltitudeGroup={resetAltitudeGroup}
              selectAltitudeGroup={selectAltitudeGroup}
              selectedStatusFilter={selectedStatusFilter}
              setSelectedStatusFilter={setSelectedStatusFilter}
            />
            <FluxStatList
              configList={dashboardStatConfig}
              statsList={dashboard.stats.statList}
              requestSuccess={dashboard.stats.requestSucceeded}
            />
          </AssetList>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

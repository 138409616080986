import React, { useRef } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

import './FluxCustomDropdown.scss';

const FluxCustomDropdown = ({
  header,
  isOpen,
  children = <div />,
  restOfProps = {},
  toggleDropdown,
}) => {
  const dropdownRef = useRef(null);

  return (
    <div
      ref={dropdownRef}
      {...restOfProps}
      className="flux-custom-dropdown-container"
    >
      <button className="flux-custom-header" onClick={toggleDropdown}>
        <p>{header}</p>
        {isOpen
          ? <IoChevronUp size={24} />
          : <IoChevronDown size={24} />}
      </button>
      {isOpen && (
        <div className="flux-custom-dropdown-content">
          {children}
        </div>
      )}
    </div>
  );
};

export default FluxCustomDropdown;

import { fetchTagById } from 'shared/repositories/axilTagRepository';
import * as telemetryStatsActionTypes from 'single-asset/actions/telemetryStatsActionTypes';
import { transformStatsRequest } from 'single-asset/transformers/telemetryTransformers';
import { transformFluxStatsResponse } from 'shared/transformers/fluxTransformers';
import { asyncBatchActionCreator, asyncBatchHookActionCreator } from 'shared/helpers/actionHelpers';
import { selectAsset } from 'single-asset/reducers/assetReducer';

export const fetchTelemetryStats = asyncBatchHookActionCreator({
  repositoryAPI: fetchTagById,
  requestTransformer: transformStatsRequest,
  responseTransformer: transformFluxStatsResponse,
  PENDING_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST,
  SUCCESS_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_FAILED,
  selector: selectAsset,
});

export const fetchWellStats = asyncBatchActionCreator({
  repositoryAPI: fetchTagById,
  requestTransformer: transformStatsRequest,
  responseTransformer: transformFluxStatsResponse,
  PENDING_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST,
  SUCCESS_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_FAILED,
  selector: selectAsset,
});

export const fetchGasLiftStats = asyncBatchActionCreator({
  repositoryAPI: fetchTagById,
  requestTransformer: transformStatsRequest,
  responseTransformer: transformFluxStatsResponse,
  PENDING_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_STATS__FETCH_STATS_LIST,
  SUCCESS_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_STATS__FETCH_STATS_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_STATS__FETCH_STATS_LIST_FAILED,
  selector: selectAsset,
});

export const resetAssetStats = () => ({
  type: telemetryStatsActionTypes.TELEMETRY_STATS__RESET_STATS_LIST,
});

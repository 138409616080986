import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'account-settings/actions/accountSettingsActionTypes';

export const defaultState = {
  isPending: false,
  updatePending: false,
  updateSuccessful: false,
  errorResponse: null,
  preferences: null,
  fetchAttempts: 0,
};

const handlePreferencesFetch = (state) => ({
  ...state,
  isPending: true,
});

const handlePreferencesFetchSuccess = (state, { result: preferences }) => ({
  ...state,
  isPending: false,
  errorResponse: null,
  preferences,
  phone_number: preferences.phone_number,
  fetchAttempts: state.fetchAttempts + 1,
});

const handlePreferencesFetchFailed = (state, { errorResponse }) => ({
  ...defaultState,
  errorResponse,
  fetchAttempts: state.fetchAttempts + 1,
});

const handleResetUpdateStatus = (state) => ({
  ...state,
  updateSuccessful: false,
});

const handlePreferencesUpdate = (state) => ({
  ...state,
  updatePending: true,
});

const handlePreferencesUpdateFailed = (state, { errorResponse }) => ({
  ...state,
  updatePending: false,
  errorResponse,
});

const handlePhoneUpdateSuccess = (state, { result: { phone_number } }) => ({
  ...state,
  updatePending: false,
  updateSuccessful: true,
  preferences: {
    ...state.preferences,
    phone_number,
  },
});

const handlePlatformUpdateSuccess = (state, { send_email, send_sms }) => ({
  ...state,
  updatePending: false,
  updateSuccessful: true,
  preferences: {
    ...state.preferences,
    send_email,
    send_sms,
  },
});

const handleDndUpdateSuccess = (state, { body: { dnd, dnd_stop, dnd_start } }) => ({
  ...state,
  updatePending: false,
  updateSuccessful: true,
  preferences: {
    ...state.preferences,
    dnd,
    dnd_start,
    dnd_stop,
  },
});

const handleResetPreferenceFetchAttempts = () => ({
  ...defaultState,
});

const userPreferencesSlice = createSlice({
  name: 'userPreference',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES, handlePreferencesFetch);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES_FAILED, handlePreferencesFetchFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES_SUCCESS, handlePreferencesFetchSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE, handlePreferencesUpdate);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE_FAILED, handlePreferencesUpdateFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE_SUCCESS, handlePhoneUpdateSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__RESET_UPDATE_STATUS, handleResetUpdateStatus);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM, handlePreferencesUpdate);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_FAILED, handlePreferencesUpdateFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_SUCCESS, handlePlatformUpdateSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB, handlePreferencesUpdate);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_FAILED, handlePreferencesUpdateFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_SUCCESS, handleDndUpdateSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__RESET_PREFERENCE_FETCH_ATTEMPTS, handleResetPreferenceFetchAttempts);
  },
});

export const selectUserPreferences = (state) => state.accountSettings.userPreferences;

export default userPreferencesSlice.reducer;

import * as actionTypes from 'single-asset/actions/constants/enterpriseObjectActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';
import { getEnterpriseObject } from 'shared/repositories/enterpriseObjectRepository';

export const fetchEnterpriseObject = asyncActionCreator({
  PENDING_ACTION_TYPE: actionTypes.ENTERPRISE_OBJECT__FETCH,
  FAILED_ACTION_TYPE: actionTypes.ENTERPRISE_OBJECT__FETCH_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ENTERPRISE_OBJECT__FETCH_SUCCEEDED,
  repositoryAPI: getEnterpriseObject,
});

export const updateEnterpriseObjectIpAddress = (ipAddress) => ({
  type: actionTypes.ENTERPRISE_OBJECT__UPDATE_IP,
  ipAddress,
});

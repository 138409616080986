import React from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

import './MathCard.scss';

const MathCard = ({
  value,
  label,
  handleClick,
  bordered = false,
  showDownArrow = false,
}) => (
  <div
    onClick={handleClick}
    className={`${showDownArrow ? 'math-card-clickable' : 'math-card'}${bordered ? '-bordered' : ''}`}
  >
    <div className="math-card-content">
      <p className="math-value">{value}</p>
      <p className="math-label">{label}</p>
    </div>
    {showDownArrow && <IoChevronDownOutline size="18px" color="#BCBCBC" />}
  </div>
);

export default MathCard;

import React, { useState, useEffect } from 'react';
import { IoGridOutline, IoMenuOutline } from 'react-icons/io5';

import {
  todayFullTime,
  todayFullDate,
  chartRangeMappings,
  oneDayAgo,
  oneHourAgo,
  getOffsetDateArr,
} from 'shared/helpers/datePickerHelpers';
import {
  chartRangeSelectOptions,
  DEFAULT_CHART_RANGE_LABEL,
} from 'shared/constants/datePickerConstants';
import FluxTooltip from 'shared/components/FluxTooltip';
import { extendedDayJs } from 'shared/helpers/timeHelpers';
import FluxDatePicker from 'shared/components/FluxDatePicker';

import {
  buildPartialLayoutBody,
  partialTemplateLayoutPayload,
  createNewChartSeriesConfiguration,
} from 'single-asset/helpers/telemetryHelpers';
import {
  formatTimeStringsForCharts,
  formatDateStringArrayAsMomentObjects,
} from 'single-asset/helpers/singleAssetHelpers';
import FluxCustomSelect from 'single-asset/components/charts/edit-charts/FluxCustomSelect';
import { TEMPLATE_CONFIRMATION_MODAL_TITLE } from 'single-asset/constants/telemetryConstants';
import ConfirmTemplateModal from 'single-asset/components/charts/edit-charts/ConfirmTemplateModal';

import EditChartsSection from 'single-asset/components/charts/edit-charts/EditChartsSection';
import ConfirmEditChartsSection from 'single-asset/components/charts/edit-charts/ConfirmEditChartsSection';

import './ChartForm.scss';

const ChartForm = ({
  asset,
  dates,
  defaultDates,
  modifyCharts,
  chartsLayout,
  legacyLayout,
  displayGridView,
  chartRangeState,
  isEditingCharts,
  getChartsByDate,
  resetChartsLayout,
  modifyChartsRange,
  setIsEditingCharts,
  setDisplayGridView,
  chartMetrics: metrics,
}) => {
  const defaultDatePickerValue = Array.isArray(dates)
    ? formatDateStringArrayAsMomentObjects(dates)
    : formatDateStringArrayAsMomentObjects(defaultDates);

  const customDateRangeOption = chartRangeSelectOptions.find((option) => option.label === 'Custom');
  const initialDateRangeOption = chartRangeSelectOptions.find((option) => option.label === DEFAULT_CHART_RANGE_LABEL);

  const [metricSearch, setMetricSearch] = useState('');
  const [newChartIsOpen, setNewChartIsOpen] = useState(false);
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [metricDropdownValues, setMetricDropdownValues] = useState([]);
  const [defaultChartRangeOption, setDefaultChartRangeOption] = useState(initialDateRangeOption);

  const chartFormHeaderText = !isEditingCharts ? 'Asset Charts' : 'Editing Chart Layout';
  const metricOptions = metrics?.chartMetrics?.map(({ name }) => ({ value: name, label: name }));

  const showDatesResetButton = isCustomSelected || defaultChartRangeOption?.label !== DEFAULT_CHART_RANGE_LABEL;

  const resetDefaultStates = () => {
    setMetricDropdownValues([]);
    setMetricSearch('');
  };

  const handleDropdownToggle = () => {
    newChartIsOpen && resetDefaultStates();
    setNewChartIsOpen(!newChartIsOpen);
  };

  const handleMetricSelection = (value) => {
    metricDropdownValues.includes(value)
      ? setMetricDropdownValues(metricDropdownValues.filter((m) => m !== value))
      : setMetricDropdownValues([...metricDropdownValues, value]);
  };

  const handleTemplateLayoutConfirmation = ({ templateFromCreate }) => {
    const templatePayload = {
      ...partialTemplateLayoutPayload,
      asset_type_id: asset.asset_type_id,
      layout: {
        ...legacyLayout,
        fluxCharts: templateFromCreate?.length ? templateFromCreate : chartsLayout,
      },
    };
    modifyCharts(templatePayload);
    !templateFromCreate && setConfirmModalIsOpen(false);
  };

  const handleChartCreation = (addToTemplate) => {
    const newChartMetrics = metricDropdownValues.reduce((acc, metricName) => (
      [...acc, metrics.chartMetrics.find((chartMetric) => chartMetric.name === metricName)]
    ), []);
    const chartSeriesData = createNewChartSeriesConfiguration(newChartMetrics);
    const chartCreationPayload = {
      ...buildPartialLayoutBody(asset),
      layout: {
        ...legacyLayout,
        fluxCharts: [chartSeriesData, ...chartsLayout],
      },
    };
    modifyCharts(chartCreationPayload);
    addToTemplate && handleTemplateLayoutConfirmation({
      templateFromCreate: [chartSeriesData, ...chartsLayout],
    });
    handleDropdownToggle(true);
  };

  const handleChartLayoutEdit = () => {
    const chartLayoutModificationPayload = {
      ...buildPartialLayoutBody(asset),
      layout: {
        ...legacyLayout,
        fluxCharts: chartsLayout,
      },
    };
    modifyCharts(chartLayoutModificationPayload);
    setIsEditingCharts(false);
  };

  const resetDatesToDefault = () => {
    setIsCustomSelected(false);
    modifyChartsRange(initialDateRangeOption?.label);
    const dateRange = getOffsetDateArr([
      extendedDayJs(oneDayAgo),
      extendedDayJs(todayFullTime),
    ]);
    handleDateRangeChange(
      dateRange,
    );
  };

  const handleDateRangeChange = (newDateRange) => {
    getChartsByDate(newDateRange, true);
  };

  const handleTimeRangeChange = (newDateRange) => {
    getChartsByDate(formatTimeStringsForCharts(newDateRange), true);
  };

  const handleRangeSelect = (dateRangeOption) => {
    const rangeString = dateRangeOption?.label;
    const mappedOption = chartRangeMappings
      ?.find((mapping) => mapping?.label === rangeString)?.value;

    setIsCustomSelected(false);
    modifyChartsRange(rangeString);

    if (rangeString === 'Custom') {
      setDefaultChartRangeOption(customDateRangeOption);
    }

    if (!mappedOption) {
      return setIsCustomSelected(true);
    }

    if (rangeString?.includes('Days')) {
      const dateRange = getOffsetDateArr([
        extendedDayJs(mappedOption),
        extendedDayJs(todayFullDate),
      ]);
      return handleDateRangeChange(dateRange);
    }

    if (rangeString?.includes('24')) {
      return handleTimeRangeChange([
        extendedDayJs(mappedOption),
        extendedDayJs(todayFullTime),
      ]);
    }

    return handleTimeRangeChange([
      extendedDayJs(oneHourAgo),
      extendedDayJs(todayFullTime),
    ]);
  };

  useEffect(() => {
    if (chartRangeState?.chartsRange) {
      const selectedOption = chartRangeSelectOptions
        .find((entry) => entry?.label === chartRangeState?.chartsRange);

      if (selectedOption) {
        setDefaultChartRangeOption(selectedOption);

        if (selectedOption?.label === 'Custom') {
          setIsCustomSelected(true);
        }
      }
    }
  }, [chartRangeState?.isPending]);

  return (
    <form className="chart-form">
      <h1 className="chart-form-header-text">{chartFormHeaderText}</h1>
      {isEditingCharts ? (
        <ConfirmEditChartsSection
          resetChartsLayout={resetChartsLayout}
          setIsEditingCharts={setIsEditingCharts}
          handleChartLayoutEdit={handleChartLayoutEdit}
        />
      ) : (
        <div className="chart-form-edit-section">
          <div className={
            isCustomSelected
              ? 'chart-form--dates-with-calendar'
              : 'chart-form--dates'
          }
          >
            <div className="chart-form-edit-subsection">
              <FluxCustomSelect
                handleChange={handleRangeSelect}
                options={chartRangeSelectOptions}
                showResetButton={showDatesResetButton}
                handleResetClick={resetDatesToDefault}
                defaultOption={defaultChartRangeOption}
              />
              <FluxTooltip
                showInfoCircle={false}
                title={`${displayGridView ? 'List' : 'Grid'} View`}
                description={
                  `View all of your charts in a ${displayGridView ? 'single list' : 'grid layout'}.`
                }
                tooltip={displayGridView ? (
                  <IoMenuOutline
                    size={24}
                    color="#BCBCBC"
                    onClick={() => setDisplayGridView(!displayGridView)}
                  />
                ) : (
                  <IoGridOutline
                    size={24}
                    color="#BCBCBC"
                    onClick={() => setDisplayGridView(!displayGridView)}
                  />
                )}
              />
            </div>
            {isCustomSelected && (
              <FluxDatePicker
                clearFunction={resetDefaultStates}
                finalizedDates={defaultDatePickerValue}
                setFinalizedDates={handleDateRangeChange}
                containerClasses="charts"
                popupClasses="charts"
              />
            )}
          </div>
          <EditChartsSection
            metricSearch={metricSearch}
            metricOptions={metricOptions}
            newChartIsOpen={newChartIsOpen}
            setMetricSearch={setMetricSearch}
            setIsEditingCharts={setIsEditingCharts}
            handleChartCreation={handleChartCreation}
            metricDropdownValues={metricDropdownValues}
            handleDropdownToggle={handleDropdownToggle}
            handleMetricSelection={handleMetricSelection}
            setConfirmModalIsOpen={setConfirmModalIsOpen}
          />
        </div>
      )}
      <ConfirmTemplateModal
        isOpen={confirmModalIsOpen}
        onSubmit={handleTemplateLayoutConfirmation}
        modalTitle={TEMPLATE_CONFIRMATION_MODAL_TITLE}
        closeModal={() => setConfirmModalIsOpen(false)}
      />
    </form>
  );
};

export default ChartForm;

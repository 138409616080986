import { fetchLayoutByDashboardId } from 'shared/repositories/axilLayoutRepository';
import * as telemetryLayoutActionTypes from 'single-asset/actions/telemetryConfigActionTypes';
import { transformTelemetryLayoutResponse } from 'single-asset/transformers/telemetryTransformers';
import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import { selectAsset } from 'single-asset/reducers/assetReducer';

export const fetchTelemetryConfig = asyncHookActionCreator({
  PENDING_ACTION_TYPE: telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG,
  SUCCESS_ACTION_TYPE: telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryLayoutActionTypes.TELEMETRY_CONFIG__FETCH_CONFIG_FAILED,
  repositoryAPI: fetchLayoutByDashboardId,
  responseTransformer: transformTelemetryLayoutResponse,
  selector: selectAsset,
});

export const resetTelemetryConfig = () => ({
  type: telemetryLayoutActionTypes.TELEMETRY_CONFIG__RESET,
});

import { getPreferences, updateHomepage } from 'shared/repositories/userPreferencesRepository';
import * as actionTypes from 'shared/actions/preferencesActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';

export const fetchPreferences = asyncActionCreator({
  repositoryAPI: getPreferences,
  PENDING_ACTION_TYPE: actionTypes.PREFERENCES__FETCH_PREFERENCES,
  SUCCESS_ACTION_TYPE: actionTypes.PREFERENCES__FETCH_PREFERENCES_SUCCESS,
  FAILED_ACTION_TYPE: actionTypes.PREFERENCES__FETCH_PREFERENCES_FAILED,
  responseTransformer: (result, { email }) => ({
    result: result[email].preferences,
  }),
});

export const updateHomepagePreference = asyncActionCreator({
  repositoryAPI: updateHomepage,
  PENDING_ACTION_TYPE: actionTypes.PREFERENCES__UPDATE_HOMEPAGE,
  SUCCESS_ACTION_TYPE: actionTypes.PREFERENCES__UPDATE_HOMEPAGE_SUCCESS,
  FAILED_ACTION_TYPE: actionTypes.PREFERENCES__UPDATE_HOMEPAGE_FAILED,
});

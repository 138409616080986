import React from 'react';

import { loadingKeyedData } from 'single-asset/constants/moreInfoConstants';

import LoadingCard from './LoadingCard';
import UnitProfileCard from './UnitProfileCard';

import './UnitProfileCardsContainer.scss';

const UnitProfileCardsContainer = ({ formattedEntries, currentUnitProfileInfo }) => (
  <section className="unit-profile-cards-container">
    {currentUnitProfileInfo?.isPending
      ? loadingKeyedData.map((entry) => <LoadingCard key={entry.key} />)
      : formattedEntries.map((entry) => <UnitProfileCard key={entry.index} entry={entry} />)}
  </section>
);

export default UnitProfileCardsContainer;

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Table } from 'antd';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';

import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';
import VirtualizedTableCellContainer from 'shared/components/VirtualizedTableCellContainer';

import './VirtualizedTable.scss';

const VirtualizedTable = ({
  scroll,
  columns,
  rowHeight,
  formattedCells = true,
  ...otherProps
}) => {
  const defaultHoveredRowState = null;
  const gridRef = useRef();
  const [tableWidth, setTableWidth] = useState(0);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(defaultHoveredRowState);
  const [headerScrollManager] = useState(Object.defineProperty({}, 'scrollLeft', {
    get: () => (gridRef.current
      ? gridRef.current?.state?.scrollLeft
      : null
    ),
    set: (scrollLeft) => {
      gridRef.current && gridRef.current.scrollTo({ scrollLeft });
    },
  }));
  const tableCellWidth = Math.floor(tableWidth / columns.length);
  const measuredColumns = columns?.map((column) => ({
    ...column,
    width: tableCellWidth,
    sorter: createTableSorter(column.dataIndex),
  }));

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  const resetHoveredRowState = () => {
    setHoveredRowIndex(defaultHoveredRowState);
  };

  const hoverData = useMemo(
    () => ({
      hoveredRowIndex,
      setHoveredRowIndex,
    }),
    [hoveredRowIndex],
  );

  useEffect(() => {
    resetVirtualGrid();
  }, [tableWidth]);

  const VirtualGrid = (rawData, { scrollbarSize, ref, onScroll }) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = headerScrollManager;
    const { length: rawDataLength } = rawData;
    const totalHeight = rawDataLength * rowHeight;
    const listHasScroll = totalHeight > scroll.y;
    const getColumnWidth = (index) => (
      listHasScroll && index === measuredColumns.length - 1
        ? (tableCellWidth) - scrollbarSize - 1
        : tableCellWidth
    );
    const itemData = {
      rawData,
      measuredColumns,
      formattedCells,
      ...hoverData,
    };

    return (
      <div onMouseLeave={resetHoveredRowState} data-testid="asset-table-grid">
        <Grid
          ref={gridRef}
          width={tableWidth}
          height={scroll?.y}
          onScroll={onScroll}
          itemData={itemData}
          className="virtual-grid"
          rowCount={rawDataLength}
          rowHeight={() => rowHeight}
          columnWidth={getColumnWidth}
          columnCount={measuredColumns.length}
        >
          {VirtualizedTableCellContainer}
        </Grid>
      </div>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...otherProps}
        scroll={scroll}
        pagination={false}
        columns={measuredColumns}
        className="asset-list-table"
        components={{
          body: VirtualGrid,
        }}
      />
    </ResizeObserver>
  );
};

export default VirtualizedTable;

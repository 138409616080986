import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

import { GET_UNIT_PROFILE_INFO_ENDPOINT } from 'single-asset/constants/moreInfoConstants';

export const getUnitProfileInfo = ({ body, accessToken }) => repositoryFetch({
  body,
  endpoint: GET_UNIT_PROFILE_INFO_ENDPOINT,
  accessToken,
});

export const getUnitProfileLink = ({ body, accessToken }) => repositoryFetch({
  method: 'GET',
  accessToken,
  endpoint: `/unit-profiles/assets/${body.nsid}`,
  isAxil: true,
});

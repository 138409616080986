import {
  IoBarChartSharp,
  IoReaderOutline,
  IoLayersOutline,
  IoFileTrayFullOutline,
  IoNotificationsOutline,
} from 'react-icons/io5';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  singleAssetRouteNames,
} from 'single-asset/constants/singleAssetConstants';

import './AssetMenu.scss';

const AssetMenu = ({
  selectedRoute,
  digitalTwinExistence,
}) => {
  const navigate = useNavigate();
  const defaultKey = 'telemetry';
  const selectedKey = selectedRoute || defaultKey;

  const getMenuItem = (link, icon, title) => ({
    label: (
      <div
        role="link"
        onClick={() => {
          navigate(link);
        }}
        className="asset-menu--item"
      >
        {icon}{title}
      </div>
    ),
    key: link || defaultKey,
  });

  const menuOptions = [
    getMenuItem(singleAssetRouteNames.TELEMETRY, <IoBarChartSharp />, 'Telemetry'),
    getMenuItem(singleAssetRouteNames.EVENTS, <IoNotificationsOutline />, 'Events'),
    getMenuItem(singleAssetRouteNames.WORK_ORDERS, <IoFileTrayFullOutline />, 'Work Orders'),
    getMenuItem(singleAssetRouteNames.MORE_INFORMATION, <IoReaderOutline />, 'More Information'),
    (digitalTwinExistence && getMenuItem(singleAssetRouteNames.DIGITAL_TWIN, <IoLayersOutline />, '3D Digital Twin')),
  ];

  return (
    <div className="asset-menu">
      <Menu
        mode="horizontal"
        items={menuOptions}
        selectedKeys={[selectedKey]}
      />
    </div>
  );
};

export default AssetMenu;

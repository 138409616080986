import {
  DEFAULT_ASSET_TYPE_ID,
  DASHBOARD_ID,
} from 'dashboard/constants/dashboardConstants';

export const transformDashboardConfigRequest = () => ({
  asset_type_id: DEFAULT_ASSET_TYPE_ID,
  dashboard_id: DASHBOARD_ID,
});

export const transformDashboardStatsRequest = (_, { config, props, ...otherProps }) => (
  {
    ...config,
    ...otherProps,
  }
);

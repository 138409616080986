import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import * as actionTypes from 'single-asset/actions/moreInfoStatsActionTypes';
import { getUnitProfileInfo, getUnitProfileLink } from 'shared/repositories/moreInfoStatsRepository';

export const fetchUnitProfileInfo = asyncHookActionCreator({
  repositoryAPI: getUnitProfileInfo,
  PENDING_ACTION_TYPE: actionTypes.MORE_INFO__STATS_FETCH,
  FAILED_ACTION_TYPE: actionTypes.MORE_INFO__STATS_FETCH_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.MORE_INFO__STATS_FETCH_SUCCEEDED,
});

export const fetchUnitProfileLink = asyncHookActionCreator({
  repositoryAPI: getUnitProfileLink,
  PENDING_ACTION_TYPE: actionTypes.MORE_INFO__PROFILE_FETCH,
  SUCCESS_ACTION_TYPE: actionTypes.MORE_INFO__PROFILE_FETCH_SUCCESS,
  FAILED_ACTION_TYPE: actionTypes.MORE_INFO__PROFILE_FETCH_FAILED,
});

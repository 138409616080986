/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  CARBON_CAPTURE_EMISSIONS_LABEL,
  CARBON_CAPTURE_TOTAL_DESCRIPTION,
  CARBON_CAPTURE_TOTAL_ABATED_TITLE,
  CARBON_CAPTURE_CALCULATIONS_QUESTION,
} from 'single-asset/constants/carbonCaptureConstants';

import './DetailedResultsCard.scss';

const DetailedResultsCard = ({
  showResultsFunction,
  currentCarbonDetails,
}) => (
  <section className="detailed-results-card">
    <div className="detailed-results-header">
      <section className="detailed-total-info">
        <span className="detailed-number">
          {currentCarbonDetails?.netAbated?.toFixed(4)}
        </span>
        <div className="detailed-description">
          <p>{CARBON_CAPTURE_TOTAL_ABATED_TITLE}</p>
          <FluxTooltip
            showInfoCircle={false}
            title={CARBON_CAPTURE_EMISSIONS_LABEL}
            description={CARBON_CAPTURE_TOTAL_DESCRIPTION}
            tooltip={(
              <IoInformationCircleOutline />
            )}
          />
        </div>
      </section>
      <p
        onClick={() => showResultsFunction()}
        className="explain-detailed-function-text"
      >
        {CARBON_CAPTURE_CALCULATIONS_QUESTION}
      </p>
    </div>
  </section>
);

export default DetailedResultsCard;

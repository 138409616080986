import React from 'react';

import {
  netEquation,
  ventingEquation,
  cH4DensityEquation,
  cO2DensityEquation,
  flaredEmissionsEquation,
  methaneEmissionsEquation,
  CARBON_CAPTURE_CH4_DENSITY_LABEL,
  CARBON_CAPTURE_CO2_DENSITY_LABEL,
  CARBON_CAPTURE_VENTING_CO2_LABEL,
  CARBON_CAPTURE_NET_ABATED_LABEL,
  CARBON_CAPTURE_FLARED_EMISSIONS_LABEL,
  CARBON_CAPTURE_METHANE_EMISSIONS_LABEL,
} from 'single-asset/constants/carbonCaptureConstants';
import EquationTableRow from 'single-asset/components/carbon-capture-calculator/modal/components/EquationTableRow';

import './EquationTable.scss';

const EquationTable = ({ methaneGwp }) => (
  <section className="equation-table">
    <EquationTableRow
      width="85%"
      equation={flaredEmissionsEquation}
      label={CARBON_CAPTURE_FLARED_EMISSIONS_LABEL}
    />
    <EquationTableRow
      width="100%"
      equation={methaneEmissionsEquation}
      label={CARBON_CAPTURE_METHANE_EMISSIONS_LABEL}
    />
    <EquationTableRow
      equation={cO2DensityEquation}
      label={CARBON_CAPTURE_CO2_DENSITY_LABEL}
    />
    <EquationTableRow
      equation={cH4DensityEquation}
      label={CARBON_CAPTURE_CH4_DENSITY_LABEL}
    />
    <EquationTableRow
      equation={ventingEquation(methaneGwp)}
      label={CARBON_CAPTURE_VENTING_CO2_LABEL}
    />
    <EquationTableRow
      equation={netEquation}
      label={CARBON_CAPTURE_NET_ABATED_LABEL}
    />
  </section>
);

export default EquationTable;

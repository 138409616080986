import { Route, Routes } from 'react-router-dom';

import EventsPage from 'single-asset/pages/EventsPage';
import DigitalTwin from 'single-asset/pages/DigitalTwin';
import WorkOrdersPage from 'single-asset/pages/WorkOrdersPage';
import MoreInformationPage from 'single-asset/pages/MoreInformationPage';
import TelemetryPageContainer from 'single-asset/pages/TelemetryPageContainer';
import { singleAssetRoutePaths } from 'single-asset/constants/singleAssetConstants';
import PushChartComparison from 'single-asset/components/PushChartComparison';

const AssetRoutes = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={<TelemetryPageContainer />}
    />
    <Route
      exact
      element={<EventsPage />}
      path={singleAssetRoutePaths.EVENTS}
    />
    <Route
      exact
      element={<WorkOrdersPage />}
      path={singleAssetRoutePaths.WORK_ORDERS}
    />
    <Route
      exact
      element={<MoreInformationPage />}
      path={singleAssetRoutePaths.MORE_INFORMATION}
    />
    <Route
      exact
      element={<DigitalTwin />}
      path={singleAssetRoutePaths.DIGITAL_TWIN}
    />
    <Route
      exact
      path="push-comparison"
      element={<PushChartComparison />}
    />
  </Routes>
);

export default AssetRoutes;

import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import { getWorkOrders } from 'shared/repositories/workOrderRepository';
import * as actionTypes from 'single-asset/actions/workOrdersActionTypes';

export const fetchWorkOrders = asyncHookActionCreator({
  repositoryAPI: getWorkOrders,
  PENDING_ACTION_TYPE: actionTypes.WORK_ORDERS__FETCH,
  FAILED_ACTION_TYPE: actionTypes.WORK_ORDERS__FETCH_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.WORK_ORDERS__FETCH_SUCCEEDED,
});

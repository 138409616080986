import {
  Input, Modal, Select, Spin, Statistic,
} from 'antd';
import { useEffect, useState } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';
import { checkIpValidity, connectNewIpAddress } from 'shared/repositories/enterpriseObjectRepository';
import EditableIpStat from 'single-asset/components/asset/EditableIpStat';
import MechanicStat from 'single-asset/components/asset/MechanicStat';
import { headerStatTitles, IP_PORT_OPTIONS, INVALID_IP_ERROR_MESSAGE } from 'single-asset/constants/headerConstants';
import { isValidIpAddress } from 'single-asset/helpers/singleAssetHelpers';

import './HeaderStats.scss';

const HeaderStats = ({
  asset,
  accessToken,
  addIpCopyToast,
  updateIpAddress,
  hasEditIpAccess,
  enterpriseObject,
}) => {
  const defaultSubmissionErrors = {
    ipAddress: false,
    message: '',
  };
  const [isEditingIp, setIsEditingIp] = useState(false);
  const [assetIp, setAssetIp] = useState(enterpriseObject?.ipAddress);
  const [assetPort, setAssetPort] = useState(enterpriseObject?.ipInfo?.port ?? 9600);
  const [loading, setLoading] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState(defaultSubmissionErrors);
  const [override, setOverride] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const confirmIpUpdate = async () => {
    setLoading(true);
    setOverride(false);

    const {
      org_id,
      site_id,
      asset_id,
    } = asset;

    const body = {
      data: {
        identifiers: {
          altitude: `orgs/${org_id}/sites/${site_id}/assets/${asset_id}`,
        },
        changes: {
          ipAddress: `${assetIp}`,
          port: `${assetPort}`,
        },
      },
      source: 'Axil.IpAddressChange',
      eventType: 'edit',
      dataType: 'IpAddress',
      time: Date.now().toString(),
    };

    const result = await connectNewIpAddress({ accessToken, body });

    if (result.ok || result.status === 202) {
      setIsEditingIp(false);
      updateIpAddress(assetIp);
      setSubmissionErrors(defaultSubmissionErrors);
    } else {
      setSubmissionErrors({ ipAddress: false, message: 'Error updating IP Address. If this problem persists, please contact support.' });
    }

    setLoading(false);
  };

  const submitIpEdit = async () => {
    if (!isValidIpAddress(assetIp)) {
      return setSubmissionErrors({
        ipAddress: true,
        message: '',
      });
    }

    setLoading(true);

    const validityCheck = await checkIpValidity({
      accessToken,
      body: {
        orgId: asset.org_id,
        assetId: asset.asset_id,
        siteId: asset.site_id,
        ipAddress: `${assetIp}`,
        ipPort: `${assetPort}`,
      },
    });

    const parsedResponse = await validityCheck.json();

    if (parsedResponse?.valid || validityCheck.status === 408) return confirmIpUpdate();

    setSubmissionErrors({
      ipAddress: false,
      message: 'IP Address connection error. IP may be in use on another unit.',
    });

    setOverride(true);

    return setLoading(false);
  };

  useEffect(() => {
    setAssetIp(enterpriseObject?.ipAddress);
    setAssetPort(enterpriseObject?.ipInfo?.port ?? 9600);
  }, [enterpriseObject]);

  const headerStats = [
    {
      title: headerStatTitles.COMPANY,
      key: headerStatTitles.COMPANY,
      value: asset?.org_name ?? MISSING_FIELD_VALUE_PLACEHOLDER,
    },
    {
      title: headerStatTitles.LEASE_NAME,
      key: headerStatTitles.LEASE_NAME,
      value: asset?.site_name ?? MISSING_FIELD_VALUE_PLACEHOLDER,
    },
    {
      title: headerStatTitles.AREA,
      key: headerStatTitles.AREA,
      value: enterpriseObject?.operatingArea?.name ?? MISSING_FIELD_VALUE_PLACEHOLDER,
    },
    {
      title: headerStatTitles.UNIT_OWNER,
      key: headerStatTitles.UNIT_OWNER,
      value: enterpriseObject?.assetType ?? MISSING_FIELD_VALUE_PLACEHOLDER,
    },
    {
      title: headerStatTitles.MECHANIC,
      key: headerStatTitles.MECHANIC,
      formatter: () => (
        <MechanicStat mechanic={enterpriseObject?.mechanic} />
      ),
    },
    {
      title: headerStatTitles.IP_ADDRESS,
      key: headerStatTitles.IP_ADDRESS,
      formatter: () => (!loading ? (
        <EditableIpStat
          hasEditIpAccess={hasEditIpAccess}
          ipAddress={enterpriseObject?.ipAddress}
          editing={isEditingIp}
          setEditing={setIsEditingIp}
          addIpCopyToast={addIpCopyToast}
        />
      ) : <Spin />),
    },
  ];

  const handleCancelIpEdit = () => {
    setIsEditingIp(false);
    setAssetIp(enterpriseObject?.ipAddress);
    setAssetPort(enterpriseObject?.ipInfo?.port ?? 9600);
    setOverride(false);
    setSubmissionErrors(defaultSubmissionErrors);
  };

  const headerStatClass = (stat) => (
    `asset-header-stat ${stat.title === headerStatTitles.MECHANIC ? 'asset-header-stat-hover' : ''}`
  );

  return (
    <div className="asset-header-stats">
      <div className="flux-stat-list">
        {
            headerStats.map((stat) => (
              <div className={headerStatClass(stat)} key={stat.key}>
                <Statistic {...stat} />
              </div>
            ))
          }
      </div>
      <Modal
        centered
        footer={[]}
        title="Edit IP"
        open={isEditingIp}
        className="edit-ip-modal"
        onCancel={handleCancelIpEdit}
        wrapClassName="edit-ip-modal-dialog"
      >
        <label htmlFor="ipEditInput" className="edit-input-label">IP Address</label>
        <Input
          size="large"
          name="ipEditInput"
          placeholder={assetIp ?? 'IP Address'}
          allowClear={{
            clearIcon: <></>,
          }}
          onChange={({ target: { value } }) => setAssetIp(value)}
          value={assetIp ?? ''}
          status={submissionErrors?.ipAddress && 'error'}
        />
        {
          submissionErrors.ipAddress && (
            <p className="submission-error">{INVALID_IP_ERROR_MESSAGE}</p>
          )
        }
        <p htmlFor="ipPortSelect" className="edit-input-label">Port</p>
        <Select
          name="ipPortSelect"
          size="large"
          defaultValue={assetPort}
          value={assetPort}
          options={IP_PORT_OPTIONS}
          onSelect={(value) => setAssetPort(value)}
          suffixIcon={!selectOpen ? <IoChevronDown size={28} /> : <IoChevronUp size={28} />}
          onDropdownVisibleChange={() => setSelectOpen(!selectOpen)}
        />
        {
          submissionErrors.message.length > 0 && (
            <p className="submission-error">{submissionErrors.message}</p>
          )
        }
        {
          ((assetIp !== enterpriseObject?.ipAddress)
          || (assetPort !== enterpriseObject?.ipInfo?.port))
            ? (
              <div className="button-group">
                {
                  !loading ? (
                    <>
                      <button onClick={handleCancelIpEdit}>Cancel</button>
                      <button onClick={override ? confirmIpUpdate : submitIpEdit}>
                        {
                          override ? 'Override and Save' : 'Save'
                        }
                      </button>
                    </>
                  ) : <Spin />
                }
              </div>
            ) : null
        }
      </Modal>
    </div>

  );
};

export default HeaderStats;

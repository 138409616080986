export const assetListStatusOptionValues = {
  ALL_UNITS: 'all-units',
  RUNNING_UNITS: 'running-units',
  NOT_RUNNING_UNITS: 'not-running-units',
};

export const defaultAssetListStatusDropdownOption = {
  key: assetListStatusOptionValues.ALL_UNITS,
  label: 'Active & inactive assets',
  value: assetListStatusOptionValues.ALL_UNITS,
};

export const assetListStatusOptionDropdownItems = [
  defaultAssetListStatusDropdownOption,
  {
    key: assetListStatusOptionValues.RUNNING_UNITS,
    label: 'Active only',
    value: assetListStatusOptionValues.RUNNING_UNITS,
  },
  {
    key: assetListStatusOptionValues.NOT_RUNNING_UNITS,
    label: 'Inactive only',
    value: assetListStatusOptionValues.NOT_RUNNING_UNITS,
  },
];

export const assetStatusTypes = {
  OFF: 'Off',
  FAULTED: 'Faulted',
  RUNNING: 'Running',
  AUTO: 'Auto',
  WAITING_TO_AUTO_START: 'Waiting to Auto Start',
  READY: 'Ready',
};

export const okAssetStatusTypes = [
  'Running',
  'Auto',
  'Waiting to Auto Start',
];

export const runningAssetStatusTypes = [
  ...okAssetStatusTypes,
  'Ready',
  'Alarming',
];

export const connectivityTooltips = {
  connected: {
    title: 'Connected',
    description: 'Flux is communicating with this asset.',
  },
  disconnected: {
    title: 'Disconnected',
    description: 'Flux is not communicating with this asset.',
  },
  unknown: {
    title: 'Unknown',
    description: 'Flux\'s communication with this asset is unknown.',
  },
};

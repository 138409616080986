import { Draggable } from 'react-beautiful-dnd';

import { Button } from '@flogistix/flo-ui';

import './DraggableChartCard.scss';

const DraggableChartCard = ({
  seriesData,
  index,
  removeChart,
  uuid,
}) => {
  const formattedIndex = index + 1 <= 9 ? `0${index + 1}` : index + 1;

  const LabelCards = () => seriesData.map((singleSeries) => (<h6 className="label">{singleSeries.label}</h6>));

  return (
    <Draggable
      key={uuid}
      index={index}
      draggableId={uuid}
      disableInteractiveElementBlocking={false}
    >
      {(dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          className="draggable-chart-card"
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
        >
          <h6 className="index">{formattedIndex}</h6>
          <div className="label-cards-container">
            <LabelCards />
            <Button
              size="small"
              variation="light-red"
              style={{ marginLeft: 'auto' }}
              onClick={() => removeChart(index)}
            >
              Remove chart
            </Button>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableChartCard;

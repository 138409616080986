import React from 'react';

import LoadingSpinner from 'single-asset/components/carbon-capture-calculator/asset-header/LoadingSpinner';

import './CarbonCaptureInfoCard.scss';

const CarbonCaptureInfoCard = ({
  value,
  dataPending,
  handleClick,
  showDataError,
}) => (
  <section
    onClick={handleClick}
    className={!showDataError
      ? 'carbon-capture-card-hover'
      : 'carbon-capture-card'}
  >
    {dataPending
      ? <LoadingSpinner />
      : (
        <span className="carbon-capture-card-value">
          {(showDataError || !value) ? 'Data Error' : value}
        </span>
      )}
    <div className="carbon-capture-card-title-container">
      <p className="carbon-capture-card-title">
        Metric Tons CO<sub>2</sub>e Abated
      </p>
    </div>
  </section>
);

export default CarbonCaptureInfoCard;

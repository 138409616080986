import * as actionTypes from 'single-asset/actions/moreInfoStatsActionTypes';

export const defaultState = {
  unitProfileInfo: {},
  profileLink: null,
  profilePending: false,
  isPending: false,
  errorResponse: null,
};

const moreInfoStatsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.MORE_INFO__STATS_FETCH: {
      return {
        ...state,
        isPending: true,
      };
    }

    case actionTypes.MORE_INFO__STATS_FETCH_FAILED: {
      const { errorResponse } = action;

      return {
        ...state,
        isPending: false,
        errorResponse,
      };
    }

    case actionTypes.MORE_INFO__STATS_FETCH_SUCCEEDED: {
      return {
        ...state,
        isPending: false,
        unitProfileInfo: action.result,
      };
    }

    case actionTypes.MORE_INFO__PROFILE_FETCH: {
      return {
        ...state,
        profilePending: true,
      };
    }

    case actionTypes.MORE_INFO__PROFILE_FETCH_SUCCESS: {
      const { id } = action.result;

      return {
        ...state,
        profilePending: false,
        profileLink: `https://unitprofile.flogistix.com/unitprofile/${id}/view`,
      };
    }

    case actionTypes.MORE_INFO__PROFILE_FETCH_FAILED: {
      return {
        ...state,
        profilePending: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default moreInfoStatsReducer;

import React from 'react';
import { IoAddOutline, IoClose, IoRemoveOutline } from 'react-icons/io5';

export const CARBON_CAPTURE_FLARE_TITLE = 'Flare Efficiency';
export const CARBON_CAPTURE_EMISSIONS_TITLE = 'Emissions Results';
export const CARBON_CAPTURE_EQUATIONS_TITLE = 'Equation Breakdown';
export const CARBON_CAPTURE_VAPOR_COMP_TITLE = 'Vapor Composition';
export const CARBON_CAPTURE_VOLUME_TITLE = 'Volume of Vapors Captured (MCF)';
export const CARBON_CAPTURE_TOOLTIP_TITLE = <p>Abated CO<sub>2</sub>e Emissions</p>;
export const CARBON_CAPTURE_TOTAL_ABATED_TITLE = <span>Total Emissions Abated (Metric Tons CO<sub>2</sub>e)</span>;

export const CARBON_CAPTURE_CALCULATIONS_QUESTION = 'How is this calculated?';

export const CARBON_CAPTURE_SUMMATION_SYMBOL = <span>Σ</span>;

export const CARBON_CAPTURE_EMISSIONS_LABEL = <span>Emissions Abated</span>;
export const CARBON_CAPTURE_FLARED_EMISSIONS_LABEL = <span>Flared Emissions</span>;
export const CARBON_CAPTURE_METHANE_EMISSIONS_LABEL = <span>Methane Emissions</span>;
export const CARBON_CAPTURE_DRIVER_EMISSIONS_LABEL = <span>Driver Emissions</span>;
export const CARBON_CAPTURE_NET_ABATED_LABEL = <span>Net Emissions Abated</span>;
export const CARBON_CAPTURE_CO2_DENSITY_LABEL = <span>CO<sub>2</sub> Density</span>;
export const CARBON_CAPTURE_CH4_DENSITY_LABEL = <span>CH<sub>4</sub> Density</span>;
export const CARBON_CAPTURE_VENTING_CO2_LABEL = <span>Venting - Tons of <span>CO<sub>2</sub> Density</span> (considering Methane as GHG)</span>;

export const CARBON_CAPTURE_TONS_DESCRIPTION = <span>Metric Tons of CO<sub>2</sub>e Emissions Abated</span>;
export const CARBON_CAPTURE_TOTAL_DESCRIPTION = 'The total amount of emissions captured instead of flaring.';
export const CARBON_CAPTURE_DEFAULT_DESCRIPTION = <p>Total amount of CO<sub>2</sub>e emissions abated for this asset (default is set 28 days to date).</p>;

export const CARBON_CAPTURE_FLARE_TOOLTIP = 'The efficiency of all captured flare emissions.';
export const CARBON_CAPTURE_VOLUME_TOOLTIP = 'The total volume of all gas captured by vapor recovery.';

export const VFM_LABEL__DAY = 'Last Day';
export const VFM_LABEL__YEAR = 'Last Year';
export const VFM_LABEL__WEEK = 'Last Week';
export const VFM_LABEL__28_DAYS = 'Last 28 days';

export const VFM_VALUE__WEEK = 'last_7_days';
export const VFM_VALUE__DAY = 'last_24_hours';
export const VFM_VALUE__YEAR = 'last_365_days';
export const VFM_VALUE__28_DAYS = 'last_28_days';

export const timeAccruedOptions = [
  {
    label: VFM_LABEL__28_DAYS,
    value: VFM_VALUE__28_DAYS,
  },
  {
    label: VFM_LABEL__YEAR,
    value: VFM_VALUE__YEAR,
  },
  {
    label: VFM_LABEL__WEEK,
    value: VFM_VALUE__WEEK,
  },
  {
    label: VFM_LABEL__DAY,
    value: VFM_VALUE__DAY,
  },
];

export const defaultCarbonDetailRangeOption = {
  label: VFM_LABEL__28_DAYS,
  value: VFM_VALUE__28_DAYS,
};

export const vaporCompositionTableColumns = [
  {
    title: <p className="table-header-cell-text">Component</p>,
    key: 'Component',
  },
  {
    title: <p className="table-header-cell-text">Mol %</p>,
    key: 'Mol%',
  },
  {
    key: 'FlareEmissions',
    title: <p className="table-header-cell-text">Flare CO<sub>2</sub> Emissions (Metric Tons CO<sub>2</sub>)</p>,
  },
];

const flaredEmissionsPart1 = {
  description: <span>Volume of all gas</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart2 = {
  description: <span>CO<sub>2</sub> Density</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart3 = {
  description: <span>Flare Efficiency</span>,
  mathFunction: <IoAddOutline />,
};
const flaredEmissionsPart4 = {
  description: <span>Component gas mol %</span>,
  mathFunction: <IoClose />,
};
const flaredEmissionsPart5 = {
  description: <span># of carbon molecules contained in component gas</span>,
};

const methaneEmissionsPart1 = {
  description: <span>Volume of all gas</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart2 = {
  description: <span>CH<sub>4</sub> Density</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart3 = {
  description: <span>GWP</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart4 = {
  description: <span>Methane mol %</span>,
  mathFunction: <IoClose />,
};
const methaneEmissionsPart5 = {
  description: <span>1 - Flare Efficiency</span>,
};

export const netEquation = [
  {
    description: <span>Flared Emissions</span>,
    mathFunction: <IoAddOutline />,
  },
  {
    description: <span>Methane Emissions</span>,
    mathFunction: <IoRemoveOutline />,
  },
  {
    description: <span>Driver Emissions</span>,
  },
];

export const flaredEmissionsEquation = [
  // flaredEmissionsPart0,
  flaredEmissionsPart1,
  flaredEmissionsPart2,
  flaredEmissionsPart3,
  flaredEmissionsPart4,
  flaredEmissionsPart5,
];

export const methaneEmissionsEquation = [
  methaneEmissionsPart1,
  methaneEmissionsPart2,
  methaneEmissionsPart3,
  methaneEmissionsPart4,
  methaneEmissionsPart5,
];

export const ventingEquation = (methaneGwp) => [
  { description: <span>Methane 100 Year Global Warming Potential (GWP) = {methaneGwp}</span> },
];

export const cO2DensityEquation = [
  { description: <span>0.05262 Metric Tons / MSCF at 14.73 psia, 60° F</span> },
];

export const cH4DensityEquation = [
  { description: <span>0.01913 Metric Tons / MSCF at 14.73 psia, 60° F</span> },
];

export const gasSelectOptions = [
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Carbon Dioxide</span>
      </div>
    ),
    value: 'Carbon Dioxide',
    dropdownLabel: 'Carbon Dioxide',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Nitrogen</span>
      </div>
    ),
    value: 'Nitrogen',
    dropdownLabel: 'Nitrogen',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Methane</span>
      </div>
    ),
    value: 'Methane',
    dropdownLabel: 'Methane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Ethane</span>
      </div>
    ),
    value: 'Ethane',
    dropdownLabel: 'Ethane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Butane</span>
      </div>
    ),
    value: 'Butane',
    dropdownLabel: 'Butane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">I-Butane</span>
      </div>
    ),
    value: 'I-Butane',
    dropdownLabel: 'I-Butane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Pentane</span>
      </div>
    ),
    value: 'Pentane',
    dropdownLabel: 'Pentane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">I-Pentane</span>
      </div>
    ),
    value: 'I-Pentane',
    dropdownLabel: 'I-Pentane',
  },
  {
    label: (
      <div className="component-gas-select-option">
        <span className="component-gas-select-option-summation">Σ</span>
        <span className="component-gas-select-option-text">Hexane</span>
      </div>
    ),
    value: 'Hexane',
    dropdownLabel: 'Hexane',
  },
];

import React from 'react';

import './CarbonCalculatorPill.scss';

const CarbonCalculatorPill = ({
  label,
  style,
}) => (
  <div style={style} className="carbon-calculator-pill">
    {label}
  </div>
);

export default CarbonCalculatorPill;

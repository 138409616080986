import { v4 as uuid } from 'uuid';

import FluxStat from 'shared/components/FluxStat';

import './FluxStatList.scss';

const FluxStatList = ({
  statsList,
  configList,
  additionalClassNames = '',
}) => {
  const renderCards = statsList?.length && statsList || (configList ?? []);
  return (
    <div className={`flux-stat-list ${additionalClassNames}`}>
      {renderCards.map((stat) => {
        const key = `${stat.props.title}-${uuid()}`;

        return (
          <FluxStat
            key={key}
            stat={stat}
          />
        );
      })}
    </div>
  );
};

export default FluxStatList;

import { Skeleton } from 'antd';

import './FluxStatSkeleton.scss';

const FluxStatSkeleton = () => (
  <Skeleton
    active
    className="flux-stat-skeleton"
    title={{
      width: '50%',
    }}
    paragraph={{
      rows: 1,
      width: '100%',
      height: '40px',
    }}
  />
);

export default FluxStatSkeleton;

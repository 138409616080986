import {
  getAssetLinkUrl,
  getRunTimePercentageClassAndText,
} from 'shared/helpers/altitudeHelpers';

import {
  setMissingElementValuesToDefault,
} from 'dashboard/helpers/dashboardHelpers';

import { getStatusClassAndText } from 'shared/helpers/assetHelpers';

import './AssetCard.scss';

const AssetCard = ({ asset }) => {
  const displayedAsset = setMissingElementValuesToDefault(asset);
  const [statusClass, statusText] = getStatusClassAndText(displayedAsset.status);
  const [runtimeClass, runtimeText] = getRunTimePercentageClassAndText(displayedAsset.runtime);

  return (
    <a
      className="asset-card"
      href={getAssetLinkUrl(asset)}
    >
      <div className="asset-card--left-column">
        <div className="asset-card--asset-name">{displayedAsset.asset_name}</div>
        <div className="asset-card--org-model-status">
          <div>{displayedAsset.org_name}</div>
          <div>Model: {displayedAsset.model}</div>
          <div className={`asset-card--status ${statusClass}`}>Status: {statusText}</div>
        </div>
        <span className="asset-card--site-name">{displayedAsset.site_name} | Rental Units</span>
      </div>
      <div className={`asset-card--runtime ${runtimeClass}`}>
        <div className="asset-card--runtime-percentage">{runtimeText}</div>
        <div className="asset-card--runtime-title">MA</div>
      </div>
    </a>
  );
};

export default AssetCard;

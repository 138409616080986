export const TELEMETRY_STATS__FETCH_STATS_LIST = 'TELEMETRY_STATS__FETCH_STATS_LIST';
export const TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED = 'TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED';
export const TELEMETRY_STATS__FETCH_STATS_LIST_FAILED = 'TELEMETRY_STATS__FETCH_STATS_LIST_FAILED';

export const GASLIFT_WELL_STATS__FETCH_STATS_LIST = 'GASLIFT_WELL_STATS__FETCH_STATS_LIST';
export const GASLIFT_WELL_STATS__FETCH_STATS_LIST_SUCCEEDED = 'GASLIFT_WELL_STATS__FETCH_STATS_LIST_SUCCEEDED';
export const GASLIFT_WELL_STATS__FETCH_STATS_LIST_FAILED = 'GASLIFT_WELL_STATS__FETCH_STATS_LIST_FAILED';

export const GASLIFT_STATS__FETCH_STATS_LIST = 'GASLIFT_STATS__FETCH_STATS_LIST';
export const GASLIFT_STATS__FETCH_STATS_LIST_SUCCEEDED = 'GASLIFT_STATS__FETCH_STATS_LIST_SUCCEEDED';
export const GASLIFT_STATS__FETCH_STATS_LIST_FAILED = 'GASLIFT_STATS__FETCH_STATS_LIST_FAILED';

export const TELEMETRY_STATS__RESET_STATS_LIST = 'TELEMETRY_STATS__RESET_STATS_LIST';

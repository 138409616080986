import {
  Input,
  Select,
  Switch,
  Divider,
} from 'antd';
import { useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import { Button } from '@flogistix/flo-ui';

import './AddChartDropdown.scss';

const AddChartDropdown = ({
  itemHeader,
  searchValue,
  placeholder,
  onItemSelect,
  cancelAction,
  dropdownItems,
  dropdownValue,
  confirmAction,
  onSearchChange,
  isOpen = false,
  disabled = false,
  searchPlaceholder,
  itemSubHeader = '',
  isSearchable = true,
  placement = 'topLeft',
  ...restOfProps
}) => {
  const preventCloseBehavior = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [addToTemplate, setAddToTemplate] = useState(false);

  return (
    <div className="add-chart-dropdown">
      <Select
        open={isOpen}
        mode="multiple"
        disabled={disabled}
        placement={placement}
        value={dropdownValue}
        tagRender={() => null}
        options={dropdownItems}
        onSelect={onItemSelect}
        onDeselect={onItemSelect}
        placeholder={placeholder}
        popupClassName="add-chart-options"
        dropdownStyle={{ marginTop: '-1rem' }}
        dropdownRender={(menu) => (
          <div
            onClick={preventCloseBehavior}
            onMouseDown={preventCloseBehavior}
          >
            <p className="dropdown-item-header">{itemHeader}</p>
            {itemSubHeader
              && <p className="dropdown-item-subheader">{itemSubHeader}</p>}
            {isSearchable
              && (
                <>
                  <Input
                    onKeyDown={(e) => e.stopPropagation()}
                    placeholder={searchPlaceholder}
                    prefix={<IoSearchOutline />}
                    onChange={onSearchChange}
                    value={searchValue}
                  />
                  <Divider />
                </>
              )}
            {menu}
            <div className="modal-footer">
              <div className="template-toggle">
                <Switch
                  onChange={() => setAddToTemplate(!addToTemplate)}
                  value={addToTemplate}
                />
                <span className="toggle-text">Add to template</span>
              </div>
              <div>
                <Button
                  onClick={cancelAction}
                  variation="black-outline"
                  style={{ marginRight: '4px' }}
                >
                  Cancel
                </Button>
                <Button onClick={() => confirmAction(addToTemplate)}>
                  Create chart
                </Button>
              </div>
            </div>
          </div>
        )}
        {...restOfProps}
      />
    </div>
  );
};

export default AddChartDropdown;

import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { isDataEqual } from 'shared/helpers/filterHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';

import { selectAsset } from 'single-asset/reducers/assetReducer';
import { sharedRequestBody } from 'single-asset/helpers/assetHelpers';
import { tagsForRequestBody } from 'single-asset/constants/moreInfoConstants';
import { formUnitProfileEntries } from 'single-asset/helpers/moreInfoHelpers';
import { fetchUnitProfileInfo } from 'single-asset/actions/moreInfoStatsActions';
import { fetchUnitProfileImage } from 'single-asset/actions/moreInfoImageActions';
import LocationAndImageContainer from 'single-asset/components/more-info/LocationAndImageContainer';
import UnitProfileCardsContainer from 'single-asset/components/more-info/UnitProfileCardsContainer';
import moreInfoImageReducer, { defaultState as defaultMoreInfoImageState } from 'single-asset/reducers/moreInfoImageReducer';
import moreInfoStatsReducer, { defaultState as defaultMoreInfoStatsState } from 'single-asset/reducers/moreInfoStatsReducer';

import './MoreInformationPage.scss';

const MoreInformationPage = () => {
  const asset = useSelector(selectAsset);
  const currentSession = useSelector(selectSession);

  const [moreInfoStatsState, moreInfoStatsDispatch] = useReducer(moreInfoStatsReducer, defaultMoreInfoStatsState);
  const [moreInfoImageState, moreInfoImageDispatch] = useReducer(moreInfoImageReducer, defaultMoreInfoImageState);

  const [assetImage, setAssetImage] = useState('');
  const [unitProfile, setUnitProfile] = useState({});
  const [assetLatitude, setAssetLatitude] = useState('');
  const [assetLongitude, setAssetLongitude] = useState('');
  const [formattedEntries, setFormattedEntries] = useState([]);

  const getUnitProfileInfoRequestBody = {
    ...sharedRequestBody(asset),
    tags: tagsForRequestBody,
  };

  useEffect(() => {
    if (asset) {
      if (!isDataEqual(moreInfoStatsState?.unitProfileInfo[0], unitProfile)) {
        fetchUnitProfileInfo(getUnitProfileInfoRequestBody, moreInfoStatsDispatch, currentSession);
      }

      if (moreInfoImageState?.unitProfileImage?.url !== assetImage) {
        fetchUnitProfileImage(asset?.asset_name, moreInfoImageDispatch, currentSession);
      }
    }
  }, [asset]);

  useEffect(() => {
    if (unitProfile) {
      setAssetLatitude(unitProfile?.lat);
      setAssetLongitude(unitProfile?.lon);

      const entries = Object.entries(unitProfile);
      const newFormattedEntries = formUnitProfileEntries(entries);
      const filteredEntries = newFormattedEntries.filter((entry) => entry.index > 0);
      const sortedEntries = filteredEntries.sort((a, b) => a.index - b.index);

      setFormattedEntries(sortedEntries);
    }
  }, [unitProfile]);

  useEffect(() => {
    if (moreInfoStatsState?.unitProfileInfo[0]) {
      setUnitProfile(moreInfoStatsState?.unitProfileInfo[0]);
    }
  }, [moreInfoStatsState]);

  useEffect(() => {
    if (moreInfoImageState?.unitProfileImage?.url) {
      setAssetImage(moreInfoImageState?.unitProfileImage?.url);
    }
  }, [moreInfoImageState]);

  return (
    <section className="more-information-page-container">
      <div className="more-information-page">
        <h1>Unit Profile</h1>
        <UnitProfileCardsContainer
          formattedEntries={formattedEntries}
          currentUnitProfileInfo={moreInfoStatsState}
        />
        {!moreInfoImageState?.isPending
          && !moreInfoStatsState?.isPending
            && (
              <LocationAndImageContainer
                image={assetImage}
                lat={assetLatitude}
                lng={assetLongitude}
              />
            )}
      </div>
    </section>
  );
};

export default MoreInformationPage;

/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect, useState, useReducer } from 'react';
import { IoFolderOutline, IoNotificationsOutline } from 'react-icons/io5';

import FluxTooltip from 'shared/components/FluxTooltip';
import { selectSession } from 'shared/reducers/sessionReducer';
import { checkDigitalTwinExistence } from 'shared/repositories/assetsRepository';
import { DEFAULT_CHART_RANGE_LABEL } from 'shared/constants/datePickerConstants';
import { getCarbonCaptureDetails } from 'shared/repositories/carbonCaptureRepository';
import { isUserExternal, hasCarbonCalcAccess } from 'shared/helpers/permissionHelpers';

import {
  UNIT_PROFILE_ICON_TITLE,
  UNIT_PROFILE_ICON_DESCRIPTION,
  NOTIFICATIONS_ICON_TITLE,
  NOTIFICATIONS_ICON_DESCRIPTION,
} from 'single-asset/constants/headerConstants';
import AssetMenu from 'single-asset/components/asset/AssetMenu';
import HeaderStats from 'single-asset/components/asset/HeaderStats';
import AssetRoutes from 'single-asset/components/asset/AssetRoutes';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import AssetDetails from 'single-asset/components/asset/AssetDetails';
import NotificationModal from 'single-asset/components/asset/NotificationModal';
import { fetchUnitProfileLink } from 'single-asset/actions/moreInfoStatsActions';
import { updateTelemetryChartRange } from 'single-asset/actions/telemetryChartsRangeActions';
import CarbonCaptureModal from 'single-asset/components/carbon-capture-calculator/modal/CarbonCaptureModal';
import moreInfoStatsReducer, { defaultState as defaultMoreInfoStatsState } from 'single-asset/reducers/moreInfoStatsReducer';
import CarbonCaptureInfoContainer from 'single-asset/components/carbon-capture-calculator/asset-header/CarbonCaptureInfoContainer';
import telemetryChartsRangeReducer, { defaultState as defaultRangeState } from 'single-asset/reducers/telemetryChartsRangeReducer';

import './AssetPage.scss';

const AssetPage = ({
  user,
  asset,
  setAsset,
  accessToken,
  subscriptions,
  selectedRoute,
  updateIpAddress,
  hasEditIpAccess,
  getSubscriptions,
  enterpriseObject,
  getEnterpriseObject,
  fetchedSubscriptions,
  updateUserSubscriptions,
  addIpCopyToast,
}) => {
  const currentSession = useSelector(selectSession);
  const { groups } = useSelector(selectPermissions);

  const [_, rangeDispatch] = useReducer(telemetryChartsRangeReducer, defaultRangeState);
  const [profileLinkState, profileLinkDispatch] = useReducer(moreInfoStatsReducer, defaultMoreInfoStatsState);

  const getProfileLink = (nsid) => fetchUnitProfileLink({ nsid }, profileLinkDispatch, currentSession);
  const modifyChartsRange = (newChartsRange) => rangeDispatch(updateTelemetryChartRange(newChartsRange));

  const [showDataError, setShowDataError] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [digitalTwinExistence, setDigitalTwinExistence] = useState(false);

  const [emissionsDetails, setEmissionsDetails] = useState(null);
  const [carbonCaptureModalOpen, setCarbonCaptureModalOpen] = useState(false);
  const [emissionsDetailsPending, setEmissionsDetailsPending] = useState(false);
  const [currentCarbonCaptureTime, setCurrentCarbonCaptureTime] = useState('Last 28 Days');

  const hasCccAccess = hasCarbonCalcAccess(groups);
  const external_user = isUserExternal(user?.email);

  const { profileLink } = profileLinkState;

  const openCarbonModal = () => {
    setCarbonCaptureModalOpen(true);
  };

  const closeCarbonModal = () => {
    setCarbonCaptureModalOpen(false);
  };

  const closeNotificationModal = () => setNotificationModalOpen(false);

  const fetchEmissionsData = async (calculationTime = 'last_28_days') => {
    setEmissionsDetailsPending(true);

    const getEmissionsDataRequestBody = {
      org_id: asset?.org_id,
      site_id: asset?.site_id,
      asset_id: asset?.asset_id,
      device_id: asset?.device_id,
      tags: {
        emissionsData: {
          tag_id: 2135,
          key: calculationTime,
          agg_func: 'last_value',
        },
      },
    };

    try {
      const response = await getCarbonCaptureDetails({
        accessToken: currentSession?.token,
        body: getEmissionsDataRequestBody,
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();

      setEmissionsDetails(responseData?.[0]?.emissionsData);
    } catch (error) {
      console.error('Error fetching emission data:', error);
    } finally {
      setEmissionsDetailsPending(false);
    }
  };

  useEffect(() => {
    asset && setAsset(asset);
    asset && checkDigitalTwinExistence(asset.asset_name, setDigitalTwinExistence);
    asset?.asset_name && asset?.asset_name !== enterpriseObject?.name && getEnterpriseObject(asset.asset_name);
    asset?.asset_name && asset?.asset_name !== enterpriseObject?.name && modifyChartsRange(DEFAULT_CHART_RANGE_LABEL);
  }, [asset]);

  useEffect(() => {
    asset && enterpriseObject && asset?.asset_name === enterpriseObject?.name && getProfileLink(enterpriseObject?.netsuiteId);
  }, [asset, enterpriseObject]);

  useEffect(() => {
    !fetchedSubscriptions && getSubscriptions();
  }, [fetchedSubscriptions]);

  useEffect(() => {
    currentSession && !emissionsDetails && asset && fetchEmissionsData();
  }, [currentSession, asset]);

  useEffect(() => {
    if (!emissionsDetailsPending) {
      const netAbated = emissionsDetails?.netAbated;

      if (netAbated !== 0 && !netAbated > 0) {
        setShowDataError(true);
      } else {
        setShowDataError(false);
      }
    }
  }, [emissionsDetailsPending]);

  const AdditionalSpecificsComponent = () => (
    <div className="additional-specifics">
      <AssetDetails
        subscriptions={subscriptions}
        enterpriseObject={enterpriseObject}
        updateUserSubscriptions={updateUserSubscriptions}
      />
      <div className="specifics-container">
        <div className="specific-icons">
          {!external_user && (
            <FluxTooltip
              showInfoCircle={false}
              title={UNIT_PROFILE_ICON_TITLE}
              description={UNIT_PROFILE_ICON_DESCRIPTION}
              tooltip={(
                <IoFolderOutline
                  size={24}
                  role="button"
                  onClick={() => window.open(profileLink, '_blank')}
                  className={`unit-profiles-redirect ${!profileLink ? 'disabled' : ''}`}
                />
              )}
            />
          )}
          <FluxTooltip
            showInfoCircle={false}
            title={NOTIFICATIONS_ICON_TITLE}
            description={NOTIFICATIONS_ICON_DESCRIPTION}
            tooltip={(
              <IoNotificationsOutline
                size={24}
                role="button"
                onClick={() => setNotificationModalOpen(true)}
              />
            )}
          />
        </div>
        {(!external_user || hasCccAccess) && (
          <CarbonCaptureInfoContainer
            showDataError={showDataError}
            openCarbonModal={openCarbonModal}
            dataPending={emissionsDetailsPending}
            currentCarbonCaptureTime={currentCarbonCaptureTime}
            setCurrentCarbonCaptureTime={setCurrentCarbonCaptureTime}
            currentVfmTotalAbated={emissionsDetails?.netAbated?.toFixed(4)}
          />
        )}
      </div>
    </div>
  );

  return (!asset || !enterpriseObject)
    ? <>loading placeholder</>
    : (
      <div className="asset-page">
        <AdditionalSpecificsComponent />
        <HeaderStats
          asset={asset}
          accessToken={accessToken}
          addIpCopyToast={addIpCopyToast}
          updateIpAddress={updateIpAddress}
          hasEditIpAccess={hasEditIpAccess}
          enterpriseObject={enterpriseObject}
        />
        <AssetMenu
          selectedRoute={selectedRoute}
          digitalTwinExistence={digitalTwinExistence}
        />
        <AssetRoutes />
        <NotificationModal
          asset={asset}
          subscriptions={subscriptions}
          isOpen={notificationModalOpen}
          closeModal={closeNotificationModal}
          updateSubscriptions={updateUserSubscriptions}
        />
        <CarbonCaptureModal
          showDataError={showDataError}
          closeModal={closeCarbonModal}
          isOpen={carbonCaptureModalOpen}
          fetchEmissionsData={fetchEmissionsData}
          defaultEmissionsDetails={emissionsDetails}
          emissionDetailsPending={emissionsDetailsPending}
          currentCarbonCaptureTime={currentCarbonCaptureTime}
          setCurrentCarbonCaptureTime={setCurrentCarbonCaptureTime}
        />
      </div>
    );
};

export default AssetPage;

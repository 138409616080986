import { useDispatch } from 'react-redux';

import { addNotification } from 'shared/actions/notificationsActions';

import {
  editUserDnd,
  editUserPhoneNumber,
  fetchUserPreferences,
  editUserSubscriptionPlatform,
  resetPreferenceFetchAttempts,
} from 'account-settings/actions/userPreferenceActions';

import {
  editUserSubscriptions,
  fetchUserSubscriptions,
  resetSubscriptionFetchAttempts,
} from 'account-settings/actions/subscriptionsActions';
import AccountSettingsPage from 'account-settings/pages/AccountSettingsPage';
import AccountSettingsPageSkeleton from 'account-settings/pages/AccountSettingsPageSkeleton';

const AccountSettingsPageContainer = ({ user }) => {
  const dispatch = useDispatch();
  const getPreferences = () => dispatch(fetchUserPreferences());
  const getSubscriptions = () => dispatch(fetchUserSubscriptions());
  const updateUserPhone = (preferences) => dispatch(editUserPhoneNumber(preferences));
  const updateUserSubscriptions = (subscriptions) => dispatch(editUserSubscriptions(subscriptions));
  const updateReceivingPlatform = (preferences) => dispatch(editUserSubscriptionPlatform(preferences));
  const updateDndInfo = (preferences) => dispatch(editUserDnd(preferences));
  const toggleDemoMode = (demoMode) => dispatch({ type: 'TOGGLE_DEMO_MODE', payload: demoMode });
  const addErrorToast = (errorMessage) => dispatch(addNotification({ type: 'error', message: errorMessage, duration: '5' }));
  const resetSubscriptionAttempts = () => dispatch(resetSubscriptionFetchAttempts());
  const resetPreferenceAttempts = () => dispatch(resetPreferenceFetchAttempts());

  return (
    user
      ? (
        <AccountSettingsPage
          user={user}
          updateDndInfo={updateDndInfo}
          addErrorToast={addErrorToast}
          toggleDemoMode={toggleDemoMode}
          getPreferences={getPreferences}
          updateUserPhone={updateUserPhone}
          getSubscriptions={getSubscriptions}
          updateUserSubscriptions={updateUserSubscriptions}
          updateReceivingPlatform={updateReceivingPlatform}
          resetPreferenceAttempts={resetPreferenceAttempts}
          resetSubscriptionAttempts={resetSubscriptionAttempts}
        />
      ) : (
        <AccountSettingsPageSkeleton />
      )
  );
};

export default AccountSettingsPageContainer;

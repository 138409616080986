import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'shared/actions/preferencesActionTypes';

export const defaultState = {
  isPending: false,
  fetchAttempts: 0,
  preferences: {},
};

const handlePreferencesFetch = (state) => ({
  ...state,
  fetchAttempts: state.fetchAttempts + 1,
  isPending: true,
});

const handlePreferencesFetchSuccess = (state, { result }) => ({
  ...state,
  isPending: false,
  preferences: result,
});

const handlePreferencesFetchFailed = (state) => ({
  ...defaultState,
  fetchAttempts: state.fetchAttempts,
});

const returnStateAndContinue = (state) => ({
  ...state,
});

const handleHomepageUpdateSuccess = (state, {
  result: {
    Attributes: {
      preference: homepage,
    },
  },
}) => ({
  ...state,
  preferences: {
    ...state.preferences,
    homepage,
  },
});

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.PREFERENCES__FETCH_PREFERENCES, handlePreferencesFetch);
    builder.addCase(actionTypes.PREFERENCES__FETCH_PREFERENCES_SUCCESS, handlePreferencesFetchSuccess);
    builder.addCase(actionTypes.PREFERENCES__FETCH_PREFERENCES_FAILED, handlePreferencesFetchFailed);
    builder.addCase(actionTypes.PREFERENCES__UPDATE_HOMEPAGE, returnStateAndContinue);
    builder.addCase(actionTypes.PREFERENCES__UPDATE_HOMEPAGE_SUCCESS, handleHomepageUpdateSuccess);
    builder.addCase(actionTypes.PREFERENCES__UPDATE_HOMEPAGE_FAILED, returnStateAndContinue);
  },
});

export const selectPreferences = (state) => state?.preferences;
export default preferencesSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import * as configActionTypes from 'dashboard/actions/dashboardConfigActionTypes';
import { parseBreakpointChildren } from 'dashboard/helpers/dashboardHelpers';
import { FLEET_STAT_ID_PREFIX, EHS_STAT_ID } from 'dashboard/constants/dashboardConstants';

export const defaultState = {
  isPending: false,
  data: {
    fleetStatConfig: null,
    ehsConfig: null,
  },
  errorResponse: null,
};
export const selectDasboardConfig = (state) => state?.dashboard?.config;

const handleConfigFetch = () => ({
  ...defaultState,
  isPending: true,
});

const handleConfigFetchFailed = (_, { errorResponse } = {}) => ({
  ...defaultState,
  errorResponse,
});

const handleConfigFetchSucceeded = (_, action = {}) => {
  const breakpointChildren = parseBreakpointChildren(action?.result);
  const fleetStatConfig = breakpointChildren.filter(({ i }) => i.startsWith(FLEET_STAT_ID_PREFIX))
    .map(({ options }) => options);
  const ehsConfig = breakpointChildren.filter(({ i }) => i === EHS_STAT_ID).map(({ options }) => options?.props?.stats).at(0);

  return {
    ...defaultState,
    data: {
      fleetStatConfig,
      ehsConfig,
    },
  };
};

const configSlice = createSlice({
  name: 'assetList',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(configActionTypes.DASHBOARD_CONFIG__FETCH_CONFIG, handleConfigFetch);
    builder.addCase(configActionTypes.DASHBOARD_CONFIG__FETCH_CONFIG_FAILED, handleConfigFetchFailed);
    builder.addCase(configActionTypes.DASHBOARD_CONFIG__FETCH_CONFIG_SUCCEEDED, handleConfigFetchSucceeded);
  },
});

export default configSlice.reducer;

import { IoCall } from 'react-icons/io5';

import FluxTooltip from 'shared/components/FluxTooltip';

import './MechanicStat.scss';

const MechanicStat = ({
  mechanic,
}) => (
  <FluxTooltip
    arrow={false}
    placement="bottom"
    showInfoCircle={false}
    title={mechanic?.phone ?? 'Unavailable'}
    description={`${mechanic?.name ?? ''} Phone Number`}
    tooltip={<span className="mechanic-name-text"><IoCall /> {mechanic?.name ?? 'N/A'}</span>}
  />
);

export default MechanicStat;

import VirtualizedTableCell from 'shared/components/VirtualizedTableCell';
import { getAssetLinkUrl } from 'shared/helpers/altitudeHelpers';

import './VirtualizedTableCellContainer.scss';
import { Link } from 'react-router-dom';

const VirtualizedTableCellContainer = ({
  data,
  style,
  rowIndex,
  columnIndex,
}) => {
  const {
    rawData,
    measuredColumns,
    hoveredRowIndex,
    setHoveredRowIndex,
  } = data;
  const className = hoveredRowIndex === rowIndex ? 'hover' : '';
  const rowData = rawData.at(rowIndex);
  const rowDataFieldName = measuredColumns.at(columnIndex)?.dataIndex;
  const item = rowData?.[rowDataFieldName];

  const handleOnHover = () => {
    setHoveredRowIndex(rowIndex);
  };

  const renderTableCell = () => (
    <VirtualizedTableCell
      item={item}
      style={style}
      rowIndex={rowIndex}
      onHover={handleOnHover}
      columnIndex={columnIndex}
      hoveredClassName={className}
      formattedCells={data.formattedCells}
      lastColumnIndex={measuredColumns.length - 1}
    />
  );

  if (measuredColumns.find((col) => col.title === 'Info')) {
    return (
      <div className="asset-list-table-cell-container">
        {renderTableCell()}
      </div>
    );
  }

  return (
    <Link
      role="link"
      className="asset-list-table-cell-container"
      to={getAssetLinkUrl(rowData)}
    >
      {renderTableCell()}
    </Link>
  );
};

export default VirtualizedTableCellContainer;

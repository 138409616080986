import * as assetListActionTypes from 'dashboard/actions/assetListActionTypes';
import { DEFAULT_ALTITUDE_GROUP_ID } from 'dashboard/constants/dashboardConstants';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';
import { getAssetList } from 'shared/repositories/assetsRepository';

export const fetchAssetList = asyncActionCreator({
  repositoryAPI: getAssetList,
  PENDING_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH,
  SUCCESS_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH_SUCCEEDED,
  FAILED_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH_FAILED,
});

export const fetchFilteredAssetList = asyncActionCreator({
  repositoryAPI: getAssetList,
  PENDING_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH,
  SUCCESS_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_SUCCESS,
  FAILED_ACTION_TYPE: assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_FAILED,
});

export const resetAltitudeGroupFilter = () => ({
  type: assetListActionTypes.DASHBOARD_ASSET_LIST__RESET_ALTITUDE_GROUP,
});

export const setSelectedAltitudeGroupFilter = (altitudeInfo = { altitudeGroupId: DEFAULT_ALTITUDE_GROUP_ID, altitudeGroupName: '' }) => ({
  type: assetListActionTypes.DASHBOARD_ASSET_LIST__SELECT_ALTITUDE_GROUP,
  altitudeInfo,
});

import { useState } from 'react';

import {
  assetListStatusOptionDropdownItems,
  defaultAssetListStatusDropdownOption,
} from 'shared/constants/assetConstants';
import SearchableDropdown from 'shared/components/SearchableDropdown';

import './AssetFilterDropdowns.scss';

const AssetFilterDropdowns = ({
  filterGroups,
  selectedGroup,
  selectAltitudeGroup,
  resetAltitudeGroup,
  selectedStatusFilter,
  setSelectedStatusFilter,
}) => {
  const [groupSearch, setGroupSearch] = useState('');
  const allGroupsOption = { label: 'All Areas', value: -1, key: 'All Areas' };
  const groupOptions = [allGroupsOption, ...filterGroups.map(({ label, value }) => ({
    label,
    value,
    key: value,
  }))];

  const handleGroupSearch = ({ target: { value } }) => {
    setGroupSearch(value);
  };

  const handleGroupSelect = (newGroup) => {
    setGroupSearch('');
    if (newGroup === -1) return resetAltitudeGroup();
    const groupInfo = filterGroups.find((group) => group.value === newGroup);
    return selectAltitudeGroup({
      altitudeGroupId: groupInfo.value,
      altitudeGroupName: groupInfo.label,
    });
  };

  const handleStatusSelect = (newStatus) => {
    setSelectedStatusFilter(newStatus);
  };

  return (
    <div className="asset-filter-dropdowns">
      <SearchableDropdown
        itemHeader="Areas"
        variant="blue-slim"
        selectedHasCheckmark
        placement="bottomLeft"
        searchValue={groupSearch}
        defaultOption={allGroupsOption}
        onItemSelect={handleGroupSelect}
        onSearchChange={handleGroupSearch}
        resetToDefault={resetAltitudeGroup}
        searchPlaceholder='Search "Permian Basin"'
        dropdownValue={groupOptions.find((group) => group.value === selectedGroup)?.label ?? 'All Areas'}
        dropdownItems={groupOptions.filter((group) => group.label.toLowerCase().includes(groupSearch.toLowerCase()))}
      />
      <SearchableDropdown
        variant="blue-slim"
        itemHeader="Activity"
        isSearchable={false}
        selectedHasCheckmark
        placement="bottomLeft"
        onItemSelect={handleStatusSelect}
        dropdownItems={assetListStatusOptionDropdownItems}
        defaultOption={defaultAssetListStatusDropdownOption}
        resetToDefault={() => handleStatusSelect(defaultAssetListStatusDropdownOption.value)}
        dropdownValue={assetListStatusOptionDropdownItems.find((option) => option.value === selectedStatusFilter).label}
      />
    </div>
  );
};

export default AssetFilterDropdowns;

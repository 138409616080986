import { createSlice } from '@reduxjs/toolkit';

import * as statsActionTypes from 'dashboard/actions/dashboardStatsActionTypes';

export const defaultState = {
  isPending: false,
  fetchAttempts: 0,
  requestFailed: false,
  requestSucceeded: false,
  statList: [],
  failedResultList: [],
};

const handleStatsFetch = (state) => ({
  ...defaultState,
  isPending: true,
  fetchAttempts: state.fetchAttempts + 1,
});

const handleStatsFetchFailed = ({ fetchAttempts }, { failedResultList } = {}) => ({
  ...defaultState,
  requestFailed: true,
  failedResultList,
  fetchAttempts,
});

const handleStatsFetchSucceeded = ({ fetchAttempts }, { successfulResultList: statList } = {}) => ({
  ...defaultState,
  requestSucceeded: true,
  statList,
  fetchAttempts,
});

const statsSlice = createSlice({
  name: 'assetList',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(statsActionTypes.DASHBOARD_STATS__FETCH_STATS, handleStatsFetch);
    builder.addCase(statsActionTypes.DASHBOARD_STATS__FETCH_STATS_FAILED, handleStatsFetchFailed);
    builder.addCase(statsActionTypes.DASHBOARD_STATS__FETCH_STATS_SUCCEEDED, handleStatsFetchSucceeded);
  },
});

export default statsSlice.reducer;

import AssetCard from 'dashboard/components/assetDisplay/AssetCard';

import './AssetCardRow.scss';

const AssetCardRow = ({ index, style, data }) => {
  const { tupleizedAssetList } = data;
  const [asset1, asset2] = tupleizedAssetList.at(index);

  return (
    <div style={style} className="asset-card-row">
      <AssetCard
        asset={asset1}
      />
      {asset2 && (
      <AssetCard
        asset={asset2}
      />
      )}
    </div>
  );
};

export default AssetCardRow;

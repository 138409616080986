import * as dashboardActionTypes from 'dashboard/actions/dashboardActionTypes';
import assetListReducer, { defaultState as assetList } from 'dashboard/reducers/assetListSlice';
import configReducer, { defaultState as config } from 'dashboard/reducers/dashboardConfigSlice';
import statsReducer, { defaultState as stats } from 'dashboard/reducers/dashboardStatsSlice';
import filterReducer, { defaultState as filters } from 'dashboard/reducers/filterSlice';

const defaultState = {
  assetList,
  config,
  stats,
  filters,
};

const dashboardReducer = (state = defaultState, action = {}) => (
  action.type === dashboardActionTypes.DASHBOARD__RESET_STATE
    ? defaultState
    : {
      ...state,
      assetList: assetListReducer(state.assetList, action),
      config: configReducer(state.config, action),
      stats: statsReducer(state.stats, action),
      filters: filterReducer(state.filters, action),
    }
);

const selectDashboard = (state) => state.dashboard;

export { defaultState, selectDashboard };
export default dashboardReducer;

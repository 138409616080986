import * as actionTypes from 'single-asset/actions/telemetryChartsRangeActionTypes';

export const defaultState = {
  isPending: false,
  errorResponse: null,
  chartsRange: null,
};

const telemetryChartsRangeReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.TELEMETRY__CHARTS_RANGE_UPDATE: {
      return {
        ...defaultState,
        isPending: false,
        chartsRange: action.newChartsRange,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectTelemetryChartRange = (state) => state?.singleAsset?.telemetry?.chartsRange;

export default telemetryChartsRangeReducer;

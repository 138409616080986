import { createSlice } from '@reduxjs/toolkit';
import * as actionTypes from 'account-settings/actions/accountSettingsActionTypes';

export const defaultState = {
  isPending: false,
  fetchedSuccessfully: false,
  errorResponse: null,
  subscriptions: {},
  fetchAttempts: 0,
};

const handleSubscriptionsCall = (state) => ({
  ...state,
  isPending: true,
});

const handleResetAttempts = () => ({
  ...defaultState,
});

const handleSubscriptionsFetchSuccess = (state, { result: { results: { subscriptions } } }) => ({
  ...defaultState,
  fetchedSuccessfully: true,
  subscriptions,
  fetchAttempts: state.fetchAttempts + 1,
});

const handleSubscriptionsCallFailed = (state, { errorResponse }) => ({
  ...defaultState,
  errorResponse,
  fetchAttempts: state.fetchAttempts + 1,
});

const handleSubscriptionsUpdateSuccess = (_, { subscriptions }) => ({
  ...defaultState,
  subscriptions,
});

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS, handleSubscriptionsCall);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_FAILED, handleSubscriptionsCallFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_SUCCESS, handleSubscriptionsFetchSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS, handleSubscriptionsCall);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_FAILED, handleSubscriptionsCallFailed);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_SUCCESS, handleSubscriptionsUpdateSuccess);
    builder.addCase(actionTypes.ACCOUNT_SETTINGS__RESET_SUBSCRIPTION_FETCH_ATTEMPTS, handleResetAttempts);
  },
});

export const selectSubscriptions = (state) => state.accountSettings.subscriptions;

export default subscriptionsSlice.reducer;

import * as telemetryEventsActionTypes from 'single-asset/actions/telemetryEventsActionTypes';

export const defaultState = {
  successfulResponse: false,
  isPending: false,
  eventList: [],
};

const telemetryEventsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS_FAILED: {
      const { errorResponse } = action;

      return {
        ...defaultState,
        errorResponse,
      };
    }

    case telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS_SUCCEEDED: {
      return {
        ...defaultState,
        eventList: action.result,
        successfulResponse: true,
        isPending: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default telemetryEventsReducer;

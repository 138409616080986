import { useNavigate } from 'react-router-dom';
import { IoAddOutline, IoCalendarClearOutline } from 'react-icons/io5';

import { sections } from 'reporting/constants/reportingConstants';

import './ReportingHeader.scss';

const ReportingHeader = ({
  activeSection,
  reportBeingEdited,
}) => {
  const navigate = useNavigate();

  return (
    <div className="heading-content">
      {!reportBeingEdited ? (
        <>
          <h1>Flux Reporting</h1>
          <button
            onClick={() => navigate('')}
            className={activeSection === sections.NEW_REPORT ? 'active' : ''}
          >
            <IoAddOutline className="nav-icon" /> { ' ' }
            Create new report
          </button>
          <button
            onClick={() => navigate('scheduled')}
            className={activeSection === sections.SCHEDULED ? 'active' : ''}
          >
            <IoCalendarClearOutline className="nav-icon" /> { ' ' }
            Scheduled reports
          </button>
        </>
      ) : (
        <>
          <h1>Editing Report</h1>
          <h2>{reportBeingEdited?.editName ?? reportBeingEdited.userReportName}</h2>
        </>
      )}
    </div>
  );
};

export default ReportingHeader;

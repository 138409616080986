import ChartsList from 'single-asset/components/charts/ChartsList';
import EditChartList from 'single-asset/components/charts/edit-charts/DraggableChartList';

const ChartsContentSection = ({
  chartsLayout,
  isEditingCharts,
  getChartsByDate,
  setChartsLayout,
  displayGridView,
  resetChartsLayout,
  scheduledChartData,
}) => (
  <div className="charts-container--chart-content">
    {
      !isEditingCharts ? (
        <ChartsList
          chartData={scheduledChartData}
          getChartsByDate={getChartsByDate}
          displayGridView={displayGridView}
          resetChartsLayout={resetChartsLayout}
        />
      ) : (
        <EditChartList
          chartsLayout={chartsLayout}
          setChartsLayout={setChartsLayout}
        />
      )
    }
  </div>
);

export default ChartsContentSection;

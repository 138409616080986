import { useEffect, useState } from 'react';

import AssetListTable from 'shared/components/VirtualizedTable';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import { createToggles } from 'shared/helpers/notificationHelpers';
import { assetFieldNames } from 'shared/constants/altitudeConstants';

import {
  NOTIFICATION_SECTIONS,
  notificationsTableScroll,
  notificationsTableColumns,
  NOTIFICATIONS_TABLE_ROW_HEIGHT,
} from 'account-settings/constants/accountSettingsConstants';
import NotificationSettings from 'account-settings/components/notifications/NotificationSettings';

import './NotificationManagement.scss';

const NotificationManagement = ({
  assets,
  dndValues,
  preferences,
  setDndValues,
  activeSection,
  userSubscriptions,
  modifiedSubscriptions,
  setModifiedSubscriptions,
  updateReceivingPlatform,
}) => {
  const filterFields = [
    assetFieldNames.MODEL,
    assetFieldNames.ORG_NAME,
    assetFieldNames.SITE_NAME,
    assetFieldNames.ASSET_NAME,
  ];

  const [filteredAssets, setFilteredAssets] = useState(getFilteredAssetList(assets, null, '', ''));
  const [assetFilterText, setAssetFilterText] = useState('');

  const toggleSubscriptionPlatform = (platform) => updateReceivingPlatform({ ...preferences, [platform]: !preferences[platform] });

  const handleDndStartChange = (_, option) => {
    setDndValues({
      ...dndValues,
      dnd_start: option.value,
    });
  };

  const handleDndStopChange = (_, option) => {
    setDndValues({
      ...dndValues,
      dnd_stop: option.value,
    });
  };

  const tableData = filteredAssets.map((asset) => ({
    ...asset,
    ...createToggles(asset, modifiedSubscriptions, setModifiedSubscriptions),
  }));

  const subscriptionKeys = Object.keys(userSubscriptions);
  const nonEmptySubscriptions = subscriptionKeys.filter((subKey) => Object.values(userSubscriptions[subKey]).includes(true));

  const shownTableData = tableData.filter((assetRow) => (
    activeSection === NOTIFICATION_SECTIONS.subscribed
      ? nonEmptySubscriptions.includes(assetRow.asset_id.toString())
      : !nonEmptySubscriptions.includes(assetRow.asset_id.toString())
  ));

  useEffect(() => {
    setFilteredAssets(getFilteredAssetList(assets, null, assetFilterText, filterFields));
  }, [assetFilterText]);

  return (
    <div className="notification-management">
      <NotificationSettings
        dndValues={dndValues}
        preferences={preferences}
        activeSection={activeSection}
        setAssetFilterText={setAssetFilterText}
        handleDndStopChange={handleDndStopChange}
        handleDndStartChange={handleDndStartChange}
        toggleSubscriptionPlatform={toggleSubscriptionPlatform}
      />
      <AssetListTable
        formattedCells={false}
        onNavigate={() => null}
        dataSource={shownTableData}
        scroll={notificationsTableScroll}
        columns={notificationsTableColumns}
        rowHeight={NOTIFICATIONS_TABLE_ROW_HEIGHT}
      />
    </div>
  );
};

export default NotificationManagement;

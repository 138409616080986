import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  editScheduledReport,
  getAvailableReports,
  postImmediateReport,
  postScheduledReport,
} from 'shared/repositories/reportingRepository';
import { selectSession } from 'shared/reducers/sessionReducer';
import ReportingHeader from 'reporting/components/ReportingHeader';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { addNotification } from 'shared/actions/notificationsActions';
import { selectOrganizations } from 'shared/reducers/organizationsSlice';

import {
  REPORT_EDIT_FAILURE,
  REPORT_EDIT_SUCCESS,
  REPORT_ALREADY_EXISTS,
  REPORT_CREATION_FAILURE,
  REPORT_CREATION_SUCCESS,
} from 'reporting/constants/reportingConstants';
import ReportingRoutes from 'reporting/pages/ReportingRoutes';
import { checkReportingAccess } from 'reporting/helpers/reportingHelpers';

import './ReportingPage.scss';

const ReportingPage = () => {
  const params = useParams();
  const selectedRoute = params['*'];

  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { groups } = useSelector(selectPermissions);
  const { organizations } = useSelector(selectOrganizations);

  const [reportsToCreate, setReportsToCreate] = useState(null);
  const [reportBeingEdited, setReportBeingEdited] = useState(null);

  const submitNewReport = async ({ oneTimeReport, requestData, reportName }) => {
    const accessToken = session.token;

    try {
      const result = oneTimeReport
        ? await postImmediateReport({ accessToken, reportName, body: requestData })
        : await postScheduledReport({ accessToken, body: requestData });

      if (result.status === 200) {
        dispatch(addNotification({ type: 'success', message: REPORT_CREATION_SUCCESS }));
        return result.createdReport ?? true;
      }

      if (result.message.includes('already exists')) {
        dispatch(addNotification({ type: 'error', message: REPORT_ALREADY_EXISTS }));
      } else dispatch(addNotification({ type: 'error', message: REPORT_CREATION_FAILURE }));
    } catch (err) {
      dispatch(addNotification({ type: 'error', message: REPORT_CREATION_FAILURE }));
    }
    return null;
  };

  const editExistingReport = async ({ requestData }) => {
    const accessToken = session.token;

    try {
      const result = await editScheduledReport({ accessToken, body: requestData });
      if (result.status === 200) {
        dispatch(addNotification({ type: 'success', message: REPORT_EDIT_SUCCESS }));
        return result.editedReport;
      }

      dispatch(addNotification({ type: 'error', message: REPORT_EDIT_FAILURE }));
    } catch (err) {
      dispatch(addNotification({ type: 'error', message: REPORT_EDIT_FAILURE }));
    }
    return null;
  };

  const fetchAvailableReports = async () => {
    try {
      const response = await getAvailableReports({ accessToken: session.token });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const reportsData = await response.json();
      setReportsToCreate(reportsData?.reports);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  };

  useEffect(() => {
    if (!session) {
      return;
    }

    if (checkReportingAccess(groups) && !reportsToCreate?.length) {
      fetchAvailableReports();
    }
  }, [session, groups]);

  if (!checkReportingAccess(groups)) return <p>no access</p>;

  return (
    <div className="reporting-page">
      <ReportingHeader
        activeSection={selectedRoute}
        reportBeingEdited={reportBeingEdited}
      />
      <div className="reporting-content">
        <ReportingRoutes
          orgs={organizations}
          availableReports={reportsToCreate}
          reportBeingEdited={reportBeingEdited}
          setReportBeingEdited={setReportBeingEdited}
          onSubmit={reportBeingEdited ? editExistingReport : submitNewReport}
        />
      </div>
    </div>
  );
};

export default ReportingPage;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import FluxStatList from 'shared/components/FluxStatList';
import ChartsContainer from 'single-asset/components/charts/ChartsContainer';
import { getStatsConfiguration } from 'single-asset/constants/telemetryStatTagConfiguration';

import { selectEnterpriseObject } from 'single-asset/reducers/enterpriseObjectReducer';
import './TelemetryPage.scss';

const TelemetryPage = ({
  stats,
  asset,
  config,
  charts,
  location,
  getStats,
  getConfig,
  defaultDates,
  chartMetrics,
  modifyCharts,
  resetStatsData,
  resetChartData,
  getChartSeries,
  getChartMetrics,
  chartRangeState,
  resetChartConfig,
  modifyChartsRange,
}) => {
  const { assetId, isPending: metricsPending } = chartMetrics;
  const { enterpriseObject: eo } = useSelector(selectEnterpriseObject);

  const sendChartsRequest = (dateRange, fromCharts = false) => {
    const { chartsConfig, fluxCharts } = config.data;
    getChartSeries(fluxCharts ?? chartsConfig, dateRange, fromCharts, asset);
  };

  const fetchChartsOnPageLoad = () => {
    const start = charts?.dates?.[0];
    const end = charts?.dates?.[1];
    !!start && !!end
      ? sendChartsRequest([start, end])
      : !charts?.seriesList?.length && sendChartsRequest(defaultDates);
  };

  const fetchStats = () => {
    const { driver } = asset;
    const statsConfiguration = driver && eo.packageModel ? getStatsConfiguration(driver, eo.packageModel) : getStatsConfiguration();
    !stats?.statsList?.length && !stats?.isPending && getStats(statsConfiguration, asset);
  };

  const resetCharts = () => {
    charts?.seriesList?.length && resetChartData();
    stats?.statsList?.length && resetStatsData();
    resetChartConfig();
  };

  useEffect(() => {
    const deviceId = location?.pathname?.split('/')?.at(-1);
    if (deviceId !== asset?.device_id?.toString()) {
      resetCharts();
    }
  }, []);

  useEffect(() => {
    const {
      asset_id, org_id, site_id,
    } = asset;
    (charts?.refetchCharts
      || (asset_id && asset_id !== assetId && !config?.data?.isPending && !config?.data?.chartsConfig))
        && getConfig(asset);
    asset_id && asset_id !== assetId && !metricsPending && getChartMetrics({ asset_id, org_id, site_id });
    (config?.data?.chartsConfig || config?.data?.fluxCharts) && !charts?.refetchCharts && !charts?.isPending && fetchChartsOnPageLoad();
  }, [config.data, location, asset, charts?.refetchCharts]);

  useEffect(() => {
    asset && eo && !stats.statsList.length && fetchStats();
  }, [asset, eo]);

  return (
    <div className="telemetry-page">
      <FluxStatList
        statsList={stats?.statsList}
        configList={getStatsConfiguration()}
        additionalClassNames="telemetry-statistics"
      />
      {charts?.dates
        && (
          <ChartsContainer
            asset={asset}
            charts={charts}
            defaultDates={defaultDates}
            chartMetrics={chartMetrics}
            modifyCharts={modifyCharts}
            chartRangeState={chartRangeState}
            getChartsByDate={sendChartsRequest}
            modifyChartsRange={modifyChartsRange}
            legacyLayout={config?.data?.completeLegacyResponse}
            chartsLayout={config?.data?.fluxCharts ?? config?.data?.chartsConfig}
          />
        )}
    </div>
  );
};

export default TelemetryPage;

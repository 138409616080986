import { format } from 'date-fns';
import { getOffsetDateArr } from 'shared/helpers/datePickerHelpers';
import { extendedDayJs } from 'shared/helpers/timeHelpers';

import {
  DEFAULT_DATE_FORMAT,
  MM_DD_YYYY_DATE_FORMATTER,
  MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED,
} from 'single-asset/constants/singleAssetConstants';

export const findAssetMatch = (assetList, {
  org_id,
  site_id,
  asset_id,
  device_id,
}) => assetList.find((asset) => (
  `${asset.org_id}` === `${org_id}`
    && `${asset.site_id}` === `${site_id}`
      && `${asset.asset_id}` === `${asset_id}`
        && `${asset.device_id}` === `${device_id}`
));

export const isNewAsset = ({
  org_id,
  site_id,
  asset_id,
  device_id,
}, {
  orgId,
  siteId,
  assetId,
  deviceId,
}) => (
  `${orgId}/${siteId}/${assetId}/${deviceId}`
  !== `${org_id}/${site_id}/${asset_id}/${device_id}`
);

const ensureDayJsDates = (dates) => ([
  extendedDayJs(dates?.at(0)).format('MM/DD/YYYY'),
  extendedDayJs(dates?.at(1)).format('MM/DD/YYYY'),
]);

export const createChartSeriesReducer = ({
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
  RESET_ACTION_TYPE,
  PENDING_MODIFY,
  FAILED_MODIFY,
  SUCCESS_MODIFY,
  RESET_ERROR,
  RESET_REFETCH,
  defaultState,
}) => (state = defaultState, action = {}) => {
  switch (action.type) {
    case PENDING_ACTION_TYPE: {
      return {
        ...defaultState,
        isPending: true,
        dates: action.dates,
      };
    }

    case FAILED_ACTION_TYPE: {
      const { failedResultList } = action;

      return {
        ...defaultState,
        failedResultList,
        dates: ensureDayJsDates(state.dates),
      };
    }

    case SUCCESS_ACTION_TYPE: {
      return {
        ...defaultState,
        seriesList: action.successfulResultList,
        dates: ensureDayJsDates(state.dates),
      };
    }

    case RESET_ACTION_TYPE: {
      return {
        ...defaultState,
      };
    }

    case PENDING_MODIFY: {
      return {
        ...state,
      };
    }

    case FAILED_MODIFY: {
      return {
        ...state,
        modifyError: action.errorResponse,
      };
    }

    case SUCCESS_MODIFY: {
      return {
        ...state,
        refetchCharts: true,
        seriesList: [],
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        modifyError: null,
      };
    }

    case RESET_REFETCH: {
      return {
        ...state,
        refetchCharts: false,
      };
    }

    default: {
      return state;
    }
  }
};

export const createStatsReducer = ({
  PENDING_ACTION_TYPE,
  SUCCESS_ACTION_TYPE,
  FAILED_ACTION_TYPE,
  RESET_ACTION_TYPE,
  defaultState,
}) => (state = defaultState, action = {}) => {
  switch (action.type) {
    case PENDING_ACTION_TYPE: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case FAILED_ACTION_TYPE: {
      const { failedResultList } = action;

      return {
        ...defaultState,
        failedResultList,
      };
    }

    case SUCCESS_ACTION_TYPE: {
      return {
        ...defaultState,
        statsList: action.successfulResultList,
      };
    }

    case RESET_ACTION_TYPE: {
      return {
        ...defaultState,
      };
    }

    default: {
      return state;
    }
  }
};

export const groupListHasAccess = (groupList, groupIdsWithAccess = []) => (
  !!groupList?.map((group) => groupIdsWithAccess.includes(group?.groupId)).includes(true)
);

export const getFormattedDateFromUTC = (milliseconds) => format(new Date(parseInt(`${milliseconds}`.trim())), DEFAULT_DATE_FORMAT);

const now = new Date();

export const formatDateRangeAsStrings = (dateRange, formatter = MM_DD_YYYY_DATE_FORMATTER) => ([
  dateRange.at(0).format(formatter),
  dateRange.at(1).format(formatter),
]);

export const getDefaultDateRange = () => (getOffsetDateArr([
  extendedDayJs(now).subtract(1, 'days'),
  extendedDayJs(now),
]));

export const convertUTCToDateRangeString = (utc) => formatDateRangeAsStrings([
  extendedDayJs(utc),
  extendedDayJs(utc).add(1, 'days'),
]);

export const formatDateStringArrayAsMomentObjects = (dateRange) => ([
  extendedDayJs(dateRange?.at(0), MM_DD_YYYY_DATE_FORMATTER),
  extendedDayJs(dateRange?.at(1), MM_DD_YYYY_DATE_FORMATTER),
]);

export const formatDateStringsForCharts = (dateRange) => ([
  extendedDayJs(dateRange?.at(0))?.format(MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED),
  extendedDayJs(dateRange?.at(1))
    ?.set('hour', 23)
    ?.set('minute', 59)
    ?.set('second', 59)
    ?.format(MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED),
]);

export const formatTimeStringsForCharts = (timeRange) => (
  timeRange?.map((time) => extendedDayJs(time).format())
);

export const calculatePropertyName = ({ agg, label }) => {
  const [, aggregatorMethodName] = Object.keys(agg);
  return `${label} ${aggregatorMethodName}`;
};

export const formatXAxisPropertyToDatestring = (dataList, propertyName) => dataList.map((data) => ({
  ...data,
  [propertyName]: extendedDayJs(data[propertyName]).format(MOMENT_DATE_FORMAT__MOMENT_IS_DEPRECATED),
}));

export const isValidIpAddress = (address) => !!/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(address);

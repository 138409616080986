import { useState, useEffect, useTransition } from 'react';

import ChartSkeleton from 'single-asset/components/charts/ChartSkeleton';
import ChartForm from 'single-asset/components/charts/edit-charts/ChartForm';
import ChartsContentSection from 'single-asset/components/charts/ChartsContentSection';

import './ChartsContainer.scss';

const ChartsContainer = ({
  asset,
  charts,
  defaultDates,
  chartMetrics,
  modifyCharts,
  legacyLayout,
  chartRangeState,
  getChartsByDate,
  modifyChartsRange,
  chartsLayout: existingCharts,
}) => {
  const [scheduledChartData, setScheduledChartData] = useState([]);
  const [isEditingCharts, setIsEditingCharts] = useState(false);
  const [chartsLayout, setChartsLayout] = useState(existingCharts);
  const [isRenderingCharts, startTransition] = useTransition();
  const [displayGridView, setDisplayGridView] = useState(false);

  const resetChartsLayout = () => setChartsLayout(existingCharts);

  useEffect(() => {
    startTransition(() => setScheduledChartData(charts?.seriesList));
  }, [charts]);

  useEffect(() => {
    setChartsLayout(existingCharts);
  }, [existingCharts]);

  return charts.isPending ? (
    <ChartSkeleton />
  ) : (
    <div className="charts-container">
      <ChartForm
        asset={asset}
        dates={charts?.dates}
        defaultDates={defaultDates}
        chartMetrics={chartMetrics}
        chartsLayout={chartsLayout}
        modifyCharts={modifyCharts}
        legacyLayout={legacyLayout}
        isDisabled={charts?.isPending}
        chartRangeState={chartRangeState}
        getChartsByDate={getChartsByDate}
        isEditingCharts={isEditingCharts}
        displayGridView={displayGridView}
        resetChartsLayout={resetChartsLayout}
        modifyChartsRange={modifyChartsRange}
        setDisplayGridView={setDisplayGridView}
        setIsEditingCharts={setIsEditingCharts}
      />
      <ChartsContentSection
        chartsLayout={chartsLayout}
        displayGridView={displayGridView}
        isEditingCharts={isEditingCharts}
        getChartsByDate={getChartsByDate}
        setChartsLayout={setChartsLayout}
        resetChartsLayout={resetChartsLayout}
        scheduledChartData={scheduledChartData}
      />
    </div>
  );
};

export default ChartsContainer;

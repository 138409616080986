import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

// import * as dashboardConstants from 'dashboard/constants/dashboardConstants';
import PillToggleButton from 'shared/components/PillToggleButton';

import {
  DEBOUNCED_INPUT_MILLISECOND_DELAY,
  ASSET_LIST_SEARCH_PLACEHOLDER_TEXT,
} from 'dashboard/constants/dashboardConstants';
import AssetListFormInput from 'dashboard/components/filtration/AssetListFormInput';

import './AssetListForm.scss';

const AssetListForm = ({
  disabled,
  onNavigate,
  onFilterChange,
  children = null,
  assetListFilters,
  onDisplayTypeChange,
  selectAltitudeGroup,
}) => {
  const [currentInputValue, setCurrentInputValue] = useState('');

  const handleTextInputChange = (filterText) => {
    setCurrentInputValue(filterText);
  };

  const handleInputOptionSelected = (link) => {
    if (link.includes('tagId')) {
      const { tagId, tagName } = JSON.parse(link);
      selectAltitudeGroup({ altitudeGroupId: parseInt(tagId), altitudeGroupName: tagName });
      setCurrentInputValue('');
      handleTextInputChange('');
      onNavigate('/dashboard');
    } else {
      onNavigate(link);
      setCurrentInputValue('');
      handleTextInputChange('');
    }
  };

  const debouncedOnFilterChange = useMemo(
    () => debounce((filterText, selectedStatusFilter) => {
      onFilterChange({ filterText, selectedStatusFilter });
    }, DEBOUNCED_INPUT_MILLISECOND_DELAY),
    [currentInputValue, onFilterChange],
  );

  useEffect(() => {
    debouncedOnFilterChange(currentInputValue);
  }, [currentInputValue]);

  return (
    <div className="asset-list-form">
      {children}
      <div className="asset-list-form__filter-label-row">
        <AssetListFormInput
          disabled={disabled}
          value={currentInputValue}
          onChange={handleTextInputChange}
          assetListFilters={assetListFilters}
          onSelect={handleInputOptionSelected}
          placeholder={!assetListFilters.length ? ASSET_LIST_SEARCH_PLACEHOLDER_TEXT : ''}
        />
        <PillToggleButton
          disabled={disabled}
          onToggle={onDisplayTypeChange}
          toggleNameList={['Card View', 'Table View']}
        />
      </div>
    </div>
  );
};

export default AssetListForm;

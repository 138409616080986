import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AssetPage from 'single-asset/pages/AssetPage';
import { setAsset } from 'single-asset/actions/singleAssetActions';
import { selectPermissions } from 'shared/reducers/permissionsSlice';
import { addNotification } from 'shared/actions/notificationsActions';
import { findAssetMatch } from 'single-asset/helpers/singleAssetHelpers';
import { checkIpEditPermissions } from 'single-asset/helpers/assetHelpers';
import { IP_COPY_SUCCESS_MESSAGE } from 'single-asset/constants/headerConstants';
import { selectSubscriptions } from 'account-settings/reducers/subscriptionsSlice';
import { selectEnterpriseObject } from 'single-asset/reducers/enterpriseObjectReducer';
import { editUserSubscriptions, fetchUserSubscriptions } from 'account-settings/actions/subscriptionsActions';
import { fetchEnterpriseObject, updateEnterpriseObjectIpAddress } from 'single-asset/actions/enterpriseObjectActions';

import FullScreenLoader from 'FullScreenLoader';

const AssetPageContainer = ({ user, assetList, accessToken }) => {
  const dispatch = useDispatch();
  const setAssetState = (asset) => dispatch(setAsset(asset));
  const getSubscriptions = () => dispatch(fetchUserSubscriptions());
  const getEnterpriseObject = (assetName) => dispatch(fetchEnterpriseObject({ assetName }));
  const updateIpAddress = (ipAddress) => dispatch(updateEnterpriseObjectIpAddress(ipAddress));
  const updateUserSubscriptions = (subscriptions) => dispatch(editUserSubscriptions(subscriptions));
  const addIpCopyToast = () => dispatch(addNotification({ type: 'success', message: IP_COPY_SUCCESS_MESSAGE }));

  const { groups } = useSelector(selectPermissions);
  const { enterpriseObject } = useSelector(selectEnterpriseObject);
  const { fetchedSuccessfully: fetchedSubscriptions, subscriptions } = useSelector(selectSubscriptions);

  const params = useParams();
  const selectedRoute = params['*'];

  const asset = (
    !assetList.isPending
      ? findAssetMatch(assetList.completeAssetList, params)
      : null
  );

  return user
    ? (
      <AssetPage
        user={user}
        asset={asset}
        setAsset={setAssetState}
        accessToken={accessToken}
        selectedRoute={selectedRoute}
        subscriptions={subscriptions}
        addIpCopyToast={addIpCopyToast}
        updateIpAddress={updateIpAddress}
        enterpriseObject={enterpriseObject}
        getSubscriptions={getSubscriptions}
        getEnterpriseObject={getEnterpriseObject}
        fetchedSubscriptions={fetchedSubscriptions}
        hasEditIpAccess={checkIpEditPermissions(groups)}
        updateUserSubscriptions={updateUserSubscriptions}
      />
    ) : <FullScreenLoader loading />;
};

export default AssetPageContainer;

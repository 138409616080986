import {
  CUSTOMER_ADVANCED_TECH,
  CUSTOMER_DECODER,
  FLOGISTIX_ADVANCED_TECH,
  FLOGISTIX_DECODER,
} from 'shared/constants/permissionsConstants';

export const sharedRequestBody = (asset) => ({
  org_id: asset?.org_id,
  site_id: asset?.site_id,
  asset_id: asset?.asset_id,
  device_id: asset?.device_id,
});

export const checkIpEditPermissions = (groups) => (
  groups.some((group) => group === CUSTOMER_DECODER
    || group === CUSTOMER_ADVANCED_TECH
    || group === FLOGISTIX_DECODER
    || group === FLOGISTIX_ADVANCED_TECH)
);

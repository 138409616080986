import { IoTimeOutline } from 'react-icons/io5';

import {
  DND_OPTIONS,
  NOTIFICATION_SECTIONS,
  DELIVERY_METHOD_TOGGLE_TEXTS,
  NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER,
} from 'account-settings/constants/accountSettingsConstants';

import FluxDropdown from 'shared/components/FluxDropdown';
import ToggleButton from 'shared/components/ToggleButton';
import FluxAutoComplete from 'shared/components/FluxAutoComplete';

const NotificationSettings = ({
  dndValues,
  preferences,
  activeSection,
  setAssetFilterText,
  handleDndStopChange,
  handleDndStartChange,
  toggleSubscriptionPlatform,
}) => (
  <div className="filter-section">
    <div className="text-and-toggle">
      <div className="toggle-group">
        <ToggleButton
          disabled={false}
          showTooltip={false}
          checked={preferences.send_email}
          title={DELIVERY_METHOD_TOGGLE_TEXTS.email}
          onClick={() => toggleSubscriptionPlatform('send_email')}
        />
        <ToggleButton
          disabled={false}
          showTooltip={false}
          checked={preferences.send_sms}
          title={DELIVERY_METHOD_TOGGLE_TEXTS.text}
          onClick={() => toggleSubscriptionPlatform('send_sms')}
        />
      </div>
      <FluxAutoComplete
        placeholder={NOTIFICATIONS_ASSET_FILTER_PLACEHOLDER}
        onChange={(input) => setAssetFilterText(input.toLowerCase())}
      />
    </div>
    { activeSection === NOTIFICATION_SECTIONS.subscribed && (
    <div className="dnd-section">
      <p>Do Not Disturb</p>
      <div className="dnd-pickers">
        <FluxDropdown
          clearable
          label="Start Time"
          options={DND_OPTIONS}
          popupPlacement="bottomLeft"
          placeholder="Select Start Time"
          popupClasses="flexed-dnd-start"
          icon={<IoTimeOutline size={28} />}
          handleChange={handleDndStartChange}
          handleClear={() => handleDndStartChange('', { value: null })}
          value={DND_OPTIONS.find((option) => option.value === dndValues?.dnd_start)?.label}
        />
        <FluxDropdown
          clearable
          label="End Time"
          options={DND_OPTIONS}
          popupPlacement="bottomLeft"
          placeholder="Select End Time"
          popupClasses="flexed-dnd-stop"
          icon={<IoTimeOutline size={28} />}
          handleChange={handleDndStopChange}
          handleClear={() => handleDndStopChange('', { value: null })}
          value={DND_OPTIONS.find((option) => option.value === dndValues?.dnd_stop)?.label}
        />
      </div>
    </div>
    )}
  </div>
);

export default NotificationSettings;

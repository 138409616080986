import React from 'react';
import { Table } from 'antd';

const AssetEventsTable = ({
  columns, data, handleClick, rowClassName,
}) => (
  <Table
    columns={columns}
    dataSource={data}
    pagination={false}
    scroll={{ y: 500 }}
    onRow={handleClick}
    rowClassName={rowClassName}
    className="asset-events-table"
    data-testid="asset-events-table"
  />
);

export default AssetEventsTable;

import { assetFieldNames } from 'shared/constants/altitudeConstants';

export const DEFAULT_ALTITUDE_GROUP_ID = -1;
export const DEFAULT_ASSET_TYPE_ID = -1;
export const DASHBOARD_ID = 1;
export const DEBOUNCED_INPUT_MILLISECOND_DELAY = 300;
export const ASSET_LIST_SEARCH_PLACEHOLDER_TEXT = 'Filter by asset, area, type, etc.';
export const FLEET_STAT_ID_PREFIX = 'card-';
export const EHS_STAT_ID = 'ehss';
export const assetTableColumnIndexes = {
  CUSTOMER_COLUMN_INDEX: 0,
  LOCATION_COLUMN_INDEX: 1,
  ASSET_COLUMN_INDEX: 2,
  RUN_TIME_STATUS_COLUMN_INDEX: 3,
  RUN_TIME_PERCENTAGE_COLUMN_INDEX: 4,
  DRIVER_COLUMN_INDEX: 5,
  LAST_SYNCED_COLUMN_INDEX: 6,
};
export const ASSET_LIST_TABLE_ROW_HEIGHT = 64;
export const assetListTableColumns = [{
  title: 'Customer',
  dataIndex: 'org_name',
  defaultSortOrder: 'ascend',
  className: 'asset-list-table--customer-column',
}, {
  title: 'Location',
  dataIndex: 'site_name',
}, {
  title: 'Asset',
  dataIndex: 'asset_name',
}, {
  title: 'Status',
  dataIndex: 'status',
}, {
  title: 'Run Time',
  dataIndex: 'runtime',
}, {
  title: 'Driver',
  dataIndex: 'driver',
}, {
  title: 'Last Synced',
  dataIndex: 'last_synced',
  className: 'asset-list-table--last-synced-column',
},
];

export const assetListTableScroll = {
  y: 600,
  x: '50vw',
};

export const MINIMUM_AUTOCOMPLETE_FILTER_LENGTH = 2;

export const notRunningAssetsTooltip = {
  tooltip: 'Inactive or Faulted Assets',
  title: 'Inactive Assets',
  description: 'Assets that are inactive and are unable to have Mechanical Availability.',
};

export const activeAssetsTooltip = {
  tooltip: 'Active Assets',
  title: 'Active Assets',
  description: 'Assets that are active and able to have Mechanical Availability.',
};
export const DASHBOARD_START_ROUTE = '/dashboard';
export const assetListDisplayTypes = {
  TABLE_VIEW: 'TABLE_VIEW',
  CARD_VIEW: 'CARD_VIEW',
};

export const assetCardListSortOptions = [{
  value: assetFieldNames.ORG_NAME,
  label: 'Alphabetical (Customer)',
}, {
  value: assetFieldNames.SITE_NAME,
  label: 'Alphabetical (Location)',
}, {
  value: assetFieldNames.ASSET_NAME,
  label: 'Numerical (Asset)',
}, {
  value: assetFieldNames.RUNTIME,
  label: 'Numerical (MA)',
}];

export const excludedAutocompleteFields = [
  assetFieldNames.DRIVER,
  assetFieldNames.STATUS,
];

export const myAssets = {
  pathname: 'my-assets',
  title: 'My Assets',
};

export const allAssets = {
  pathname: 'dashboard',
  title: 'All Assets',
};

export const NAV_ITEMS = [allAssets, myAssets];

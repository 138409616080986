import { IoCreateOutline } from 'react-icons/io5';

const EditableIpStat = ({
  ipAddress,
  setEditing,
  addIpCopyToast,
  hasEditIpAccess,
}) => {
  const handleCopyIpAddress = () => {
    if (ipAddress) {
      navigator.clipboard.writeText(ipAddress);
      addIpCopyToast();
    }
  };

  return (
    <div>
      <div className={`ip-stat ip-stat-left ${ipAddress ? 'ip-stat-copyable' : ''}`}>
        {
          hasEditIpAccess && (
            <IoCreateOutline
              className="edit-icon"
              onClick={() => setEditing(true)}
            />
          )
        }
        <button onClick={handleCopyIpAddress}>{ipAddress ?? 'Unknown'}</button>
      </div>
    </div>
  );
};

export default EditableIpStat;

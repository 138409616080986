import {
  IoGrid,
  IoGridOutline,
  IoMail,
  IoMailOutline,
  IoNotifications,
  IoNotificationsOutline,
  IoStatsChart,
  IoStatsChartOutline,
  IoTerminal,
  IoTerminalOutline,
} from 'react-icons/io5';

/*
  For easy development access, add new dev routes to devLinks and change path
  {
    key: 'DevelopmentPage',
    icon: 'IoTerminal',
    label: 'DevelopmentPage',
    path: '/pathToDevPage',
  },
*/

export const getNavLinks = (homepage) => ([
  {
    key: 'Dashboard',
    icon: 'IoGrid',
    label: 'Dashboard',
    path: `/${homepage ?? 'dashboard'}`,
  },
  {
    key: 'Reporting',
    icon: 'IoStatsChart',
    label: 'Reporting',
    path: '/reports',
  },
  {
    key: 'Notifications',
    icon: 'IoNotifications',
    label: 'Notifications',
    path: '/notifications',
  },
]);

const icons = {
  IoGrid,
  IoGridOutline,
  IoMail,
  IoMailOutline,
  IoNotifications,
  IoNotificationsOutline,
  IoStatsChart,
  IoStatsChartOutline,
  IoTerminal,
  IoTerminalOutline,
};

export const generateNavIcon = (iconName, isActive) => {
  const Icon = icons[`${iconName}${isActive ? '' : 'Outline'}`];
  return (
    <Icon />
  );
};

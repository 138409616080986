import { Skeleton } from 'antd';

import './NotificationSkeleton.scss';

const NotificationSkeleton = ({ active }) => (
  <div>
    <Skeleton
      active={active}
      className="notification-top-skeleton"
      title={{ width: '40%' }}
      paragraph={{ rows: 2 }}
    />
    <Skeleton
      active={active}
      className="notification-body-skeleton"
      title={{ width: '30%' }}
      paragraph={{ rows: 1, width: ['100%'] }}
    />
  </div>
);

export default NotificationSkeleton;

export const ACCOUNT_SETTINGS__FETCH_PREFERENCES = 'ACCOUNT_SETTINGS__FETCH_PREFERENCES';
export const ACCOUNT_SETTINGS__FETCH_PREFERENCES_FAILED = 'ACCOUNT_SETTINGS__FETCH_PREFERENCES_FAILED';
export const ACCOUNT_SETTINGS__FETCH_PREFERENCES_SUCCESS = 'ACCOUNT_SETTINGS__FETCH_PREFERENCES_SUCCESS';

export const ACCOUNT_SETTINGS__UPDATE_PHONE = 'ACCOUNT_SETTINGS__UPDATE_PHONE';
export const ACCOUNT_SETTINGS__UPDATE_PHONE_FAILED = 'ACCOUNT_SETTINGS__UPDATE_PHONE_FAILED';
export const ACCOUNT_SETTINGS__UPDATE_PHONE_SUCCESS = 'ACCOUNT_SETTINGS__UPDATE_PHONE_SUCCESS';
export const ACCOUNT_SETTINGS__RESET_UPDATE_STATUS = 'ACCOUNT_SETTINGS__RESET_UPDATE_STATUS';

export const ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS = 'ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS';
export const ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_FAILED = 'ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_FAILED';
export const ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_SUCCESS = 'ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_SUCCESS';

export const ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS = 'ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS';
export const ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_FAILED = 'ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_FAILED';
export const ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_SUCCESS = 'ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_SUCCESS';

export const ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM = 'ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM';
export const ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_FAILED = 'ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_FAILED';
export const ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_SUCCESS = 'ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_SUCCESS';

export const ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB = 'ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB';
export const ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_FAILED = 'ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_FAILED';
export const ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_SUCCESS = 'ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_SUCCESS';

export const ACCOUNT_SETTINGS__RESET_SUBSCRIPTION_FETCH_ATTEMPTS = 'ACCOUNT_SETTINGS__RESET_SUBSCRIPTION_FETCH_ATTEMPTS';
export const ACCOUNT_SETTINGS__RESET_PREFERENCE_FETCH_ATTEMPTS = 'ACCOUNT_SETTINGS__RESET_PREFERECNE_FETCH_ATTEMPTS';

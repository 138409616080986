import userPreferencesSlice, { defaultState as userPreferences } from 'account-settings/reducers/userPreferencesSlice';
import subscriptionSlice, { defaultState as subscriptions } from 'account-settings/reducers/subscriptionsSlice';

const defaultState = {
  subscriptions,
  userPreferences,
};

const accountSettingsReducer = (state = defaultState, action = {}) => (
  {
    ...state,
    subscriptions: subscriptionSlice(state.subscriptions, action),
    userPreferences: userPreferencesSlice(state.userPreferences, action),
  }
);

export default accountSettingsReducer;

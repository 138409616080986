import { Input } from 'antd';
import { IoCheckmarkOutline, IoCloseOutline, IoCreateOutline } from 'react-icons/io5';

import { formatPhoneNumber } from 'account-settings/helpers/formatHelpers';

const EditablePhoneStat = ({
  editPhone,
  editError,
  userPhone,
  setUserPhone,
  initialPhone,
  setEditPhone,
  handlePhoneNumberSumbit,
}) => (
  <div>
    <div className={`phone-number ${!editPhone ? 'phone-left' : ''}`}>
      {editPhone
        ? (
          <IoCloseOutline
            className="cancel-edit"
            onClick={() => {
              setEditPhone(false);
              setUserPhone(initialPhone);
            }}
          />
        )
        : (
          <IoCreateOutline
            className="edit-icon"
            onClick={() => setEditPhone(true)}
          />
        )}
      <Input
        maxLength={12}
        value={userPhone}
        disabled={!editPhone}
        onChange={(e) => setUserPhone(formatPhoneNumber(e.target.value))}
      />
      {editPhone && <IoCheckmarkOutline onClick={handlePhoneNumberSumbit} />}
    </div>
    {editError && <p className="error-state">{editError}</p>}
  </div>
);

export default EditablePhoneStat;

import {
  assetListStatusOptionValues,
  assetStatusTypes,
  okAssetStatusTypes,
  runningAssetStatusTypes,
} from 'shared/constants/assetConstants';
import { itemFilter } from 'shared/helpers/filterHelpers';

const { NOT_RUNNING_UNITS, RUNNING_UNITS } = assetListStatusOptionValues;

export const isValidAssetAndTextMatch = (asset, filterText, filterFields) => asset?.invalidated_at === null && itemFilter(filterText, filterFields, asset);
export const isReviewAsset = (asset) => !runningAssetStatusTypes.includes(asset.status);
export const isRunningAsset = (asset) => runningAssetStatusTypes.includes(asset.status);
export const getFilteredAssetList = (assetList, selectedStatusFilter, filterText, filterFields) => (
  selectedStatusFilter === NOT_RUNNING_UNITS && assetList.filter((asset) => isReviewAsset(asset) && isValidAssetAndTextMatch(asset, filterText, filterFields))
  || selectedStatusFilter === RUNNING_UNITS && assetList.filter((asset) => isRunningAsset(asset) && isValidAssetAndTextMatch(asset, filterText, filterFields))
  || assetList.filter((asset) => isValidAssetAndTextMatch(asset, filterText, filterFields))
);

export const getStatusClassAndText = (status) => (
  okAssetStatusTypes.includes(status) && ['ok', status]
  || [assetStatusTypes.FAULTED, assetStatusTypes.OFF].includes(status) && ['error', status]
  || ['warning', status]
);

export const getFilteredGroupList = (groupList, filterText) => (
  groupList.filter(({ label }) => label.toLowerCase().includes(filterText.toLowerCase()))
);

import React from 'react';

import './DisplayTable.scss';

const DisplayTable = ({
  columns,
  dataRows,
}) => (
  <section className="vapor-composition-table">
    <div className="table-header">
      {columns?.map((entry) => (
        <div
          key={entry?.key}
          className="table-header-cell"
        >
          <div className="table-header-cell-text">
            {entry?.title}
          </div>
        </div>
      ))}
    </div>
    <div className="table-body">
      {dataRows?.map((entry) => (
        <div
          key={entry.key}
          className="table-body-row"
        >
          <div className="table-body-row-cell">
            <p>{entry.label}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.percent}</p>
          </div>
          <div className="table-body-row-cell">
            <p>{entry.info}</p>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default DisplayTable;

import React from 'react';
import { Statistic } from 'antd';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { MONTH_DAY_YEAR } from 'single-asset/constants/singleAssetConstants';
import { getAdditionalTelemetryStyling } from 'shared/helpers/altitudeHelpers';
import { tagTitleToTooltipMap } from 'shared/constants/graphingAndStatisticsConstants';

import FluxTooltip from './FluxTooltip';
import FluxStatSkeleton from './FluxStatSkeleton';

import './FluxStat.scss';

const FluxStat = ({
  stat: {
    statsResult: [result] = [{}],
    props: {
      timestamp = false,
      title,
      precision,
      prefix = '',
      suffix = '',
      value: valuePropName = '',
    },
  },
}) => {
  const defaultDisplayedValue = '_';
  const cardValue = result?.value ?? result?.[valuePropName];
  const valueIsDate = timestamp || title?.toLowerCase().includes('date');
  const displayedValue = cardValue !== undefined && valueIsDate && extendedDayJs(cardValue).format(MONTH_DAY_YEAR)
  || cardValue
  || defaultDisplayedValue;

  const isLoading = result && Object.keys(result).length === 0;
  const tooltipData = tagTitleToTooltipMap?.[title];
  const statTitle = (tooltipData)
    ? <FluxTooltip {...tooltipData} />
    : title;

  return (
    <div className="flux-stat">
      {
        isLoading ? (
          <FluxStatSkeleton />
        ) : (
          <Statistic
            suffix={suffix}
            prefix={prefix}
            title={statTitle}
            precision={precision}
            value={displayedValue}
            className={getAdditionalTelemetryStyling(title, displayedValue)}
          />

        )
      }
    </div>
  );
};

export default FluxStat;

export const tagTitleToTooltipMap = {
  Off: {
    tooltip: 'Off',
    title: 'Off',
    description: 'Assets that are inactive because they have been turned off',
  },
  Faulted: {
    tooltip: 'Faulted',
    title: 'Faulted',
    description: 'Assets that are active, but do not currently have mechanical availability',
  },
  'Not Running': {
    tooltip: 'Not Running',
    title: 'Not Running',
    description: 'Assets that are inactive and do not have mechanical availability',
  },
  Running: {
    tooltip: 'Running',
    title: 'Running',
    description: 'All assets that are active and have mechanical availability',
  },
  'Total Assets': {
    tooltip: 'Total Assets',
    title: 'Total Assets',
    description: 'The total amount of assets commissioned by Flux across the globe',
  },
  RTP: {
    tooltip: 'RTP',
    title: 'Run Time Percentage',
    description: 'The actual availability a machine is able to actively run, updated every 24 hours',
  },
};

export const datePickerTypes = {
  RANGE: 'range',
  SINGLE: 'single',
};

export const datePickerErrorFields = {
  START_TIME: 'startTime',
  END_TIME: 'endTime',
};

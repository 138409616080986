import { Avatar } from 'antd';

import fluxLogo from 'assets/images/FluxLogo.png';
import flogistixLogo from 'assets/images/FlogistixLogo.png';

import MobileHeader from './MobileHeader';

import './Header.scss';

const Header = ({
  user,
  navLinks,
  activeLink,
  openProfileModal,
}) => {
  const mobileHeader = document.querySelector('.mobile-header');

  return (
    <div className="app-header">
      <div className="desktop-header">
        <a className="flux-logo" href="/">
          <img src={fluxLogo} />
        </a>
        <div className="manage-profile">
          <button onClick={() => openProfileModal()}>
            <img className="flogistix-logo" src={flogistixLogo} />
            <Avatar size={32} src={user?.picture} />
          </button>
        </div>
      </div>
      <MobileHeader
        navLinks={navLinks}
        activeLink={activeLink}
        mobileVisibility={mobileHeader && window.getComputedStyle(mobileHeader).display}
      />
    </div>
  );
};

export default Header;

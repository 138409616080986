import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button } from '@flogistix/flo-ui';

import {
  MAX_FETCH_ATTEMPTS,
  MAX_FETCH_ERROR_MESSAGE,
  PREFERENCES_ERROR_ORIGIN,
  SUBSCRIPTIONS_ERROR_ORIGIN,
} from 'shared/constants/errorHandlingConstants';

import { selectDashboard } from 'dashboard/reducers';

import { selectSubscriptions } from 'account-settings/reducers/subscriptionsSlice';
import { selectUserPreferences } from 'account-settings/reducers/userPreferencesSlice';
import { NOTIFICATION_SECTIONS } from 'account-settings/constants/accountSettingsConstants';
import NotificationSkeleton from 'account-settings/components/notifications/NotificationSkeleton';
import AccountInformation from 'account-settings/components/account-information/AccountInformation';
import NotificationManagement from 'account-settings/components/notifications/NotificationManagement';
import AccountInformationSkeleton from 'account-settings/components/account-information/AccountInformationSkeleton';

import packageJson from '../../../package.json';

import './AccountSettingsPage.scss';

const { version } = packageJson;

const AccountSettingsPage = ({
  user,
  updateDndInfo,
  addErrorToast,
  toggleDemoMode,
  getPreferences,
  updateUserPhone,
  getSubscriptions,
  updateUserSubscriptions,
  resetPreferenceAttempts,
  updateReceivingPlatform,
  resetSubscriptionAttempts,
}) => {
  const {
    preferences,
    isPending: preferencesPending,
    errorResponse,
    updatePending,
    updateSuccessful,
    fetchAttempts: preferenceAttempts,
  } = useSelector(selectUserPreferences);
  const {
    subscriptions,
    isPending: subscriptionsPending,
    fetchedSuccessfully,
    fetchAttempts: subscriptionAttempts,
  } = useSelector(selectSubscriptions);
  const demoMode = useSelector((state) => state.resources.demo);
  const { assetList: { completeAssetList: assets } } = useSelector(selectDashboard);

  const defaultDndValues = {
    dnd_start: preferences?.dnd_start,
    dnd_stop: preferences?.dnd_stop,
  };

  const initialDnd = {
    dnd_start: preferences?.dnd ? defaultDndValues.dnd_start : null,
    dnd_stop: preferences?.dnd ? defaultDndValues.dnd_stop : null,
  };

  const [modifiedSubscriptions, setModifiedSubscriptions] = useState(subscriptions);
  const [activeSection, setActiveSection] = useState(NOTIFICATION_SECTIONS.subscribed);
  const [dndValues, setDndValues] = useState(initialDnd);

  const dndValuesAreNull = dndValues.dnd_start === null && dndValues.dnd_stop === null;
  const dndValuesNotNull = dndValues.dnd_start !== null && dndValues.dnd_stop !== null;

  const handleSaveChanges = () => {
    if (subscriptions !== modifiedSubscriptions) updateUserSubscriptions({ subscriptions: modifiedSubscriptions });
    if (JSON.stringify(defaultDndValues) !== JSON.stringify(dndValues) && dndValuesAreNull) {
      updateDndInfo({
        ...preferences,
        dnd: false,
        dnd_start: null,
        dnd_stop: null,
      });
    } else if (JSON.stringify(defaultDndValues) !== JSON.stringify(dndValues) && dndValuesNotNull) {
      updateDndInfo({
        ...preferences,
        dnd: true,
        dnd_start: dndValues.dnd_start,
        dnd_stop: dndValues.dnd_stop,
      });
    }
  };

  useEffect(() => {
    !preferences && resetPreferenceAttempts();
    !Object.entries(subscriptions).length && resetSubscriptionAttempts();
  }, []);

  useEffect(() => {
    !preferences && !preferencesPending && preferenceAttempts < MAX_FETCH_ATTEMPTS && getPreferences();
  }, [preferences, preferencesPending, preferenceAttempts]);

  useEffect(() => {
    !fetchedSuccessfully && subscriptionAttempts < MAX_FETCH_ATTEMPTS && !subscriptionsPending && getSubscriptions();
    Object.entries(subscriptions).length && setModifiedSubscriptions(subscriptions);
  }, [subscriptions, subscriptionsPending, subscriptionAttempts]);

  useEffect(() => {
    preferenceAttempts >= MAX_FETCH_ATTEMPTS
      && addErrorToast(`${PREFERENCES_ERROR_ORIGIN}: ${MAX_FETCH_ERROR_MESSAGE}`);
  }, [preferenceAttempts]);

  useEffect(() => {
    subscriptionAttempts >= MAX_FETCH_ATTEMPTS
      && addErrorToast(`${SUBSCRIPTIONS_ERROR_ORIGIN}: ${MAX_FETCH_ERROR_MESSAGE}`);
  }, [subscriptionAttempts]);

  return (
    <div className="account-settings-page">
      <div className="page-heading">
        <div className="left-heading">
          <h1>My Account</h1>
          {((subscriptions !== modifiedSubscriptions)
              || (preferences?.dnd && dndValuesAreNull)
              || (preferences?.dnd && (dndValuesNotNull && (JSON.stringify(defaultDndValues) !== JSON.stringify(dndValues))))
              || (!preferences?.dnd && dndValues.dnd_start !== null && dndValues.dnd_stop !== null))
                && (
                  <div className="confirmation-buttons">
                    <Button
                      variation="red"
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        setDndValues(initialDnd);
                        setModifiedSubscriptions(subscriptions);
                      }}
                    >
                      Revert changes
                    </Button>
                    <Button
                      variation="blue"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </Button>
                  </div>
                )}
        </div>
        <p className="software-version">
          Software Version: {version}
        </p>
      </div>
      {
        preferences
          ? (
            <AccountInformation
              user={user}
              demoMode={demoMode}
              preferences={preferences}
              updatePending={updatePending}
              errorResponse={errorResponse}
              toggleDemoMode={toggleDemoMode}
              updateUserPhone={updateUserPhone}
              updateSuccessful={updateSuccessful}
            />
          )
          : <AccountInformationSkeleton active={preferenceAttempts < MAX_FETCH_ATTEMPTS} />
      }
      <div className="notifications-content">
        {assets.length > 0 && preferences ? (
          <div>
            <div className="section-tabs">
              <button
                className={activeSection === NOTIFICATION_SECTIONS.subscribed ? 'active' : ''}
                onClick={() => setActiveSection(NOTIFICATION_SECTIONS.subscribed)}
              >
                My Notifications
              </button>
              <button
                className={activeSection === NOTIFICATION_SECTIONS.addNew ? 'active' : ''}
                onClick={() => setActiveSection(NOTIFICATION_SECTIONS.addNew)}
              >
                Add New
              </button>
            </div>
            <NotificationManagement
              assets={assets}
              dndValues={dndValues}
              preferences={preferences}
              setDndValues={setDndValues}
              activeSection={activeSection}
              userSubscriptions={subscriptions}
              modifiedSubscriptions={modifiedSubscriptions}
              updateReceivingPlatform={updateReceivingPlatform}
              setModifiedSubscriptions={setModifiedSubscriptions}
            />
          </div>
        ) : (
          <NotificationSkeleton active={subscriptionAttempts < MAX_FETCH_ATTEMPTS} />
        )}
      </div>
    </div>
  );
};

export default AccountSettingsPage;

import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

export const parseBreakpointChildren = ({ lg } = {}) => lg?.at(0)?.children ?? [];

export const filterAssetsByPathParams = (assetList, pathParams) => {
  const pathParamsArray = Object.entries(pathParams).filter(([, value]) => !!value);
  return assetList.filter((asset) => pathParamsArray.every(([key, value]) => asset?.[key] === value));
};

const ascendingSorter = (item1, item2, propertyName) => `${item1?.[propertyName]}`.localeCompare(`${item2?.[propertyName]}`, 'en', { ignorePunctuation: true, numeric: true });
const descendingSorter = (item1, item2, propertyName) => -1 * ascendingSorter(item1, item2, propertyName);

export const createTableSorter = (propertyName, ascending = true) => (item1, item2) => (ascending
  ? ascendingSorter(item1, item2, propertyName)
  : descendingSorter(item1, item2, propertyName));

export function tupleizeArray(listToTupelize) {
  const copyOfListToTupelize = [...listToTupelize];
  let tuplelList = [];

  while (copyOfListToTupelize.length > 0) {
    tuplelList = [...tuplelList, copyOfListToTupelize.splice(0, 2)];
  }

  return tuplelList;
}

export const setMissingElementValuesToDefault = (element, defaultValue = MISSING_FIELD_VALUE_PLACEHOLDER) => Object.entries(element).reduce((acc, [key, value]) => ({
  ...acc,
  [key]: value ?? defaultValue,
}), {});

export const dashboardStatConfig = [
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      exclusions: [
        137,
      ],
      tags: {
        rtp: {
          agg_func: 'avg',
          key: 'val',
          tag_id: 915,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: 2,
      prefix: '',
      rounded: false,
      suffix: '%',
      textcolor: '',
      title: 'RTP',
      value: 'rtp',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: 98,
          less_than: 98,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      tags: {
        total: {
          agg_func: 'count',
          key: 'str_val',
          tag_id: 1692,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: false,
      prefix: '',
      rounded: false,
      suffix: '',
      textcolor: '',
      title: 'Total Assets',
      value: 'total',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: false,
          less_than: false,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      exclusions: [
        137,
        138,
      ],
      tags: {
        status: {
          agg_func: 'count',
          key: 'enum_label',
          notin: [
            'Faulted',
            'Off',
          ],
          tag_id: 562,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: false,
      prefix: '',
      rounded: false,
      suffix: '',
      textcolor: '',
      title: 'Running',
      value: 'status',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: false,
          less_than: false,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      tags: {
        status: {
          agg_func: 'count',
          key: 'enum_label',
          notin: [
            'Running',
            'Ready',
            'Waiting to Auto Start',
            'Alarming',
            'Auto',
            'Hand',
          ],
          tag_id: 562,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: false,
      prefix: '',
      rounded: false,
      suffix: '',
      textcolor: '',
      title: 'Not Running',
      value: 'status',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: false,
          less_than: false,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      tags: {
        status: {
          agg_func: 'count',
          isin: [
            'Faulted',
            'Auto Starts Exceeded',
          ],
          key: 'enum_label',
          tag_id: 562,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: false,
      prefix: '',
      rounded: false,
      suffix: '',
      textcolor: '',
      title: 'Faulted',
      value: 'status',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: false,
          less_than: false,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
  {
    config: {
      agg: true,
      duration: {
        hours: 48,
      },
      endpoint: '/v1/axil/config/tag_values/by_tag_id',
      tags: {
        status: {
          agg_func: 'count',
          isin: [
            'Off',
          ],
          key: 'enum_label',
          tag_id: 562,
        },
      },
    },
    props: {
      align: 'center',
      bordered: true,
      cardBodyStyles: {},
      justify: 'center',
      precision: false,
      prefix: '',
      rounded: false,
      suffix: '',
      textcolor: '',
      title: 'Off',
      value: 'status',
      valueStyles: {
        conditions: {
          equals: [],
          equals_color: '',
          greater: false,
          less_than: false,
          not_equals: [],
          not_equals_color: '',
        },
        styles: {},
      },
    },
  },
];

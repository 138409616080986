import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@flogistix/flo-ui';

import { getAssetLinkUrl } from 'shared/helpers/altitudeHelpers';
import FluxAutoComplete from 'shared/components/FluxAutoComplete';
import { selectDashboard } from 'dashboard/reducers';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import { assetFieldNames } from 'shared/constants/altitudeConstants';
import { searchItemFieldsForMatch } from 'shared/helpers/filterHelpers';

import {
  excludedAutocompleteFields,
  MINIMUM_AUTOCOMPLETE_FILTER_LENGTH,
} from 'dashboard/constants/dashboardConstants';

import './AssetListFormInput.scss';

const AssetListFormInput = ({
  value,
  onChange,
  onSelect,
  placeholder,
  assetListFilters,
  ...otherProps
}) => {
  const { assetList: { completeAssetList } } = useSelector(selectDashboard);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [matchingOptions, setMatchingOptions] = useState([]);

  const pageLocation = pathname.split('/').at(1);

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const generateAssetLinkByFieldName = (asset, fieldName) => (
    fieldName === assetFieldNames.ASSET_NAME && getAssetLinkUrl(asset)
      || fieldName === 'org_name' && `/${pageLocation}/${asset?.org_id}`
      || `/${pageLocation}/${asset?.org_id}/${asset?.site_id}`
  );

  const createDropdownItems = (matchedItems) => {
    const addedItems = new Set();
    // eslint-disable-next-line consistent-return, array-callback-return
    return matchedItems.map((item) => {
      const {
        org_id, site_id, asset_name,
      } = item;

      const [fieldName, fieldValue] = searchItemFieldsForMatch(item, value);
      const displayText = fieldName === assetFieldNames.ASSET_NAME ? `Asset ${fieldValue} (Page)` : fieldValue;
      const link = org_id ? generateAssetLinkByFieldName(item, fieldName) : JSON.stringify({
        tagId: item.value,
        tagName: item.label,
      });
      const itemKey = org_id ? `${org_id}-${site_id}-${asset_name}` : `${item.label}-${item.value}`;
      if (!addedItems.has(fieldValue) && !excludedAutocompleteFields.includes(fieldName) && displayText) {
        addedItems.add(fieldValue);
        return ({
          key: itemKey,
          value: link,
          label: (
            <div className="asset-list-form-input__option">
              {displayText}
            </div>
          ),
        });
      }
    }).filter((item) => item);
  };

  const removeFilter = (e, { type }) => {
    e.preventDefault();
    type === assetFieldNames.SITE_NAME ? navigate(pathname.split('/').slice(0, 3).join('/')) : navigate(`/${pageLocation}`);
  };

  const generateFilterTags = () => (
    <div>
      {
        assetListFilters.map((filter) => (
          <Tag key={filter.label} closable onClose={(e) => removeFilter(e, filter)}>
            {filter.label}
          </Tag>
        ))
      }
    </div>
  );

  useEffect(() => {
    value.length <= MINIMUM_AUTOCOMPLETE_FILTER_LENGTH
      ? setMatchingOptions([])
      : setMatchingOptions(
        createDropdownItems(getFilteredAssetList(completeAssetList, null, '', '')),
      );
  }, [completeAssetList, value]);

  return (
    <FluxAutoComplete
      value={value}
      {...otherProps}
      onSelect={onSelect}
      onChange={handleChange}
      options={matchingOptions}
      placeholder={placeholder}
      prefix={generateFilterTags()}
      className={`asset-list-form-input ${assetListFilters.length ? 'blue-icon' : ''}`}
      suffix={assetListFilters.length > 0 && <Button title="RESET" onClick={() => navigate(`/${pageLocation}`)} />}
    />
  );
};

export default AssetListFormInput;

import { configureStore } from '@reduxjs/toolkit';

import dashboard from 'dashboard/reducers';
import singleAsset from 'single-asset/reducers';
import accountSettings from 'account-settings/reducers';
import session from 'shared/reducers/sessionReducer';
import notifications from 'shared/reducers/notificationsSlice';
import permissions from 'shared/reducers/permissionsSlice';
import preferences from 'shared/reducers/preferencesSlice';
import organizations from 'shared/reducers/organizationsSlice';

import { reducer as resources } from './resources';

const store = configureStore({
  reducer: {
    session,
    dashboard,
    resources,
    permissions,
    singleAsset,
    preferences,
    notifications,
    organizations,
    accountSettings,
  },
});

export default store;

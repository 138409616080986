import { Select } from 'antd';
import { useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline, IoCloseOutline } from 'react-icons/io5';

import './FluxDropdown.scss';

const FluxDropdown = ({
  icon,
  label,
  value,
  options,
  variant,
  placeholder,
  handleChange,
  popupClasses,
  disabled = false,
  clearable = false,
  erroredClass = '',
  handleClear = () => {},
  popupPlacement = 'topLeft',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelection = (val, option) => {
    handleChange(val, option);
    setIsOpen(false);
  };

  const disabledClass = disabled ? 'disabled' : '';

  return (
    <div className="dropdown-container">
      <div
        className={
          `dropdown-trigger ${isOpen ? 'showing-dropdown' : ''}${variant ?? ''} ${disabledClass} ${!value ? erroredClass : ''}`
        }
        role="button"
        tabIndex={0}
        onClick={!clearable ? () => setIsOpen(!isOpen) : () => {}}
      >
        <div
          className="dropdown-content"
          role="button"
          tabIndex={0}
          onClick={clearable ? () => setIsOpen(!isOpen) : () => {}}
        >
          {icon}
          <div>
            {label && <p className="btn-label">{label}</p>}
            <p className="btn-value">{value ?? placeholder}</p>
          </div>
        </div>
        <div className="chevron">
          {
            !clearable ? (
              isOpen
                ? <IoChevronDownOutline size={28} />
                : <IoChevronUpOutline size={28} />
            ) : (value ? <IoCloseOutline size={28} onClick={handleClear} /> : null)
          }
        </div>
      </div>
      {
        isOpen && (
          <Select
            disabled={disabled}
            popupClassName={`external-dropdown ${popupClasses}`}
            open={isOpen}
            options={options}
            onChange={handleSelection}
            placement={popupPlacement}
          />
        )
      }
    </div>
  );
};

export default FluxDropdown;

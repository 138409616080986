import { assetDrivers } from 'single-asset/constants/singleAssetConstants';

const lastPMDate = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '33',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Last PM Date',
    timestamp: false,
  },
};

const nextPMDate = {
  config: {
    agg: true,
    endpoint: '/v1/axil/config/tag_values/by_tag_id',
    tags: {
      value: {
        agg_func: 'last_value',
        key: 'str_val',
        tag_id: '32',
      },
    },
  },
  props: {
    precision: false,
    prefix: '',
    suffix: '',
    title: 'Next PM Date',
    timestamp: false,
  },
};

export default [lastPMDate, nextPMDate];

const getEngineMotorSpeedTag = (driverType) => {
  if (driverType === assetDrivers.ENGINE || driverType === assetDrivers.GED) {
    return 13;
  }
  return 14;
};

const getFaultConfig = (modelType) => {
  if (modelType.includes('VRX7')) return 860;
  return 881;
};

export const getStatsConfiguration = (driverType = assetDrivers.ENGINE, modelType = 'FX17') => ([
  {
    config: {
      agg: true,
      duration: { hours: 48 },
      tags: {
        rtp: {
          agg_func: 'avg',
          key: 'val',
          tag_id: 915,
        },
      },
    },
    props: {
      title: 'RTP',
      precision: 2,
      prefix: '',
      suffix: '%',
      value: 'rtp',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        status: {
          agg_func: 'last_value',
          key: 'enum_label',
          tag_id: 562,
        },
      },
    },
    props: {
      precision: false,
      title: 'Current Status',
      value: 'status',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        fault: {
          agg_func: 'last_value',
          key: 'enum_label',
          tag_id: getFaultConfig(modelType),
        },
      },
    },
    props: {
      precision: false,
      title: 'Fault',
      value: 'fault',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        application_type: {
          agg_func: 'last_value',
          key: 'str_val',
          tag_id: 34,
        },
      },
    },
    props: {
      precision: false,
      title: 'Application Type',
      value: 'application_type',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        register_tag__discharge_temperature: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: 869,
        },
      },
    },
    props: {
      precision: false,
      title: 'Discharge Temperature',
      value: 'value',
      suffix: ' °F',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        suction_pressure: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: 564,
        },
      },
    },
    props: {
      precision: false,
      title: 'Suction Pressure',
      value: 'value',
      suffix: ' psi',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        discharge_pressure: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: 565,
        },
      },
    },
    props: {
      precision: false,
      title: 'Discharge Pressure',
      value: 'value',
      suffix: ' psi',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        engine_hours: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: 23,
        },
      },
    },
    props: {
      precision: false,
      title: `${driverType} Hours`,
      value: 'engine_hours',
      suffix: ' hrs',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        engine_speed: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: getEngineMotorSpeedTag(driverType),
        },
      },
    },
    props: {
      precision: false,
      title: `${driverType} Speed`,
      value: 'value',
      suffix: ' rpm',
    },
  },
  {
    config: {
      agg: true,
      tags: {
        _flogistix__elevation_feet: {
          agg_func: 'last_value',
          key: 'val',
          tag_id: 1688,
        },
      },
    },
    props: {
      precision: false,
      title: 'Elevation',
      value: 'value',
      suffix: ' ft',
    },
  },
]);

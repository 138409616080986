import { fetchTagById } from 'shared/repositories/axilTagRepository';
import * as dashboardStatsActionTypes from 'dashboard/actions/dashboardStatsActionTypes';
import { asyncBatchActionCreator } from 'shared/helpers/actionHelpers';
import { transformDashboardStatsRequest } from 'dashboard/transformers/dashboardTransformers';
import { transformFluxStatsResponse } from 'shared/transformers/fluxTransformers';

export const fetchDashboardStats = asyncBatchActionCreator({
  repositoryAPI: fetchTagById,
  PENDING_ACTION_TYPE: dashboardStatsActionTypes.DASHBOARD_STATS__FETCH_STATS,
  SUCCESS_ACTION_TYPE: dashboardStatsActionTypes.DASHBOARD_STATS__FETCH_STATS_SUCCEEDED,
  FAILED_ACTION_TYPE: dashboardStatsActionTypes.DASHBOARD_STATS__FETCH_STATS_FAILED,
  requestTransformer: transformDashboardStatsRequest,
  responseTransformer: transformFluxStatsResponse,
});

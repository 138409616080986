import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

import FluxTooltip from 'shared/components/FluxTooltip';

import './ResultsCard.scss';

const ResultsCard = ({
  cardTitle,
  cardResult,
  tooltipText,
  tooltipTitle,
}) => (
  <section className="results-card">
    <div className="results-header">
      <section className="results-total-info">
        <span className="results-number">{cardResult}</span>
        <div className="results-description">
          <p>{cardTitle}</p>
          <FluxTooltip
            title={tooltipTitle}
            showInfoCircle={false}
            description={tooltipText}
            tooltip={(
              <IoInformationCircleOutline />
            )}
          />
        </div>
      </section>
    </div>
  </section>
);

export default ResultsCard;

import React, { useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectSession } from 'shared/reducers/sessionReducer';
import { addNotification } from 'shared/actions/notificationsActions';

import {
  resetAssetStats,
  fetchTelemetryStats,
} from 'single-asset/actions/telemetryStatsActions';
import {
  fetchTelemetryConfig,
  resetTelemetryConfig,
} from 'single-asset/actions/telemetryConfigActions';
import {
  resetRefetchCharts,
  editTelemetryCharts,
  resetModifyChartsError,
  resetTelemetryChartSeries,
  fetchTelemetryChartSeries,
} from 'single-asset/actions/telemetryChartsActions';
import TelemetryPage from 'single-asset/pages/TelemetryPage';
import { selectAsset } from 'single-asset/reducers/assetReducer';
import { getDefaultDateRange } from 'single-asset/helpers/singleAssetHelpers';
import { fetchChartMetrics } from 'single-asset/actions/telemetryChartMetricsActions';
import { DASHBOARD_ID, MODIFY_CHART_ERROR } from 'single-asset/constants/telemetryConstants';
import { updateTelemetryChartRange } from 'single-asset/actions/telemetryChartsRangeActions';
import telemetryStatsReducer, { defaultState as initialStatsState } from 'single-asset/reducers/telemetryStatsReducer';
import telemetryConfigReducer, { defaultState as initialConfigState } from 'single-asset/reducers/telemetryConfigReducer';
import telemetryChartsReducer, { defaultState as initialChartsState } from 'single-asset/reducers/telemetryChartsReducer';
import telemetryChartsRangeReducer, { defaultState as initialRangeState } from 'single-asset/reducers/telemetryChartsRangeReducer';
import telemetryChartMetricsReducer, { defaultState as initialMetricsState } from 'single-asset/reducers/telemetryChartMetricsReducer';

import './TelemetryPage.scss';

const TelemetryPageContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const currentSession = useSelector(selectSession);

  const [statsState, statsDispatch] = useReducer(telemetryStatsReducer, initialStatsState);
  const [configState, configDispatch] = useReducer(telemetryConfigReducer, initialConfigState);
  const [chartsState, chartsDispatch] = useReducer(telemetryChartsReducer, initialChartsState);
  const [rangeState, rangeDispatch] = useReducer(telemetryChartsRangeReducer, initialRangeState);
  const [metricsState, metricsDispatch] = useReducer(telemetryChartMetricsReducer, initialMetricsState);

  const resetStatsData = () => statsDispatch(resetAssetStats());
  const resetChartConfig = () => configDispatch(resetTelemetryConfig());
  const resetChartData = () => chartsDispatch(resetTelemetryChartSeries());
  const modifyChartsRange = (newChartsRange) => rangeDispatch(updateTelemetryChartRange(newChartsRange));
  const getStats = (statsConfig, asset) => fetchTelemetryStats(statsConfig, asset, statsDispatch, currentSession);
  const modifyCharts = (chartModificationBody) => editTelemetryCharts(chartModificationBody, chartsDispatch, currentSession);
  const getChartMetrics = (metricParams, asset) => fetchChartMetrics({ metricParams, ...asset }, metricsDispatch, currentSession);
  const getChartSeries = (configList, dates, fromCharts = false, asset) => (
    fetchTelemetryChartSeries(configList, { dates, fromCharts, ...asset }, chartsDispatch, currentSession));
  const getConfig = (asset) => {
    fetchTelemetryConfig({ dashboard_id: DASHBOARD_ID, ...asset }, configDispatch, currentSession);
    chartsDispatch(resetRefetchCharts());
  };

  useEffect(() => {
    if (chartsState?.modifyError) {
      dispatch(addNotification({ type: 'error', message: MODIFY_CHART_ERROR }));
      chartsDispatch(resetModifyChartsError());
    }
  }, [chartsState?.modifyError]);

  return (
    <TelemetryPage
      stats={statsState}
      location={location}
      getStats={getStats}
      config={configState}
      charts={chartsState}
      getConfig={getConfig}
      chartMetrics={metricsState}
      modifyCharts={modifyCharts}
      getChartSeries={getChartSeries}
      resetChartData={resetChartData}
      resetStatsData={resetStatsData}
      chartsDispatch={chartsDispatch}
      asset={useSelector(selectAsset)}
      chartRangeState={rangeState}
      getChartMetrics={getChartMetrics}
      resetChartConfig={resetChartConfig}
      modifyChartsRange={modifyChartsRange}
      defaultDates={getDefaultDateRange()}
    />
  );
};

export default TelemetryPageContainer;

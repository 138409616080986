import { Modal } from 'antd';

import { Button } from '@flogistix/flo-ui';

import './ConfirmTemplateModal.scss';

const ConfirmTemplateModal = ({
  isOpen,
  onSubmit,
  closeModal,
  modalTitle = 'Title',
  confirmButtonText = 'Yes, Apply',
  cancelButtonText = "No, don't Apply",
}) => (
  <Modal
    centered
    footer={[]}
    open={isOpen}
    title={modalTitle}
    onCancel={closeModal}
    className="confirm-modal"
    wrapClassName="confirm-modal-dialog"
  >
    <div className="button-group">
      <Button
        variation="gray"
        fixedWidth="49.5%"
        onClick={closeModal}
      >
        {cancelButtonText}
      </Button>
      <Button
        fixedWidth="49.5%"
        onClick={onSubmit}
      >
        {confirmButtonText}
      </Button>
    </div>
  </Modal>
);

export default ConfirmTemplateModal;

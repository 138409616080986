import * as wellProductionActionTypes from 'single-asset/actions/wellProductionActionTypes';
import { createChartSeriesReducer } from 'single-asset/helpers/singleAssetHelpers';
import { defaultSeriesState as defaultState } from 'single-asset/constants/singleAssetConstants';

export { defaultState };

const wellProductionReducer = createChartSeriesReducer({
  PENDING_ACTION_TYPE: wellProductionActionTypes.WELL_PRODUCTION__FETCH_SERIES_LIST,
  SUCCESS_ACTION_TYPE: wellProductionActionTypes.WELL_PRODUCTION__FETCH_SERIES_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: wellProductionActionTypes.WELL_PRODUCTION__FETCH_SERIES_LIST_FAILED,
  defaultState,
});

export default wellProductionReducer;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

export const getAssetList = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  body,
  endpoint: '/v1/axil/flux/views/asset_list',
});

export const checkDigitalTwinExistence = (assetName, setDigitalTwinExistence) => {
  const ursaleoUrl = 'https://ursaleo.axil.ai';
  fetch(`${ursaleoUrl}/devices/${assetName}`)
    .then((res) => res.json())
    .then((data) => setDigitalTwinExistence(data))
    .catch(() => setDigitalTwinExistence(false));
};

import React from 'react';

import EmptyElement from 'shared/components/EmptyElement';

import AssetGoogleMap from './AssetGoogleMap';

import './LocationAndImageContainer.scss';

const LocationAndImageContainer = ({ lat, lng, image }) => (
  <div>
    {lat || lng || image
      ? (
        <section className="image-and-location-container">
          {typeof lat === 'number'
            && typeof lng === 'number'
              && (
              <div className={
                image
                  ? 'location-section'
                  : 'location-section-large'
                }
              >
                <h1>Location</h1>
                <AssetGoogleMap lat={lat} lng={lng} />
              </div>
              )}
          {image && (
            <div className={
              lat && lng
                ? 'image-section'
                : 'image-section-large'
              }
            >
              <h1>Image</h1>
              <div className="image-container">
                <img
                  src={image}
                  width="100%"
                  height="100%"
                  className="image"
                  alt="loading data..."
                />
              </div>
            </div>
          )}
        </section>
      )
      : <EmptyElement />}
  </div>
);
export default LocationAndImageContainer;

export const GLU_ASSET_TYPE_ID = 3;
export const GLX_ASSET_TYPE_ID = 4;
export const assetFieldNames = {
  ASSET_NAME: 'asset_name',
  MODEL: 'model',
  ORG_NAME: 'org_name',
  SITE_NAME: 'site_name',
  STATUS: 'status',
  DEVICE_ID: 'device_id',
  LAST_SYNCED: 'last_synced',
  RUNTIME: 'runtime',
};
export const MINIMUM_RUN_TIME_PERCENTAGE = 98;
export const MISSING_FIELD_VALUE_PLACEHOLDER = '---';
export const TELEMETRY_STATS = {
  rtp: 'RTP',
  status: 'Current Status',
};

export const ASSET_TYPE_NAMES = {
  GED: 'Engine Driven Screw',
  ELEC: 'Electric Motor Driven Screw',
  GLU: 'Engine Driven Reciprocating Compressor',
  GLX: 'Electric Motor Reciprocating Compressor',
};

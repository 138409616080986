import { Table } from 'antd';
import { useSelector } from 'react-redux';
import React, { useEffect, useReducer, useState } from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import { isDataEqual, filterArray } from 'shared/helpers/filterHelpers';
import { showWorkOrderInfo } from 'shared/repositories/workOrderRepository';
import { loadingKeyedTableData } from 'shared/constants/tableDataConstants';

import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';

import { selectAsset } from 'single-asset/reducers/assetReducer';
import { rowClassName } from 'single-asset/helpers/workOrderHelpers';
import { sharedRequestBody } from 'single-asset/helpers/assetHelpers';
import { fetchWorkOrders } from 'single-asset/actions/workOrdersActions';
import workOrdersReducer, { defaultState as defaultWorkOrdersState } from 'single-asset/reducers/workOrdersReducer';
import WorkOrdersSearchForm from 'single-asset/components/work-orders/WorkOrdersSearchForm';
import { WORK_ORDER_TABLE_LOADING_COLUMNS } from 'single-asset/components/work-orders/WorkOrderLoadingColumn';

import './WorkOrdersPage.scss';

const WorkOrdersPage = () => {
  const asset = useSelector(selectAsset);
  const currentSession = useSelector(selectSession);

  const [workOrdersState, workOrdersDispatch] = useReducer(workOrdersReducer, defaultWorkOrdersState);

  const [searchString, setSearchString] = useState('');
  const [finalizedDates, setFinalizedDates] = useState(null);
  const [currentWorkOrders, setCurrentWorkOrders] = useState([]);
  const [defaultWorkOrders, setDefaultWorkOrders] = useState([]);
  const [formattedWorkOrders, setFormattedWorkOrders] = useState([]);

  const showWorkOrderRedirect = (id, hash, showJSA) => {
    showWorkOrderInfo(id, hash, showJSA);
  };

  const handleRowClick = (record) => {
    const { id, hash } = record;
    return {
      onClick: () => showWorkOrderRedirect(id, hash, false),
    };
  };

  const handleDateChange = () => {
    const formattedEnd = extendedDayJs(finalizedDates[1]).format('MM/DD/YYYY');
    const formattedStart = extendedDayJs(finalizedDates[0]).format('MM/DD/YYYY');

    const filteredWorkOrders = defaultWorkOrders?.filter((entry) => {
      const date = entry?.start_time;
      const formattedDate = extendedDayJs(date).format('MM/DD/YYYY');

      return formattedDate === formattedEnd
        || formattedDate === formattedStart
        || extendedDayJs(formattedDate).isBetween(formattedStart, formattedEnd);
    });

    setFormattedWorkOrders(filteredWorkOrders);
  };

  const handleClearDates = () => {
    setFinalizedDates(null);
    setFormattedWorkOrders(defaultWorkOrders);
  };

  const handleFiltering = (stringToFilter) => {
    const filteredWorkOrders = filterArray(defaultWorkOrders, stringToFilter?.toLowerCase());
    setFormattedWorkOrders(filteredWorkOrders);
  };

  const workOrderTableColumns = [
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('start_time'),
      },
      render: (value) => (
        extendedDayJs(value).format('hh:mm MM/DD/YYYY')
      ),
    },
    {
      title: 'Work Order Id',
      dataIndex: 'flogistix_id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('flogistix_id'),
      },
    },
    {
      title: 'Mechanic',
      dataIndex: 'mechanic_name',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('mechanic_name'),
      },
    },
    {
      title: 'Type',
      dataIndex: 'call_type',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('call_type'),
      },
      render: (value) => (
        <span className="type-column-value">{value}</span>
      ),
    },
    {
      title: 'Job Safety Analysis',
      render: ({ id, hash }) => (
        <span
          className="jsa-button"
          onClick={(e) => {
            e.stopPropagation();
            showWorkOrderRedirect(id, hash, true);
          }}
        >
          View JSA
        </span>
      ),
    },
  ];

  const displayedData = workOrdersState.isPending
    ? loadingKeyedTableData
    : formattedWorkOrders;

  const displayedColumns = workOrdersState.isPending
    ? WORK_ORDER_TABLE_LOADING_COLUMNS
    : workOrderTableColumns;

  const getWorkOrdersRequestBody = {
    key: asset?.asset_id,
    ...sharedRequestBody(asset),
    unit_number: asset?.asset_name,
  };

  useEffect(() => {
    const updateWorkOrders = asset && !currentWorkOrders.length
      || !isDataEqual(currentWorkOrders, workOrdersState?.workOrders);

    if (updateWorkOrders) {
      fetchWorkOrders(getWorkOrdersRequestBody, workOrdersDispatch, currentSession);
    }
  }, [asset]);

  useEffect(() => {
    if (!searchString) {
      setFormattedWorkOrders(defaultWorkOrders);
    }

    handleFiltering(searchString);
  }, [searchString]);

  useEffect(() => {
    if (finalizedDates?.length) {
      handleDateChange();
    }
  }, [finalizedDates]);

  useEffect(() => {
    if (workOrdersState?.workOrders?.length) {
      const currentFormattedWorkOrders = workOrdersState
        ?.workOrders
        ?.map((entry) => (
          {
            ...entry,
            key: entry?.id,
          }
        ));

      setDefaultWorkOrders(currentFormattedWorkOrders);
      setFormattedWorkOrders(currentFormattedWorkOrders);
      setCurrentWorkOrders(workOrdersState?.workOrders);
    }
  }, [workOrdersState?.isPending]);

  return (
    <section className="work-orders-page-container">
      <div className="work-orders-page">
        <WorkOrdersSearchForm
          finalizedDates={finalizedDates}
          handleFiltering={setSearchString}
          handleClearDates={handleClearDates}
          setFinalizedDates={setFinalizedDates}
          formattedWorkOrders={formattedWorkOrders}
        />
        <Table
          pagination={false}
          scroll={{ y: 500 }}
          onRow={handleRowClick}
          columns={displayedColumns}
          dataSource={displayedData}
          rowClassName={rowClassName}
          className="work-orders-table"
        />
      </div>
    </section>
  );
};

export default WorkOrdersPage;

import {
  IoCheckmark,
  IoSearchOutline,
  IoChevronUpOutline,
  IoChevronDownOutline,
} from 'react-icons/io5';
import { useState } from 'react';
import { Divider, Input, Select } from 'antd';

import './SearchableDropdown.scss';

const { Option } = Select;

const SearchableDropdown = ({
  label,
  itemHeader,
  searchValue,
  placeholder,
  variant = '',
  onItemSelect,
  dropdownItems,
  dropdownValue,
  onSearchChange,
  isOpen = false,
  errored = false,
  disabled = false,
  searchPlaceholder,
  isSearchable = true,
  placement = 'topLeft',
  defaultOption = null,
  resetToDefault = null,
  customValueRender = false,
  selectedHasCheckmark = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(isOpen);

  return (
    <div className={`searchable-dropdown ${variant}`}>
      {label && <p className="dropdown-label">{label}</p> }
      <Select
        disabled={disabled}
        open={dropdownOpen}
        placement={placement}
        onChange={onItemSelect}
        popupClassName={`${variant}-popup`}
        className={errored ? 'errored-border' : ''}
        value={customValueRender ? null : dropdownValue}
        placeholder={customValueRender ? dropdownValue : placeholder}
        onDropdownVisibleChange={(visible) => setDropdownOpen(visible)}
        suffixIcon={dropdownOpen ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
        dropdownRender={(menu) => (
          <>{isSearchable && (
          <>
            <Input
              value={searchValue}
              onChange={onSearchChange}
              prefix={<IoSearchOutline />}
              placeholder={searchPlaceholder}
            />
            <Divider />
          </>
          )}
            <p className="dropdown-item-header">{itemHeader}</p>
            {menu}
          </>
        )}
      >
        {dropdownItems.map(({ key, label: optionText, value }) => (
          <Option value={value} key={key}>
            <span className={optionText === dropdownValue ? 'selected' : ''}>
              {optionText}
            </span>
            {selectedHasCheckmark && (
              optionText === dropdownValue
                && <IoCheckmark className="checked-icon" />
            )}
            {defaultOption && (
              defaultOption.label === optionText
                && (
                  <span className="default">
                    DEFAULT
                  </span>
                )
            )}
          </Option>
        ))}
      </Select>
      {resetToDefault
        && dropdownValue !== defaultOption.label
          && (
            <button className="reset-cta" onClick={resetToDefault}>
              RESET
            </button>
          )}
    </div>
  );
};

export default SearchableDropdown;

import * as actionTypes from 'single-asset/actions/constants/telemetryChartMetricsActionTypes';

export const defaultState = {
  chartMetrics: [],
  assetId: null,
  isPending: false,
};

const telemetryChartMetricsReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.TELEMETRY__CHART_METRICS_FETCH: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case actionTypes.TELEMETRY__CHART_METRICS_FETCH_FAILED: {
      const { errorResponse } = action;

      return {
        ...defaultState,
        errorResponse,
      };
    }

    case actionTypes.TELEMETRY__CHART_METRICS_FETCH_SUCCEEDED: {
      return {
        ...defaultState,
        chartMetrics: action.result,
        assetId: action.assetId,
      };
    }

    default: {
      return state;
    }
  }
};

export const selectChartMetrics = ({ singleAsset: { telemetry: { metrics } } }) => metrics;

export default telemetryChartMetricsReducer;

import { useTransition, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AssetListTable from 'shared/components/VirtualizedTable';
import { getFilteredAssetList } from 'shared/helpers/assetHelpers';
import { assetFieldNames } from 'shared/constants/altitudeConstants';

import {
  assetListTableScroll,
  assetListTableColumns,
  ASSET_LIST_TABLE_ROW_HEIGHT,
} from 'dashboard/constants/dashboardConstants';
import AssetListForm from 'dashboard/components/filtration/AssetListForm';
import AssetListCards from 'dashboard/components/assetDisplay/AssetListCards';
import * as dashboardConstants from 'dashboard/constants/dashboardConstants';
import AssetListSkeleton from 'dashboard/components/assetDisplay/AssetListSkeleton';

import './AssetList.scss';

const AssetList = ({
  disabled,
  assetList,
  filterGroups,
  assetListFilters,
  resetAltitudeGroup,
  selectAltitudeGroup,
  selectedStatusFilter,
  defaultView = dashboardConstants.assetListDisplayTypes.TABLE_VIEW,
  ...otherProps
}) => {
  const navigate = useNavigate();
  const [, startTransition] = useTransition();
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedDisplayType, setSelectedDisplayType] = useState(defaultView);
  const filterFields = [
    assetFieldNames.ASSET_NAME,
    assetFieldNames.MODEL,
    assetFieldNames.ORG_NAME,
    assetFieldNames.SITE_NAME,
    assetFieldNames.STATUS,
  ];

  const handleFilterChange = (data) => {
    setFilterText(data.filterText);
  };

  const handleDisplayTypeChange = () => {
    setSelectedDisplayType(
      selectedDisplayType === dashboardConstants.assetListDisplayTypes.TABLE_VIEW
        ? dashboardConstants.assetListDisplayTypes.CARD_VIEW
        : dashboardConstants.assetListDisplayTypes.TABLE_VIEW,
    );
  };

  const navigateTo = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const newFilteredAssets = getFilteredAssetList(assetList, selectedStatusFilter, filterText, filterFields);
    startTransition(() => {
      setFilteredAssets(newFilteredAssets);
    });
  }, [assetList, filterText, selectedStatusFilter]);

  const RenderedAssetList = () => (
    selectedDisplayType === dashboardConstants.assetListDisplayTypes.TABLE_VIEW
      ? (
        <AssetListTable
          dataSource={filteredAssets}
          scroll={assetListTableScroll}
          columns={assetListTableColumns}
          rowHeight={ASSET_LIST_TABLE_ROW_HEIGHT}
        />
      )
      : (
        <AssetListCards assetList={filteredAssets} />
      )
  );

  return (
    <div className="asset-list">
      <AssetListForm
        {...otherProps}
        disabled={disabled}
        onNavigate={navigateTo}
        filterGroups={filterGroups}
        onFilterChange={handleFilterChange}
        assetListFilters={assetListFilters}
        resetAltitudeGroup={resetAltitudeGroup}
        selectAltitudeGroup={selectAltitudeGroup}
        onDisplayTypeChange={handleDisplayTypeChange}
      />
      {disabled
        ? <AssetListSkeleton />
        : <RenderedAssetList />}
    </div>
  );
};

export default AssetList;

import * as actionTypes from 'single-asset/actions/constants/telemetryChartMetricsActionTypes';
import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import { getChartMetrics } from 'shared/repositories/axilSeriesRepository';

export const fetchChartMetrics = asyncHookActionCreator({
  PENDING_ACTION_TYPE: actionTypes.TELEMETRY__CHART_METRICS_FETCH,
  FAILED_ACTION_TYPE: actionTypes.TELEMETRY__CHART_METRICS_FETCH_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.TELEMETRY__CHART_METRICS_FETCH_SUCCEEDED,
  repositoryAPI: getChartMetrics,
  responseTransformer: (result, body) => ({
    result,
    assetId: body.asset_id,
  }),
});

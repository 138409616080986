import { addAssetProps } from 'single-asset/transformers/singleAssetTransformers';

export const transformTelemetryLayoutResponse = (response) => ({ config: response });

export const transformStatsRequest = (_, {
  config,
  filters = [],
  ...restOfObject
}) => {
  const tags = Object.values(config.tags).map((value) => ({ value })).at(0);
  const transformedConfig = {
    ...config,
    tags,
  };

  return ({
    ...transformedConfig,
    ...addAssetProps(restOfObject),
    filters,
    ...restOfObject,
  });
};

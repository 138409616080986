import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FluxAutoComplete from 'shared/components/FluxAutoComplete';
import { getFilteredList, searchItemFieldsForMatch } from 'shared/helpers/filterHelpers';

import {
  REPORT_TABLE_COLUMNS,
  TABLE_FILTER_PLACEHOLDER,
  REPORT_TABLE_DROPDOWN_FIELDS,
  MINIMUM_AUTOCOMPLETE_FILTER_LENGTH,
} from 'reporting/constants/reportingConstants';
import { getReportFrequency } from 'reporting/helpers/scheduledReportHelpers';

import './ScheduledReportsTable.scss';

const ScheduledReportsTable = ({
  reports,
  organizations,
  setReportToDelete,
  setReportBeingEdited,
}) => {
  const navigate = useNavigate();

  const handleEditClick = (report) => {
    setReportBeingEdited(report);
    navigate('/reports');
  };

  const ScheduledReportButtons = ({ report }) => (
    <div className="modify-buttons">
      <button onClick={() => handleEditClick(report)}>Edit Report</button>
      <button onClick={() => setReportToDelete(report)}>Delete Report</button>
    </div>
  );

  const [baseReports, setBaseReports] = useState(reports.map((report) => ({
    key: report.editName ?? report.userReportName,
    reportCycle: getReportFrequency(report.cronUtc),
    reportName: report.editName ?? report.userReportName,
    editReport: <ScheduledReportButtons report={report} />,
    reportTypeText: report.reportName ?? 'Report type unavailable',
    reportType: <div className="report-type-pill">{report.reportName ?? 'Report type unavailable'}</div>,
    customer: organizations.find((org) => org.orgId === report.options.orgId)?.orgName ?? 'Name unavailable',
  })));
  const [filterInput, setFilterInput] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filteredReports, setFilteredReports] = useState(baseReports);

  const createDropdownItems = (matchedItems) => {
    const addedItems = [];
    // eslint-disable-next-line consistent-return, array-callback-return
    return matchedItems.map((item) => {
      const [fieldName, fieldValue] = searchItemFieldsForMatch(item, filterInput);
      if (!addedItems.includes(fieldValue) && REPORT_TABLE_DROPDOWN_FIELDS.includes(fieldName)) {
        addedItems.push(fieldValue);
        return ({
          key: fieldValue,
          value: fieldValue,
          label: (
            <div>
              {fieldValue}
            </div>
          ),
        });
      }
    }).filter((item) => item);
  };

  const handleDropdownSelection = (val) => {
    setSelectedFilter(val);
    setFilterInput('');
  };

  const filterReports = (report, filterItem) => (
    report.reportName.toLowerCase().includes(filterItem)
      || report.customer.toLowerCase().includes(filterItem)
      || report.reportTypeText.toLowerCase().includes(filterItem)
  );

  useEffect(() => {
    filterInput.length <= MINIMUM_AUTOCOMPLETE_FILTER_LENGTH
      ? setDropdownOptions([])
      : setDropdownOptions(
        createDropdownItems(getFilteredList(filterInput, ['customer', 'reportTypeText'], baseReports)),
      );
    setFilteredReports(
      baseReports
        .filter((report) => filterReports(report, selectedFilter?.toLowerCase() ?? ''))
        .filter((report) => filterReports(report, filterInput)),
    );
  }, [filterInput, selectedFilter, baseReports]);

  useEffect(() => {
    setBaseReports(reports.map((report) => ({
      key: report.editName ?? report.userReportName,
      reportCycle: getReportFrequency(report.cronUtc),
      reportName: report.editName ?? report.userReportName,
      editReport: <ScheduledReportButtons report={report} />,
      reportTypeText: report.reportName ?? 'Report type unavailable',
      reportType: <div className="report-type-pill">{report.reportName ?? 'Report type unavailable'}</div>,
      customer: organizations.find((org) => org.orgId === report.options.orgId)?.orgName ?? 'Name unavailable',
    })));
  }, [reports]);

  return (
    <div className="scheduled-reports">
      <FluxAutoComplete
        value={filterInput}
        options={dropdownOptions}
        onSelect={handleDropdownSelection}
        placeholder={TABLE_FILTER_PLACEHOLDER}
        onChange={(input) => setFilterInput(input.toLowerCase())}
      />
      <section className="table-filter-label">
        <div>
          <h2>{selectedFilter || 'All'}</h2>
          <h1>
            Scheduled Reports { ' ' }
            <span className="number-badge">
              {filteredReports.length}
            </span>
          </h1>
        </div>
      </section>
      <Table
        pagination={false}
        scroll={{ y: 500 }}
        dataSource={filteredReports}
        columns={REPORT_TABLE_COLUMNS}
        className="scheduled-reports-table"
        rowClassName={(_, index) => (!(index % 2) ? 'table-row-light' : 'table-row-dark')}
      />
    </div>
  );
};

export default ScheduledReportsTable;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

const userPreferenceEndpoint = '/axil-preferences/flux/user';

export const getPreferences = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: userPreferenceEndpoint,
  method: 'GET',
  isAxil: true,
});

export const updateHomepage = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: `${userPreferenceEndpoint}/homepage`,
  body,
});

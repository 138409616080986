import { MISSING_FIELD_VALUE_PLACEHOLDER } from 'shared/constants/altitudeConstants';

export const getTitleCase = (s) => s?.length && s.replace(/^_*(.)|_+(.)/g, (b, c, d) => (c ? c.toUpperCase() : ` ${d.toUpperCase()}`));

export const formUnitProfileEntries = (entries) => entries.map(([key, val]) => {
  let index = 0;
  const title = key || MISSING_FIELD_VALUE_PLACEHOLDER;
  const value = val || MISSING_FIELD_VALUE_PLACEHOLDER;

  switch (title) {
    case 'driver':
      index = 1; break;
    case 'application_type':
      index = 2; break;
    case 'lat':
      index = 3;
      break;
    case 'lon':
      index = 4;
      break;
    case 'compressor_serial':
      index = 5; break;
    case 'last_pm_date':
      index = 6; break;
    case 'next_pm_date':
      index = 7; break;
    case 'driver_rated_hp':
      index = 8; break;
    default: break;
  }

  return { title, value, index };
});

import NotificationToggle from 'shared/components/BlueToggle';
import { NOTIFICATION_TYPES, TABLE_NOTIFICATION_TYPES } from 'account-settings/constants/accountSettingsConstants';

export const generateAllSubscriptions = (subscriptionValue) => {
  const entries = {};
  NOTIFICATION_TYPES.reduce((acc, cur) => {
    acc[cur] = subscriptionValue;
    return acc;
  }, entries);
  return entries;
};

export const updateAllSubscriptionValues = (assetId, modifiedSubscriptions, setModifiedSubscriptions, newValue = true) => {
  const newEntry = {
    [assetId]: generateAllSubscriptions(newValue),
  };
  setModifiedSubscriptions({ ...modifiedSubscriptions, ...newEntry });
};

export const handleToggleAll = (asset, modifiedSubscriptions, setModifiedSubscriptions) => {
  const existingSubscriptions = modifiedSubscriptions[asset.asset_id];
  if (existingSubscriptions) {
    const keysOnExisting = NOTIFICATION_TYPES.filter((nType) => {
      const valueOnExisting = existingSubscriptions[nType];
      return !!valueOnExisting;
    });
    keysOnExisting.length === NOTIFICATION_TYPES.length
      ? updateAllSubscriptionValues(asset.asset_id, modifiedSubscriptions, setModifiedSubscriptions, false)
      : updateAllSubscriptionValues(asset.asset_id, modifiedSubscriptions, setModifiedSubscriptions);
  } else {
    updateAllSubscriptionValues(asset.asset_id, modifiedSubscriptions, setModifiedSubscriptions);
  }
};

export const addNewEntryToSubscriptions = (assetId, notiType, modifiedSubscriptions, setModifiedSubscriptions) => {
  const newEntry = { [assetId]: { [notiType]: true } };
  setModifiedSubscriptions({ ...modifiedSubscriptions, ...newEntry });
};

export const modifyExistingSubscriptionValue = (assetId, existingSubscriptions, notiType, modifiedSubscriptions, setModifiedSubscriptions) => {
  const subForNotiType = existingSubscriptions[notiType];
  if (subForNotiType) {
    const falsifyAllProperties = Object.entries(existingSubscriptions).length === 1;
    if (falsifyAllProperties) updateAllSubscriptionValues(assetId, modifiedSubscriptions, setModifiedSubscriptions, false);
    else {
      const { [notiType]: removedProperty, ...remainingSubscriptions } = existingSubscriptions;
      setModifiedSubscriptions({ ...modifiedSubscriptions, [assetId]: remainingSubscriptions });
    }
  } else {
    setModifiedSubscriptions({
      ...modifiedSubscriptions,
      [assetId]: {
        ...existingSubscriptions,
        [notiType]: true,
      },
    });
  }
};

export const handleToggleSingle = (asset, notificationType, modifiedSubscriptions, setModifiedSubscriptions) => {
  const existingSubsForAsset = modifiedSubscriptions[asset.asset_id];
  !existingSubsForAsset
    ? addNewEntryToSubscriptions(asset.asset_id, notificationType, modifiedSubscriptions, setModifiedSubscriptions)
    : modifyExistingSubscriptionValue(asset.asset_id, existingSubsForAsset, notificationType, modifiedSubscriptions, setModifiedSubscriptions);
};

export const determineAllToggleValue = (assetId, modifiedSubscriptions) => {
  const truthyValues = Object.values(modifiedSubscriptions[assetId] ?? {}).filter((val) => val);
  return truthyValues.length === NOTIFICATION_TYPES.length;
};

export const createToggles = (asset, modifiedSubscriptions, setModifiedSubscriptions) => {
  const toggleObject = {};
  TABLE_NOTIFICATION_TYPES.reduce((acc, cur) => {
    let checkedVal = false;
    let changeFunc = () => handleToggleSingle(asset, cur, modifiedSubscriptions, setModifiedSubscriptions);
    if (cur === 'all') {
      changeFunc = () => handleToggleAll(asset, modifiedSubscriptions, setModifiedSubscriptions);
      checkedVal = determineAllToggleValue(asset.asset_id, modifiedSubscriptions);
    } else checkedVal = modifiedSubscriptions[asset.asset_id]?.[cur] === true;
    acc[cur] = <NotificationToggle onChange={changeFunc} checked={checkedVal} />;
    return acc;
  }, toggleObject);
  return toggleObject;
};

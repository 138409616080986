export const DASHBOARD_ASSET_LIST__FETCH = 'DASHBOARD_ASSET_LIST__FETCH';
export const DASHBOARD_ASSET_LIST__FETCH_SUCCEEDED = 'DASHBOARD_ASSET_LIST__FETCH_SUCCEEDED';
export const DASHBOARD_ASSET_LIST__FETCH_FAILED = 'DASHBOARD_ASSET_LIST__FETCH_FAILED';

export const DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH = 'DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH';
export const DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_FAILED = 'DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_FAILED';
export const DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_SUCCESS = 'DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_SUCCESS';

export const DASHBOARD_ASSET_LIST__SELECT_ALTITUDE_GROUP = 'DASHBOARD_ASSET_LIST__SELECT_ALTITUDE_GROUP';
export const DASHBOARD_ASSET_LIST__RESET_ALTITUDE_GROUP = 'DASHBOARD_ASSET_LIST__RESET_ALTITUDE_GROUP';

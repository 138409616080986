import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { updateHomepagePreference } from 'shared/actions/preferencesActions';

import {
  fetchFilteredAssetList,
  resetAltitudeGroupFilter,
  setSelectedAltitudeGroupFilter,
} from 'dashboard/actions/dashboardAssetListActions';
import { selectDashboard } from 'dashboard/reducers';
import DashboardPage from 'dashboard/pages/DashboardPage';
import { fetchDashboardStats } from 'dashboard/actions/dashboardStatsActions';
import { fetchFilterGroups, resetFilterFetchAttempts } from 'dashboard/actions/dashboardFilterActions';

import FullScreenLoader from 'FullScreenLoader';

const DashboardPageContainer = ({ user, preferences }) => {
  const dispatch = useDispatch();
  const { org_id, site_id } = useParams();
  const getStats = (configList, filterList) => dispatch(fetchDashboardStats(configList, filterList));
  const getFilterGroups = () => dispatch(fetchFilterGroups());
  const getFilteredAssetList = (filters) => dispatch(fetchFilteredAssetList(filters));
  const resetAltitudeGroup = () => dispatch(resetAltitudeGroupFilter());
  const selectAltitudeGroup = (altitudeInfo) => dispatch(setSelectedAltitudeGroupFilter(altitudeInfo));
  const resetFilterGroupAttempts = () => dispatch(resetFilterFetchAttempts());
  const updateHomepage = (reqBody) => dispatch(updateHomepagePreference(reqBody));

  return user
    ? (
      <DashboardPage
        user={user}
        orgId={org_id}
        siteId={site_id}
        getStats={getStats}
        preferences={preferences}
        updateHomepage={updateHomepage}
        getFilterGroups={getFilterGroups}
        resetAltitudeGroup={resetAltitudeGroup}
        dashboard={useSelector(selectDashboard)}
        selectAltitudeGroup={selectAltitudeGroup}
        getFilteredAssetList={getFilteredAssetList}
        resetFilterGroupAttempts={resetFilterGroupAttempts}
      />
    ) : <FullScreenLoader loading />;
};

export default DashboardPageContainer;

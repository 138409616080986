import { selectAsset } from 'single-asset/reducers/assetReducer';
import { fetchSeriesByDate, modifyTelemetryCharts } from 'shared/repositories/axilSeriesRepository';
import * as telemetryChartsActionTypes from 'single-asset/actions/telemetryChartsActionsTypes';
import { asyncBatchHookActionCreator, asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import { transformChartSeriesRequest, transformChartSeriesResponse } from 'single-asset/transformers/singleAssetTransformers';

export const fetchTelemetryChartSeries = asyncBatchHookActionCreator({
  repositoryAPI: fetchSeriesByDate,
  requestTransformer: transformChartSeriesRequest,
  responseTransformer: transformChartSeriesResponse,
  PENDING_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST,
  SUCCESS_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__FETCH_SERIES_LIST_FAILED,
  selector: selectAsset,
});

export const editTelemetryCharts = asyncHookActionCreator({
  repositoryAPI: modifyTelemetryCharts,
  PENDING_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS,
  SUCCESS_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS_SUCCESS,
  FAILED_ACTION_TYPE: telemetryChartsActionTypes.TELEMETRY_CHARTS__MODIFY_CHARTS_FAILED,
});

export const resetTelemetryChartSeries = () => ({
  type: telemetryChartsActionTypes.TELEMETRY_CHARTS__RESET,
});

export const resetRefetchCharts = () => ({
  type: telemetryChartsActionTypes.TELEMETRY_CHARTS__RESET_REFETCH_CHARTS,
});

export const resetModifyChartsError = () => ({
  type: telemetryChartsActionTypes.TELEMETRY_CHART__RESET_ERROR,
});

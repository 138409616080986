import { NAV_ITEMS } from 'dashboard/constants/dashboardConstants';
import { useNavigate } from 'react-router-dom';

import './DashboardNavigation.scss';

const DashboardNavigation = ({
  email,
  location,
  preferences,
  updateHomepage,
}) => {
  const navigate = useNavigate();
  const navItems = NAV_ITEMS.at(0).pathname === (preferences?.homepage ?? 'dashboard') ? NAV_ITEMS : [...NAV_ITEMS].reverse();

  const handleHomepageUpdate = () => {
    const preferencesBody = {
      [email]: {
        preferences: {
          homepage: location,
        },
      },
    };
    updateHomepage(preferencesBody);
  };

  return (
    <div className="dashboard-nav">
      {navItems.map((item) => (
        <button
          key={item.pathname}
          className={location === item.pathname ? 'active' : ''}
          onClick={() => navigate(`/${item.pathname}`)}
        >
          {item.title}
        </button>
      ))}
      {location === navItems.at(-1).pathname && (
      <button
        className="set-default"
        onClick={handleHomepageUpdate}
      >
        Make default
      </button>
      )}
    </div>
  );
};

export default DashboardNavigation;

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { getStatusClassAndText } from 'shared/helpers/assetHelpers';
import { getRunTimePercentageClassAndText } from 'shared/helpers/altitudeHelpers';

import { assetTableColumnIndexes } from 'dashboard/constants/dashboardConstants';

import './VirtualizedTableCell.scss';

const VirtualizedTableCell = ({
  item,
  style,
  onHover,
  rowIndex,
  columnIndex,
  formattedCells,
  lastColumnIndex,
  hoveredClassName,
}) => {
  const baseColumnClassName = (rowIndex % 2 === 0)
    ? `asset-list-table-cell stripe ${hoveredClassName}`
    : `asset-list-table-cell ${hoveredClassName}`;

  const columnClassName = (columnIndex === 0) && `${baseColumnClassName} rounded-left`
    || (columnIndex === lastColumnIndex) && `${baseColumnClassName} rounded-right`
    || baseColumnClassName;

  const getTextClassAndContent = (text, index) => (
    (text === undefined || text === null) && ['', '---']
    || index === assetTableColumnIndexes.CUSTOMER_COLUMN_INDEX && ['customer', text]
    || index === assetTableColumnIndexes.LOCATION_COLUMN_INDEX && ['location', text]
    || index === assetTableColumnIndexes.RUN_TIME_STATUS_COLUMN_INDEX && getStatusClassAndText(text)
    || index === assetTableColumnIndexes.RUN_TIME_PERCENTAGE_COLUMN_INDEX && getRunTimePercentageClassAndText(text)
    || index === assetTableColumnIndexes.LAST_SYNCED_COLUMN_INDEX && ['', extendedDayJs(text).fromNow()]
    || ['', text]
  );

  const [textClass, textContent] = formattedCells ? getTextClassAndContent(item, columnIndex) : ['', item];

  return (
    <div
      className={columnClassName}
      style={{
        ...style,
      }}
      onMouseEnter={onHover}
    >
      <div className={`asset-list-table-cell__text ${textClass}`}>
        <div>
          {textContent}
        </div>
      </div>
    </div>
  );
};

export default VirtualizedTableCell;

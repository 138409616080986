import { Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PillToggleButton from 'shared/components/PillToggleButton';
import { isUserExternal } from 'shared/helpers/permissionHelpers';
import { addNotification } from 'shared/actions/notificationsActions';

import {
  EXTERNAL_ACCOUNT_INFO_STATS,
  INTERNAL_ACCOUNT_INFO_STATS, NUM_REGEX,
} from 'account-settings/constants/accountSettingsConstants';
import { formatPhoneNumber } from 'account-settings/helpers/formatHelpers';
import { resetUpdateStatus } from 'account-settings/actions/userPreferenceActions';
import EditablePhoneStat from 'account-settings/components/account-information/EditablePhoneStat';

import './AccountInformation.scss';

const AccountInformation = ({
  user,
  demoMode,
  preferences,
  updatePending,
  errorResponse,
  toggleDemoMode,
  updateUserPhone,
  updateSuccessful,
}) => {
  const dispatch = useDispatch();

  const [editPhone, setEditPhone] = useState(false);
  const [userPhone, setUserPhone] = useState(formatPhoneNumber(preferences.phone_number ?? ''));
  const [editError, setEditError] = useState(null);

  const handlePhoneNumberSumbit = () => {
    dispatch(resetUpdateStatus());
    setEditError(null);
    const strippedInput = userPhone.split('.').join('');
    if (!NUM_REGEX.test(strippedInput) || strippedInput.length !== 10) {
      setEditError('Phone number must be 10 digits');
      return false;
    }
    if (strippedInput === preferences.phone_number) {
      setEditPhone(false);
      return true;
    }
    const updatedPreferences = {
      ...preferences,
      phone_number: strippedInput,
    };
    return updateUserPhone(updatedPreferences);
  };

  const accountInfoStats = isUserExternal(user?.email) ? EXTERNAL_ACCOUNT_INFO_STATS : INTERNAL_ACCOUNT_INFO_STATS;

  const statsToRender = accountInfoStats.map((stat) => {
    const statItem = { title: stat.toUpperCase(), key: stat };
    if (stat === 'phone number') {
      statItem.value = preferences.phone_number;
      statItem.formatter = () => (
        <EditablePhoneStat
          editPhone={editPhone}
          editError={editError}
          userPhone={userPhone}
          setEditPhone={setEditPhone}
          setUserPhone={setUserPhone}
          handlePhoneNumberSumbit={handlePhoneNumberSumbit}
          initialPhone={formatPhoneNumber(preferences.phone_number ?? '')}
        />
      );
    } else if (stat === 'demo mode') {
      statItem.formatter = () => (
        <PillToggleButton
          variant="small"
          isChecked={demoMode.demo}
          toggleNameList={['On', 'Off']}
          onToggle={() => toggleDemoMode({ demo: !demoMode.demo })}
        />
      );
    } else statItem.value = user[stat];
    return statItem;
  });

  useEffect(() => {
    if (!updatePending && errorResponse && editPhone) {
      dispatch(addNotification({ type: 'error', message: errorResponse.message }));
    } else if (!updatePending && updateSuccessful && editPhone) setEditPhone(false);
  }, [errorResponse, updatePending, updateSuccessful]);

  return (
    <div className="account-information">
      <div className={`${isUserExternal(user?.email) ? 'external' : ''} flux-stat-list`}>
        {
          statsToRender.map((stat) => (
            <div className="account-stat" key={stat.key}>
              <Statistic {...stat} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default AccountInformation;

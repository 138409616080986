import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '@flogistix/flo-ui';

import { selectSession } from 'shared/reducers/sessionReducer';
import PillToggleButton from 'shared/components/PillToggleButton';
import { addNotification } from 'shared/actions/notificationsActions';
import { getOrganizationAreas, getScheduledReports } from 'shared/repositories/reportingRepository';

import {
  getTimeFromCron,
  getReportFrequency,
  getWeekdayFromCron,
  getMonthlyDateFromCron,
} from 'reporting/helpers/scheduledReportHelpers';
import ChooseReport from 'reporting/components/new-report/ChooseReport';
import ReportFilters from 'reporting/components/new-report/ReportFilters';
import NewReportSkeleton from 'reporting/components/new-report/NewReportSkeleton';
import { getValidReportRequestData } from 'reporting/helpers/newReportValidation';
import ExtraReportDetails from 'reporting/components/new-report/ExtraReportDetails';
import NewReportDatePicker from 'reporting/components/new-report/NewReportDatePicker';
import ScheduledReportOptions from 'reporting/components/new-report/ScheduledReportOptions';
import { filterOptions, reportsWithExtras, REPORT_ALREADY_EXISTS } from 'reporting/constants/reportingConstants';

import './NewReportCreation.scss';

const NewReportCreation = ({
  orgs,
  onSubmit,
  availableReports,
  reportBeingEdited,
  setReportBeingEdited,
}) => {
  const defaultReportType = reportBeingEdited ? availableReports.find((r) => r.pk === reportBeingEdited?.reportName) : null;
  const defaultReportTitle = reportBeingEdited ? reportBeingEdited?.editName ?? reportBeingEdited?.userReportName : '';
  const defaultFrequency = reportBeingEdited ? getReportFrequency(reportBeingEdited?.cronUtc, true) : null;
  const defaultWeekday = defaultFrequency?.value === 'Weekly' ? getWeekdayFromCron(reportBeingEdited?.cronUtc) : null;
  const defaultTime = reportBeingEdited ? getTimeFromCron(reportBeingEdited?.cronUtc) : null;
  const defaultFinalizedDates = defaultFrequency?.value === 'Monthly' ? [getMonthlyDateFromCron(reportBeingEdited?.cronUtc), null] : null;
  const defaultOrgId = reportBeingEdited?.options?.orgId ?? null;
  const defaultArea = reportBeingEdited ? reportBeingEdited?.options?.area : 'All';

  const defaultDropdownFilters = { org: '', area: '' };
  const defaultReportFilters = {
    org: defaultOrgId,
    area: defaultArea,
  };

  const defaultScheduledOptions = {
    title: defaultReportTitle,
    time: defaultTime,
    frequency: defaultFrequency,
    weekday: defaultWeekday,
  };

  const defaultErrors = {
    message: null,
    field: null,
  };

  const orgDropdownItems = orgs?.map((org) => ({
    value: org.orgId,
    label: org.orgName,
    key: org.orgId,
  }));

  const nonDuplicateOptions = orgDropdownItems?.filter((value, index, self) => index === self.findIndex((t) => (
    t.key === value.key && t.value === value.value
  )));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector(selectSession);

  const [areas, setAreas] = useState([]);
  const [singleArea, setSingleArea] = useState(false);
  const [scheduledReports, setScheduledReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState(defaultErrors);
  const [extraReportOptions, setExtraReportOptions] = useState({});
  const [oneTimeReport, setOneTimeReport] = useState(!reportBeingEdited);
  const [reportFilters, setReportFilters] = useState(defaultReportFilters);
  const [finalizedDates, setFinalizedDates] = useState(defaultFinalizedDates);
  const [selectedReportType, setSelectedReportType] = useState(defaultReportType);
  const [dropdownOptionFilters, setDropdownOptionFilters] = useState(defaultDropdownFilters);
  const [scheduledReportOptions, setScheduledReportOptions] = useState(defaultScheduledOptions);

  const resetErrors = () => setErrorMessage(defaultErrors);

  const resetAllFilterStates = () => {
    setReportFilters(defaultReportFilters);
    setSingleArea(false);
    setFinalizedDates(null);
    setOneTimeReport(true);
    setScheduledReportOptions(defaultScheduledOptions);
    resetErrors();
  };

  const handlePillToggle = () => {
    setOneTimeReport(!oneTimeReport);
    setFinalizedDates(null);
    setScheduledReportOptions(defaultScheduledOptions);
    (errorMessage?.field === 'startTime' || errorMessage?.field === 'endTime') && resetErrors();
  };

  const handleNewReportSelection = (newReportType) => {
    const newSelectedReport = newReportType === selectedReportType ? null : newReportType;
    setSelectedReportType(newSelectedReport);
    !newSelectedReport && resetAllFilterStates();
  };

  const handleDropdownSelection = (type) => (value) => {
    setDropdownOptionFilters(defaultDropdownFilters);
    setReportFilters({ ...reportFilters, [type]: value });
    if (type === filterOptions.ORGANIZATION) setSingleArea(false);
  };

  const handleDropdownSearch = (type) => ({ target: { value } }) => (
    setDropdownOptionFilters({ ...dropdownOptionFilters, [type]: value.toLowerCase() })
  );

  const handleSuccessfulEdit = () => {
    setReportBeingEdited(null);
    navigate('scheduled');
  };

  // eslint-disable-next-line consistent-return
  const validateAndSubmitReport = async () => {
    setErrorMessage(defaultErrors);
    const requestData = getValidReportRequestData({
      selectedReportType,
      reportFilters,
      finalizedDates,
      extraReportOptions,
      setErrorMessage,
      isScheduled: !oneTimeReport,
      scheduledReportOptions: !oneTimeReport ? scheduledReportOptions : null,
    });
    if (requestData) {
      const reportName = selectedReportType.pk;
      const requestParams = {
        requestData,
      };

      if (!reportBeingEdited) {
        requestParams.oneTimeReport = oneTimeReport;
        requestParams.reportName = reportName;
      } else if (reportBeingEdited?.userReportName !== scheduledReportOptions.title) {
        requestParams.requestData.editName = scheduledReportOptions.title;
        requestParams.requestData.userReportName = reportBeingEdited.userReportName;
      }

      // ensuring edit name is not already used in users existing reports
      const existingReportsWithName = scheduledReports.find((report) => report.userReportName === requestParams?.editName);
      if (existingReportsWithName && (existingReportsWithName !== reportBeingEdited)) {
        return setErrorMessage({
          message: REPORT_ALREADY_EXISTS,
          field: null,
        });
      }

      const successfullySubmitted = await onSubmit(requestParams);
      if (successfullySubmitted) {
        resetAllFilterStates();
        setSelectedReportType(null);
        if (oneTimeReport) return true;
        reportBeingEdited
          && handleSuccessfulEdit(successfullySubmitted);
      }
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await getOrganizationAreas({ accessToken: session?.token, body: reportFilters?.org });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      const areasFromResponse = new Set(responseData?.assets?.map((asset) => asset.area));

      if (areasFromResponse.size > 0) {
        setAreas([...areasFromResponse]);
      }
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  const fetchScheduledReports = async () => {
    setScheduledReports([]);

    try {
      const response = await getScheduledReports({ accessToken: session.token });

      if (!response.ok) {
        throw new Error(`Fetch failed with status: ${response.status}`);
      }

      const reportsData = await response.json();
      setScheduledReports(reportsData?.reports);
    } catch (error) {
      console.error('Error fetching scheduled reports:', error);
    }
  };

  useEffect(() => {
    fetchScheduledReports();
  }, []);

  useEffect(() => {
    setAreas([]);
    reportFilters.org && fetchAreas();
  }, [reportFilters.org]);

  useEffect(() => {
    errorMessage?.message
      && dispatch(addNotification({ type: 'error', message: errorMessage.message }));
  }, [errorMessage]);

  useEffect(() => {
    const { pk, options: { properties = {} } = {} } = selectedReportType || {};

    if (pk === reportsWithExtras.FLUX_FLEET) {
      const additionalOptions = Object.values(properties)
        .filter((option) => option.format === 'checkbox')
        .map((option) => ({
          name: option.name,
          label: option.label,
          value: option.default === 'true',
        }));

      setExtraReportOptions({
        type: reportsWithExtras.FLUX_FLEET,
        options: additionalOptions,
      });
    } else if (pk === reportsWithExtras.UNIT_PERFORMANCE) {
      const selectableOptions = Object.values(properties).filter((option) => option.format === 'select')
        .map((option) => ({
          name: option.name,
          label: option.label,
          options: option.options,
          value: option.default,
        }));

      setExtraReportOptions({
        type: reportsWithExtras.UNIT_PERFORMANCE,
        options: selectableOptions[0],
      });
    } else if (Object.entries(extraReportOptions)) setExtraReportOptions({});
  }, [selectedReportType]);

  return (
    <div className="full-width">
      {availableReports?.length
        ? (
          <div className="new-report">
            <ChooseReport
              reports={availableReports}
              selectedReport={selectedReportType}
              setSelectedReport={handleNewReportSelection}
            />
            {Object.entries(extraReportOptions).length > 0
              && (
                <ExtraReportDetails
                  errored={errorMessage}
                  extraOptions={extraReportOptions}
                  setExtraOptions={setExtraReportOptions}
                />
              )}
            {selectedReportType && (
              <div className="partial-width-65">
                <ReportFilters
                  areas={areas}
                  errored={errorMessage}
                  singleArea={singleArea}
                  reportFilters={reportFilters}
                  orgDropdownItems={nonDuplicateOptions}
                  handleDropdownSearch={handleDropdownSearch}
                  dropdownOptionFilters={dropdownOptionFilters}
                  handleDropdownSelection={handleDropdownSelection}
                  selectedOrgName={orgs?.find((org) => org.orgId === reportFilters.org)?.orgName}
                />
                {!reportBeingEdited && (
                  <PillToggleButton
                    variant="blue"
                    onToggle={handlePillToggle}
                    toggleNameList={['Scheduled Report', 'One-Time Report']}
                  />
                )}
                {oneTimeReport
                  ? (
                    <NewReportDatePicker
                      errored={errorMessage}
                      finalizedDates={finalizedDates}
                      setFinalizedDates={setFinalizedDates}
                    />
                  )
                  : (
                    <ScheduledReportOptions
                      errored={errorMessage}
                      finalizedDates={finalizedDates}
                      setFinalizedDates={setFinalizedDates}
                      scheduledReportOptions={scheduledReportOptions}
                      setScheduledReportOptions={setScheduledReportOptions}
                    />
                  )}
              </div>
            )}
          </div>
        )
        : <NewReportSkeleton />}
      {selectedReportType && (
        <div className="submit-button-container">
          {reportBeingEdited && (
            <Button
              variation="black-outline"
              style={{ marginRight: '8px' }}
              onClick={() => {
                setReportBeingEdited(null);
                resetAllFilterStates();
                navigate('scheduled');
              }}
            >
              Cancel
            </Button>
          )}
          <Button onClick={validateAndSubmitReport}>
            {`${reportBeingEdited ? 'Save' : (oneTimeReport ? 'Submit' : 'Schedule')} Report`}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewReportCreation;

import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import * as actionTypes from 'single-asset/actions/moreInfoImageActionTypes';
import { getUnitProfileImage } from 'shared/repositories/moreInfoImageRepository';

export const fetchUnitProfileImage = asyncHookActionCreator({
  repositoryAPI: getUnitProfileImage,
  PENDING_ACTION_TYPE: actionTypes.MORE_INFO__IMAGE_FETCH,
  FAILED_ACTION_TYPE: actionTypes.MORE_INFO__IMAGE_FETCH_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.MORE_INFO__IMAGE_FETCH_SUCCEEDED,
});

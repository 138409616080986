export const TELEMETRY_CHARTS__FETCH_SERIES_LIST = 'TELEMETRY_CHARTS__FETCH_SERIES_LIST';
export const TELEMETRY_CHARTS__FETCH_SERIES_LIST_SUCCEEDED = 'TELEMETRY_CHARTS__FETCH_SERIES_LIST_SUCCEEDED';
export const TELEMETRY_CHARTS__FETCH_SERIES_LIST_FAILED = 'TELEMETRY_CHARTS__FETCH_SERIES_LIST_FAILED';
export const TELEMETRY_CHARTS__RESET = 'TELEMETRY_CHARTS__RESET';

export const TELEMETRY_CHARTS__MODIFY_CHARTS = 'TELEMETRY_CHARTS__MODIFY_CHARTS';
export const TELEMETRY_CHARTS__MODIFY_CHARTS_SUCCESS = 'TELEMETRY_CHARTS__MODIFY_CHARTS_SUCCESS';
export const TELEMETRY_CHARTS__MODIFY_CHARTS_FAILED = 'TELEMETRY_CHARTS__MODIFY_CHARTS_FAILED';
export const TELEMETRY_CHARTS__RESET_REFETCH_CHARTS = 'TELEMETRY_CHARTS__RESET_REFETCH_CHARTS';

export const TELEMETRY_CHART__RESET_ERROR = 'TELEMETRY_CHARTS__RESET_ERROR';

import * as telemetryStatsActionTypes from 'single-asset/actions/telemetryStatsActionTypes';
import { createStatsReducer } from 'single-asset/helpers/singleAssetHelpers';
import { defaultStatsState as defaultState } from 'single-asset/constants/singleAssetConstants';

export { defaultState };

const telemetryStatsReducer = createStatsReducer({
  defaultState,
  PENDING_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST,
  SUCCESS_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__FETCH_STATS_LIST_FAILED,
  RESET_ACTION_TYPE: telemetryStatsActionTypes.TELEMETRY_STATS__RESET_STATS_LIST,
});

export default telemetryStatsReducer;

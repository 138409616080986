import React from 'react';

import CarbonCalculatorPill from 'single-asset/components/carbon-capture-calculator/modal/components/CarbonCalculatorPill';

const CarbonCalculatorEquation = ({ entry }) => (
  <section
    className="equation-breakdown"
  >
    <CarbonCalculatorPill label={entry.description} />
    {entry.mathFunction
      ? <div className="carbon-calculator-math-symbol">{entry.mathFunction}</div>
      : <div style={{ width: '5px' }} />}
  </section>
);

export default CarbonCalculatorEquation;

import React from 'react';

import ChartCard from 'single-asset/components/charts/ChartCard';

import './ChartsList.scss';

const ChartsList = ({
  name,
  chartData,
  getChartsByDate,
  displayGridView,
}) => (
  <div className="charts-list-container">
    {chartData.map((series, index) => (
      <ChartCard
        name={name}
        index={index}
        series={series}
        key={series.seriesConfig.uuid}
        displayGridView={displayGridView}
        getChartsByDate={getChartsByDate}
        width={displayGridView ? '48%' : '98.5%'}
      />
    ))}
  </div>
);

export default ChartsList;

import React from 'react';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  CARBON_CAPTURE_TOOLTIP_TITLE,
  CARBON_CAPTURE_DEFAULT_DESCRIPTION,
} from 'single-asset/constants/carbonCaptureConstants';
import CarbonCaptureInfoCard from 'single-asset/components/carbon-capture-calculator/asset-header/CarbonCaptureInfoCard';

import './CarbonCaptureInfoContainer.scss';

const CarbonCaptureInfoContainer = ({
  dataPending,
  showDataError,
  openCarbonModal,
  currentVfmTotalAbated,
  currentCarbonCaptureTime,
}) => (
  <section className="carbon-capture-info-container">
    {showDataError
      ? (
        <section className="carbon-capture-info">
          <h6 className="carbon-capture-time-label">
            {currentCarbonCaptureTime}
          </h6>
          <CarbonCaptureInfoCard
            dataPending={dataPending}
            showDataError={showDataError}
            value={currentVfmTotalAbated}
          />
        </section>
      )
      : (
        <FluxTooltip
          showInfoCircle={false}
          title={CARBON_CAPTURE_TOOLTIP_TITLE}
          description={CARBON_CAPTURE_DEFAULT_DESCRIPTION}
          tooltip={(
            <section className="carbon-capture-info">
              <h6 className="carbon-capture-time-label">
                {currentCarbonCaptureTime}
              </h6>
              <CarbonCaptureInfoCard
                dataPending={dataPending}
                showDataError={showDataError}
                handleClick={openCarbonModal}
                value={currentVfmTotalAbated}
              />
            </section>
          )}
        />
      )}
  </section>
);

export default CarbonCaptureInfoContainer;

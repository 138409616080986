import { createSlice } from '@reduxjs/toolkit';

import * as dashboardFilterTypes from 'dashboard/actions/dashboardFilterActionTypes';

export const defaultState = {
  filterGroups: [],
  isPending: false,
  errorResponse: null,
  fetchAttempts: 0,
};

const handleFetchFilterGroups = (state) => ({
  ...defaultState,
  isPending: true,
  fetchAttempts: state.fetchAttempts + 1,
}
);

const handleFetchFilterGroupsSuccess = (state, { result }) => ({
  ...state,
  isPending: false,
  filterGroups: result,
});

const handleFetchFilterGroupsFailed = (state, { errorResponse }) => ({
  ...state,
  errorResponse,
  isPending: false,
});

const handleResetFilterFetchAttempts = () => ({
  ...defaultState,
});

const filterSlice = createSlice({
  name: 'filters',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(dashboardFilterTypes.FILTER_GROUPS__FETCH, handleFetchFilterGroups);
    builder.addCase(dashboardFilterTypes.FILTER_GROUPS__FETCH_SUCCESS, handleFetchFilterGroupsSuccess);
    builder.addCase(dashboardFilterTypes.FILTER_GROUPS__FETCH_FAILED, handleFetchFilterGroupsFailed);
    builder.addCase(dashboardFilterTypes.FILTER_GROUPS__RESET_FETCH_ATTEMPTS, handleResetFilterFetchAttempts);
  },
});

export default filterSlice.reducer;

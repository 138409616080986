import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_ALTITUDE_GROUP_ID } from 'dashboard/constants/dashboardConstants';
import * as assetListActionTypes from 'dashboard/actions/assetListActionTypes';

export const defaultState = {
  isPending: false,
  altitudeIsPending: false,
  altitudeGroupId: DEFAULT_ALTITUDE_GROUP_ID,
  altitudeGroupName: '',
  assetListPerAltitudeGroups: [],
  completeAssetList: [],
  errorResponse: null,
  altitudeFetchCount: 0,
};

export const selectAssetList = (state) => state?.dashboard?.assetList;

const handleAssetListFetch = () => ({
  ...defaultState,
  isPending: true,
});

const handleAssetListFetchFailed = (_, { errorResponse }) => ({
  ...defaultState,
  errorResponse,
});

const handleAssetListFetchSuccess = (state, { result }) => ({
  ...state,
  completeAssetList: result,
  isPending: false,
});

const handleAltitudeGroupAssetFetch = (state) => ({
  ...state,
  altitudeIsPending: true,
});

const handleAltitudeGroupAssetFetchFailed = (state, { errorResponse }) => ({
  ...state,
  assetListPerAltitudeGroups: [],
  errorResponse,
  altitudeFetchCount: state.altitudeFetchCount + 1,
  altitudeIsPending: false,
});

const handleAltitudeGroupAssetFetchSuccess = (state, { result }) => ({
  ...state,
  altitudeIsPending: false,
  assetListPerAltitudeGroups: result,
  altitudeFetchCount: state.altitudeFetchCount + 1,
});

const handleResetAltitudeGroupAndGroupAssets = (state) => ({
  ...state,
  assetListPerAltitudeGroups: [],
  altitudeGroupId: DEFAULT_ALTITUDE_GROUP_ID,
  altitudeGroupName: '',
  altitudeFetchCount: 0,
});

const handleAltitudeGroupSelection = (state, { altitudeInfo: { altitudeGroupId, altitudeGroupName } }) => ({
  ...state,
  altitudeGroupId,
  altitudeGroupName,
  altitudeFetchCount: 0,
});

const assetListSlice = createSlice({
  name: 'assetList',
  initialState: defaultState,
  reducers: {
    setAssetListFromDeprecatedGlobalContext(state, action) {
      const { assetListPerAltitudeGroups } = action.payload;
      return {
        ...state,
        isPending: false,
        assetListPerAltitudeGroups,
      };
    },

    setAltitudeGroupFromDeprecatedGlobalContext(state, action) {
      return {
        ...state,
        isPending: true,
        altitudeGroupId: action?.payload?.at(0) ?? DEFAULT_ALTITUDE_GROUP_ID,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH, handleAssetListFetch);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH_FAILED, handleAssetListFetchFailed);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__FETCH_SUCCEEDED, handleAssetListFetchSuccess);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH, handleAltitudeGroupAssetFetch);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_FAILED, handleAltitudeGroupAssetFetchFailed);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__ALTITUDE_GROUP_ASSET_FETCH_SUCCESS, handleAltitudeGroupAssetFetchSuccess);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__SELECT_ALTITUDE_GROUP, handleAltitudeGroupSelection);
    builder.addCase(assetListActionTypes.DASHBOARD_ASSET_LIST__RESET_ALTITUDE_GROUP, handleResetAltitudeGroupAndGroupAssets);
  },
});

export const { setAssetListFromDeprecatedGlobalContext, setAltitudeGroupFromDeprecatedGlobalContext } = assetListSlice.actions;
export default assetListSlice.reducer;

import { useSelector } from 'react-redux';
import React, { useEffect, useReducer, useState } from 'react';

import { extendedDayJs } from 'shared/helpers/timeHelpers';
import { filterArray } from 'shared/helpers/filterHelpers';
import { selectSession } from 'shared/reducers/sessionReducer';
import { loadingKeyedTableData } from 'shared/constants/tableDataConstants';

import { createTableSorter } from 'dashboard/helpers/dashboardHelpers';

import { selectAsset } from 'single-asset/reducers/assetReducer';
import { fetchEvents } from 'single-asset/actions/eventsActions';
import { rowClassName } from 'single-asset/helpers/workOrderHelpers';
import { sharedRequestBody } from 'single-asset/helpers/assetHelpers';
import AssetEventsTable from 'single-asset/components/events/AssetEventsTable';
import { fetchRecipientInfo } from 'single-asset/actions/eventRecipientActions';
import AssetEventsSearchForm from 'single-asset/components/events/AssetEventsSearchForm';
import { formatAssetEvents, filterEventDateRange } from 'single-asset/helpers/eventHelpers';
import AssetEventMessageColumn from 'single-asset/components/events/AssetEventsMessageColumn';
import AssetEventRecipientModal from 'single-asset/components/events/AssetEventRecipientModal';
import { ASSET_EVENTS_TABLE_LOADING_COLUMNS } from 'single-asset/components/events/AssetEventLoadingColumn';
import telemetryEventsReducer, { defaultState as defaultEventsState } from 'single-asset/reducers/telemetryEventsReducer';
import eventRecipientReducer, { defaultState as defaultRecipientState } from 'single-asset/reducers/eventRecipientReducer';

import './EventsPage.scss';

const EventsPage = () => {
  const currentAsset = useSelector(selectAsset);
  const currentSession = useSelector(selectSession);

  const [eventsState, eventsDispatch] = useReducer(telemetryEventsReducer, defaultEventsState);
  const [recipientState, recipientDispatch] = useReducer(eventRecipientReducer, defaultRecipientState);

  const [showModal, setShowModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [finalizedDates, setFinalizedDates] = useState(null);
  const [defaultAssetEvents, setDefaultAssetEvents] = useState([]);
  const [formattedAssetEvents, setFormattedAssetEvents] = useState([]);
  const [currentEventForModal, setCurrentEventForModal] = useState({});
  const [currentRecipientsForModal, setCurrentRecipientsForModal] = useState([]);

  const setEventOpenModal = (record) => {
    setCurrentEventForModal(record);
    setShowModal(true);
  };

  const clearEventCloseModal = () => {
    setCurrentEventForModal({});
    setCurrentRecipientsForModal([]);
    setShowModal(false);
  };

  const handleDateChange = () => {
    const formattedEnd = extendedDayJs(finalizedDates[1]).format('MM/DD/YYYY');
    const formattedStart = extendedDayJs(finalizedDates[0]).format('MM/DD/YYYY');

    const filteredEvents = filterEventDateRange(
      defaultAssetEvents,
      formattedStart,
      formattedEnd,
    );

    setFormattedAssetEvents(filteredEvents);
  };

  const handleClearDates = () => {
    setFinalizedDates(null);
    setFormattedAssetEvents(defaultAssetEvents);
  };

  const handleFiltering = () => {
    const filteredEvents = filterArray(
      defaultAssetEvents,
      searchString,
    );

    setFormattedAssetEvents(filteredEvents);
  };

  const handleRowClick = (record) => ({
    onClick: () => {
      setEventOpenModal(record);
    },
  });

  const assetEventTableColumns = [
    {
      title: 'Time',
      defaultSortOrder: 'descend',
      dataIndex: 'epoch_milliseconds',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('epoch_milliseconds'),
      },
      render: (value) => {
        const date = extendedDayJs(value);
        const formattedDate = date.format('hh:mm A - MMM DD, YYYY');
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: 'Message',
      dataIndex: 'msg',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: {
        compare: createTableSorter('msg'),
      },
      render: (value, record) => (
        <AssetEventMessageColumn
          record={record}
          currentEventForModal={currentEventForModal}
        />
      ),
    },
  ];

  const displayedData = eventsState?.isPending
    ? loadingKeyedTableData
    : formattedAssetEvents;

  const displayedColumns = eventsState?.isPending
    ? ASSET_EVENTS_TABLE_LOADING_COLUMNS
    : assetEventTableColumns;

  const getEventsRequestBody = {
    filters: [],
    key: currentAsset?.asset_id,
    ...sharedRequestBody(currentAsset),
    unit_number: currentAsset?.asset_name,
  };

  const getRecipientsRequestBody = {
    rule_uuid: currentEventForModal?.rule_uuid,
  };

  useEffect(() => {
    if (currentAsset) {
      if (eventsState?.eventList?.[0]?.asset_id !== currentAsset?.asset_id) {
        fetchEvents(getEventsRequestBody, eventsDispatch, currentSession);
      }
    }
  }, [currentAsset]);

  useEffect(() => {
    if (!searchString) {
      setFormattedAssetEvents(defaultAssetEvents);
    } else handleFiltering();
  }, [searchString]);

  useEffect(() => {
    if (finalizedDates?.length) {
      handleDateChange();
    }
  }, [finalizedDates]);

  useEffect(() => {
    if (eventsState?.eventList?.length) {
      const formattedEvents = formatAssetEvents(eventsState.eventList);

      setDefaultAssetEvents(formattedEvents);
      setFormattedAssetEvents(formattedEvents);
    }
  }, [eventsState]);

  useEffect(() => {
    if (recipientState?.eventRecipients?.length) {
      setCurrentRecipientsForModal(recipientState.eventRecipients);
    }
  }, [recipientState]);

  useEffect(() => {
    fetchRecipientInfo(getRecipientsRequestBody, recipientDispatch, currentSession);
  }, [currentEventForModal]);

  return (
    <section className="asset-events-page-container">
      <div className="asset-events-page">
        <AssetEventRecipientModal
          isOpen={showModal}
          closeModal={clearEventCloseModal}
          recipients={currentRecipientsForModal}
          isPending={recipientState.isPending}
        />
        <AssetEventsSearchForm
          finalizedDates={finalizedDates}
          setSearchString={setSearchString}
          handleClearDates={handleClearDates}
          setFinalizedDates={setFinalizedDates}
          formattedAssetEvents={formattedAssetEvents}
        />
        <AssetEventsTable
          data={displayedData}
          columns={displayedColumns}
          rowClassName={rowClassName}
          handleClick={handleRowClick}
        />
      </div>
    </section>
  );
};

export default EventsPage;

import { getUserPreferences, updateUserPreferences } from 'shared/repositories/accountSettingsRepository';
import * as actionTypes from 'account-settings/actions/accountSettingsActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';

export const fetchUserPreferences = asyncActionCreator({
  repositoryAPI: getUserPreferences,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_PREFERENCES_SUCCESS,
});

export const editUserPhoneNumber = asyncActionCreator({
  repositoryAPI: updateUserPreferences,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_PHONE_SUCCESS,
});

export const editUserEmailSubscription = asyncActionCreator({
  repositoryAPI: updateUserPreferences,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_SUCCESS,
});

export const editUserSubscriptionPlatform = asyncActionCreator({
  repositoryAPI: updateUserPreferences,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_NOTIFICATION_RECEIVE_PLATFORM_SUCCESS,
  responseTransformer: (result, body) => ({
    ...result,
    bodyObj: body,
  }),
});

export const editUserDnd = asyncActionCreator({
  repositoryAPI: updateUserPreferences,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_DO_NOT_DISTURB_SUCCESS,
  responseTransformer: (result, body) => ({
    ...result,
    body,
  }),
});

export const resetUpdateStatus = () => ({
  type: actionTypes.ACCOUNT_SETTINGS__RESET_UPDATE_STATUS,
});

export const resetPreferenceFetchAttempts = () => ({
  type: actionTypes.ACCOUNT_SETTINGS__RESET_SUBSCRIPTION_FETCH_ATTEMPTS,
});

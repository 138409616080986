import React from 'react';

import FluxCustomDropdown from 'shared/components/FluxCustomDropdown';

import { CARBON_CAPTURE_EQUATIONS_TITLE } from 'single-asset/constants/carbonCaptureConstants';

import EquationTable from './EquationTable';

const EquationBreakdownRow = ({
  methaneGwp,
  showEquationBreakdownRow,
  handleEquationBreakdownToggle,
}) => (
  <div className="carbon-capture-equations-row">
    <FluxCustomDropdown
      isOpen={showEquationBreakdownRow}
      header={CARBON_CAPTURE_EQUATIONS_TITLE}
      toggleDropdown={handleEquationBreakdownToggle}
    >
      <EquationTable methaneGwp={methaneGwp} />
    </FluxCustomDropdown>
  </div>
);

export default EquationBreakdownRow;

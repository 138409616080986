import { extendedDayJs } from 'shared/helpers/timeHelpers';

export const formatAssetEvents = (events) => events.map((entry) => (
  {
    ...entry,
    value: 0,
    x: extendedDayJs(entry?.epoch_milliseconds),
    key: `${entry?.rule_uuid} ${entry?.epoch_milliseconds}`,
  }
));

export const filterEventDateRange = (events, startDate, endDate) => events?.filter((event) => {
  const date = event?.epoch_milliseconds;
  const formattedDate = extendedDayJs(date).format('MM/DD/YYYY');

  return formattedDate === endDate
    || formattedDate === startDate
    || extendedDayJs(formattedDate).isBetween(startDate, endDate);
});

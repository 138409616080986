import { formatDateStringsForCharts } from 'single-asset/helpers/singleAssetHelpers';

export const transformChartSeriesRequest = (
  _,
  {
    dates,
    fromCharts,
    config: { series },
    ...restOfProps
  },
) => {
  const [startDate, stopDate] = dates;

  return {
    start: startDate,
    stop: stopDate,
    series: series.map((tag) => ({
      ...tag,
      ...addAssetProps(restOfProps),
    })),
  };
};

export const transformChartSeriesResponse = (seriesResult, seriesConfig) => ({
  seriesResult,
  seriesConfig,
});

export const transformAssetProps = ({
  orgId: org_id,
  siteId: site_id,
  assetId: asset_id,
  deviceId: device_id,
}) => ({
  org_id,
  site_id,
  asset_id,
  device_id,
});

export const addAssetProps = ({
  org_id,
  site_id,
  asset_id,
  device_id,
}) => ({
  org_id,
  site_id,
  asset_id,
  device_id,
});

export const transformAltitudeEventsRequest = (
  _,
  {
    filters = [],
    dates,
    ...restOfProps
  },
) => {
  const [start, end] = formatDateStringsForCharts(dates);
  return {
    filters,
    ...addAssetProps(restOfProps),
    start,
    end,
  };
};

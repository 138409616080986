import * as actionTypes from 'single-asset/actions/constants/enterpriseObjectActionTypes';

export const defaultState = {
  isPending: false,
  enterpriseObject: null,
  errorResponse: null,
};

const enterpriseObjectReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.ENTERPRISE_OBJECT__FETCH: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case actionTypes.ENTERPRISE_OBJECT__FETCH_FAILED: {
      const { errorResponse } = action;

      return {
        ...defaultState,
        errorResponse,
      };
    }

    case actionTypes.ENTERPRISE_OBJECT__FETCH_SUCCEEDED: {
      return {
        ...defaultState,
        enterpriseObject: action.result.assets.at(0),
      };
    }

    case actionTypes.ENTERPRISE_OBJECT__UPDATE_IP: {
      return {
        ...state,
        enterpriseObject: {
          ...state.enterpriseObject,
          ipAddress: action.ipAddress,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export const selectEnterpriseObject = ({ singleAsset: { enterpriseObject } }) => enterpriseObject;

export default enterpriseObjectReducer;

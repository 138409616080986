import * as dashboardFilterTypes from 'dashboard/actions/dashboardFilterActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';
import { fetchAltitudeFilterGroups } from 'shared/repositories/filterGroupsRepository';

export const fetchFilterGroups = asyncActionCreator({
  repositoryAPI: fetchAltitudeFilterGroups,
  PENDING_ACTION_TYPE: dashboardFilterTypes.FILTER_GROUPS__FETCH,
  SUCCESS_ACTION_TYPE: dashboardFilterTypes.FILTER_GROUPS__FETCH_SUCCESS,
  FAILED_ACTION_TYPE: dashboardFilterTypes.FILTER_GROUPS__FETCH_FAILED,
});

export const resetFilterFetchAttempts = () => ({
  type: dashboardFilterTypes.FILTER_GROUPS__RESET_FETCH_ATTEMPTS,
});

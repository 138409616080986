import { getUserSubscriptions, updateUserSubscriptions } from 'shared/repositories/accountSettingsRepository';
import * as actionTypes from 'account-settings/actions/accountSettingsActionTypes';
import { asyncActionCreator } from 'shared/helpers/actionHelpers';

export const fetchUserSubscriptions = asyncActionCreator({
  repositoryAPI: getUserSubscriptions,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__FETCH_SUBSCRIPTIONS_SUCCESS,
});

export const editUserSubscriptions = asyncActionCreator({
  repositoryAPI: updateUserSubscriptions,
  PENDING_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS,
  FAILED_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_FAILED,
  SUCCESS_ACTION_TYPE: actionTypes.ACCOUNT_SETTINGS__UPDATE_SUBSCRIPTIONS_SUCCESS,
  responseTransformer: (result, body) => ({
    ...result,
    subscriptions: body.subscriptions,
  }),
});

export const resetSubscriptionFetchAttempts = () => ({
  type: actionTypes.ACCOUNT_SETTINGS__RESET_SUBSCRIPTION_FETCH_ATTEMPTS,
});

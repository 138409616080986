import React, { useState, useRef, useEffect } from 'react';
import { IoCheckmark, IoChevronDown, IoChevronUp } from 'react-icons/io5';

import { DEFAULT_OPTION_DESCRIPTOR_TEXT } from 'shared/constants/fluxSelectConstants';

import LoadingSpinner from 'single-asset/components/carbon-capture-calculator/asset-header/LoadingSpinner';

import ResetSelectButton from './ResetSelectButton';

import './FluxSelect.scss';

const FluxSelect = ({
  options,
  handleChange,
  defaultOption,
  width = '550px',
  shadowed = true,
  showLoading = false,
  showResetButton = false,
  // eslint-disable-next-line no-console
  handleResetClick = () => console.log('Reset'),
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectOption, setSelectedOption] = useState(defaultOption);

  const handleReset = (e) => {
    e.stopPropagation();
    handleResetClick();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelect = (option) => {
    handleChange(option);
    setSelectedOption(option);

    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      style={{ width }}
      ref={dropdownRef}
      className={shadowed ? 'flux-select-shadowed' : 'flux-select'}
    >
      <div
        className="flux-select-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h6 className="flux-select-label">
          {selectOption?.label}
          {showLoading
            ? <LoadingSpinner size={25} />
            : isOpen
              ? <IoChevronUp />
              : <IoChevronDown />}
        </h6>
        {showResetButton
            && <ResetSelectButton handleClick={handleReset} />}
      </div>
      {isOpen && (
        <div className="flux-select-dropdown">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleSelect(option)}
              className={selectOption?.label === option?.label
                ? 'flux-select-option-selected'
                : 'flux-select-option'}
            >
              <span className="flux-select-option-label">
                {option?.dropdownLabel || option?.label}
              </span>
              {selectOption?.label === option?.label
                  && <IoCheckmark />}
              {option?.label === defaultOption?.label
                  && (
                    <span className="flux-select-default-label">
                      {DEFAULT_OPTION_DESCRIPTOR_TEXT}
                    </span>
                  )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FluxSelect;

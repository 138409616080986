// handles spaces and hyphens
const toCamelCase = (str) => str.split(/[-\s]/).map((word, index) => {
  if (index === 0) return word.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}).join('');

const createDetails = (name, details) => {
  const camelName = toCamelCase(name);
  const percent = details?.[`${camelName}Pct`];
  const flared = details?.[`${camelName}FlaredCo2Mt`];
  return {
    key: name,
    label: name,
    info: flared,
    percent,
  };
};

export const generateDataRows = (details) => [
  'Carbon Dioxide',
  'Nitrogen',
  'Methane',
  'Ethane',
  'Propane',
  'Butane',
  'I-Butane',
  'Pentane',
  'I-Pentane',
  'Hexane',
  'TOTAL',
].map((name) => {
  if (name === 'TOTAL') {
    return {
      key: 'TOTAL',
      label: 'TOTAL',
      info: details?.totalFlaredCo2Mt,
      percent: '1',
    };
  }
  return createDetails(name, details);
});

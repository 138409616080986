import { fetchEventsByAltitude } from 'shared/repositories/axilEventsRepository';
import * as telemetryEventsActionTypes from 'single-asset/actions/telemetryEventsActionTypes';
import { asyncHookActionCreator } from 'shared/helpers/actionHelpers';
import { selectAsset } from 'single-asset/reducers/assetReducer';

export const fetchEvents = asyncHookActionCreator({
  PENDING_ACTION_TYPE: telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS,
  SUCCESS_ACTION_TYPE: telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryEventsActionTypes.TELEMETRY_EVENTS__FETCH_EVENTS_FAILED,
  repositoryAPI: fetchEventsByAltitude,
  selector: selectAsset,
});

import * as actionTypes from 'single-asset/actions/moreInfoImageActionTypes';

export const defaultState = {
  unitProfileImage: {},
  isPending: false,
  errorResponse: null,
};

const moreInfoImageReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.MORE_INFO__IMAGE_FETCH: {
      return {
        ...defaultState,
        isPending: true,
      };
    }

    case actionTypes.MORE_INFO__IMAGE_FETCH_FAILED: {
      const { errorResponse } = action;
      return {
        ...defaultState,
        isPending: false,
        errorResponse,
      };
    }

    case actionTypes.MORE_INFO__IMAGE_FETCH_SUCCEEDED: {
      return {
        ...defaultState,
        isPending: false,
        unitProfileImage: action.result,
      };
    }

    default: {
      return state;
    }
  }
};

export default moreInfoImageReducer;

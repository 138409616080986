import * as telemetryStatsActionTypes from 'single-asset/actions/telemetryStatsActionTypes';
import { createStatsReducer } from 'single-asset/helpers/singleAssetHelpers';
import { defaultStatsState as defaultState } from 'single-asset/constants/singleAssetConstants';

export { defaultState };

const wellStatsReducer = createStatsReducer({
  defaultState,
  PENDING_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST,
  SUCCESS_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_SUCCEEDED,
  FAILED_ACTION_TYPE: telemetryStatsActionTypes.GASLIFT_WELL_STATS__FETCH_STATS_LIST_FAILED,
});

export default wellStatsReducer;

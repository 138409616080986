import { repositoryFetch } from 'shared/helpers/repositoryHelpers';
import { DEFAULT_SERIES_DATA_VERSION } from 'shared/constants/repositoryConstants';

export const axilSeriesByDateEndpoint = 'axil/series/hot/by_date';

export const fetchSeriesByDate = ({
  accessToken,
  body,
  version = DEFAULT_SERIES_DATA_VERSION,
}) => {
  const queryParams = body.series.map((serie) => serie?.label?.toLowerCase()?.split(' ')?.join('-') ?? 'unknown');
  return repositoryFetch({
    accessToken,
    body,
    endpoint: `/v${version}/${axilSeriesByDateEndpoint}?label=${queryParams}`,
  });
};

export const getChartMetrics = ({
  accessToken,
  body,
}) => repositoryFetch({
  accessToken,
  body,
  endpoint: '/v2/axil/widgets/builder/custom_chart',
});

export const modifyTelemetryCharts = ({
  accessToken,
  body,
}) => repositoryFetch({
  accessToken,
  body,
  endpoint: '/v1/axil/layouts/save',
});

import React from 'react';
import { IoAdd } from 'react-icons/io5';
import { Button as AntButton } from 'antd';

import { Button } from '@flogistix/flo-ui';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  APPLY_TEMPLATE_TOOLTIP,
  NEW_CHART_DROPDOWN_HEADER,
  NEW_CHART_DROPDOWN_SEARCH_PLACEHOLDER,
} from 'single-asset/constants/telemetryConstants';
import AddChartDropdown from 'single-asset/components/charts/edit-charts/AddChartDropdown';

const EditChartsSection = ({
  metricSearch,
  metricOptions,
  newChartIsOpen,
  setMetricSearch,
  setIsEditingCharts,
  handleChartCreation,
  metricDropdownValues,
  handleDropdownToggle,
  handleMetricSelection,
  setConfirmModalIsOpen,
}) => (
  <section>
    <AddChartDropdown
      isOpen={newChartIsOpen}
      searchValue={metricSearch}
      style={{ marginLeft: '275px' }}
      confirmAction={handleChartCreation}
      cancelAction={handleDropdownToggle}
      onItemSelect={handleMetricSelection}
      dropdownValue={metricDropdownValues}
      itemHeader={NEW_CHART_DROPDOWN_HEADER}
      searchPlaceholder={NEW_CHART_DROPDOWN_SEARCH_PLACEHOLDER}
      onSearchChange={({ target: { value } }) => setMetricSearch(value.toLowerCase())}
      dropdownItems={metricOptions?.filter((option) => option.label.toLowerCase().includes(metricSearch))}
    />
    <div className="chart-form-button-row">
      <AntButton
        onClick={() => setConfirmModalIsOpen(true)}
        className="chart-form--apply-template-button"
      >
        <div>
          Apply layout to all Assets
          <FluxTooltip {...APPLY_TEMPLATE_TOOLTIP} />
        </div>
      </AntButton>
      <Button
        variation="black-outline"
        style={{ marginRight: '8px' }}
        onClick={() => setIsEditingCharts(true)}
      >
        Edit Charts
      </Button>
      <Button
        onClick={(e) => {
          e.preventDefault();
          handleDropdownToggle(true);
        }}
      >
        <IoAdd
          size={16}
          style={{ marginRight: '4px', verticalAlign: 'text-top' }}
        />
        Add Chart
      </Button>
    </div>
  </section>
);

export default EditChartsSection;

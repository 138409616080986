import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { IoAddOutline, IoInformationCircleOutline, IoRemoveOutline } from 'react-icons/io5';

import FluxTooltip from 'shared/components/FluxTooltip';

import {
  CARBON_CAPTURE_TOOLTIP_TITLE,
  CARBON_CAPTURE_TOTAL_ABATED_TITLE,
  CARBON_CAPTURE_FLARED_EMISSIONS_LABEL,
  CARBON_CAPTURE_METHANE_EMISSIONS_LABEL,
  CARBON_CAPTURE_DRIVER_EMISSIONS_LABEL,
} from 'single-asset/constants/carbonCaptureConstants';
import MathCard from 'single-asset/components/carbon-capture-calculator/modal/components/MathCard';
import MethaneBreakdownCard from 'single-asset/components/carbon-capture-calculator/modal/components/MethaneBreakdownCard';
import EmissionBreakdownCard from 'single-asset/components/carbon-capture-calculator/modal/components/EmissionBreakdownCard';

import './EmissionsResultsFunctionCard.scss';

const EmissionsResultsFunctionCard = ({
  tooltipText,
  handleClose,
  currentCarbonDetails,
}) => {
  const [showFlaredBreakdown, setShowFlaredBreakdown] = useState(false);
  const [showMethaneBreakdown, setShowMethaneBreakdown] = useState(false);

  const toggleFlaredBreakdown = () => {
    setShowFlaredBreakdown(!showFlaredBreakdown);
  };

  const toggleMethaneBreakdown = () => {
    setShowMethaneBreakdown(!showMethaneBreakdown);
  };

  const MathCards = () => (
    <div className="emissions-results">
      <MathCard
        showDownArrow
        handleClick={toggleFlaredBreakdown}
        label={CARBON_CAPTURE_FLARED_EMISSIONS_LABEL}
        value={currentCarbonDetails.totalFlaredCo2Mt.toFixed(4)}
      />
      <IoAddOutline />
      <MathCard
        showDownArrow
        handleClick={toggleMethaneBreakdown}
        label={CARBON_CAPTURE_METHANE_EMISSIONS_LABEL}
        value={currentCarbonDetails.methaneCo2eMt.toFixed(4)}
      />
      <IoRemoveOutline />
      <MathCard
        label={CARBON_CAPTURE_DRIVER_EMISSIONS_LABEL}
        value={currentCarbonDetails.driveEmissions.toFixed(4)}
      />
    </div>
  );

  return (
    <section className="emissions-results-function-container">
      <div className="emissions-results-header">
        <section className="emissions-total-info">
          <span className="emissions-number">
            {currentCarbonDetails.netAbated.toFixed(4)}
          </span>
          <div className="emissions-description">
            <p>{CARBON_CAPTURE_TOTAL_ABATED_TITLE}</p>
            <FluxTooltip
              showInfoCircle={false}
              description={tooltipText}
              title={CARBON_CAPTURE_TOOLTIP_TITLE}
              tooltip={(
                <IoInformationCircleOutline />
              )}
            />
          </div>
        </section>
        <CloseOutlined
          onClick={handleClose}
          className="emissions-row-close"
        />
      </div>
      {!showFlaredBreakdown && !showMethaneBreakdown && <MathCards />}
      {showFlaredBreakdown && (
        <EmissionBreakdownCard
          handleClose={toggleFlaredBreakdown}
          currentCarbonDetails={currentCarbonDetails}
        />
      )}
      {showMethaneBreakdown
        && (
          <MethaneBreakdownCard
            handleClose={toggleMethaneBreakdown}
            currentCarbonDetails={currentCarbonDetails}
          />
        )}
    </section>
  );
};

export default EmissionsResultsFunctionCard;

import { v4 as uuidV4 } from 'uuid';
import {
  AGGREGATION_VALUES, CHART_METRIC_COLORS, DASHBOARD_ID, DEFAULT_CHART_TYPE, EVENT_METRIC_MAP, NON_EVENT_METRIC_MAP,
} from 'single-asset/constants/telemetryConstants';

export const flattenLegacyLayoutResponse = ({
  xl = [{ i: 'Telemetry Layout', children: [{ children: [] }, { children: [] }, { children: [] }] }],
  fluxCharts = [],
}) => {
  const { children: legacyChildren } = xl.find((template) => template.i === 'Telemetry Layout');
  const { children: chartsChildren } = legacyChildren.find((template) => template.i === 'Charts Layout');

  return ({
    chartsConfig: chartsChildren?.filter(({ widget }) => widget === 'WidgetSeries')?.map(({ options }) => ({ ...options, uuid: uuidV4() })),
    fluxCharts: (fluxCharts.length ? fluxCharts.map((entry) => ({ ...entry, uuid: uuidV4() })) : null),
  });
};

export const createNewChartSeriesConfiguration = (newChartMetrics) => {
  const newChartSeriesConfig = newChartMetrics.reduce((agg, newMetric) => {
    const newSeries = {
      tag_id: newMetric.tag_id,
      label: newMetric.name,
      agg: (newMetric.type !== 'event' ? AGGREGATION_VALUES : null),
      map: (newMetric.type !== 'event' ? NON_EVENT_METRIC_MAP : EVENT_METRIC_MAP),
      seriesProps: {
        lineColor: CHART_METRIC_COLORS[agg.length],
        type: DEFAULT_CHART_TYPE,
      },
    };
    return [...agg, newSeries];
  }, []);
  return { config: { series: newChartSeriesConfig } };
};

export const combineChartLabels = (data) => data.map((singleSeries) => singleSeries.label).join('');

export const buildPartialLayoutBody = ({
  asset_id,
  device_id,
  site_id,
  org_id,
  asset_type_id,
}) => ({
  asset_id: asset_id.toString(),
  device_id: device_id.toString(),
  site_id: site_id.toString(),
  org_id: org_id.toString(),
  asset_type_id: asset_type_id ?? -1,
  dashboard_id: DASHBOARD_ID,
});

export const partialTemplateLayoutPayload = ({
  asset_id: -1,
  device_id: -1,
  site_id: -1,
  org_id: -1,
  dashboard_id: DASHBOARD_ID,
});

import { getAssetStatusClassAndTooltip } from 'shared/helpers/altitudeHelpers';
import { ASSET_TYPE_NAMES } from 'shared/constants/altitudeConstants';
import FluxTooltip from 'shared/components/FluxTooltip';

const AssetDetails = ({ enterpriseObject }) => {
  const [assetStatusClass, connectivityTooltip] = enterpriseObject
    ? getAssetStatusClassAndTooltip(enterpriseObject?.ipAddress, enterpriseObject?.connectivityInfo?.status)
    : getAssetStatusClassAndTooltip('unknown', 'unknown');
  return (
    <div className="detail-section">
      <div className="detail-group">
        <div className="asset-text">Asset</div>
        <div className="detail-section--detail">
          <div className="detail-section--detail-name">{enterpriseObject.name}</div>
          <FluxTooltip
            title={connectivityTooltip.title}
            description={connectivityTooltip.description}
            tooltip={<div className={`detail-section--detail-status ${assetStatusClass}`} />}
            showInfoCircle={false}
            placement="bottom"
            arrow={false}
          />
        </div>
        <div className="detail-section--additional-info">
          <div className="detail-section--additional-info-type">{ASSET_TYPE_NAMES[enterpriseObject.driver] ?? 'Unknown Asset Type'}</div>
          <div className="detail-section--additional-info-separator" />
          <div className="detail-section--additional-info-model">{enterpriseObject.packageModel ?? 'Unknown Asset Model'}</div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetails;

import { repositoryFetch } from 'shared/helpers/repositoryHelpers';

export const getUserReportingAccess = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: '/iam/groups/reporting',
  isAxil: true,
  method: 'GET',
});

export const getAvailableReports = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: '/reporting?app=flux',
  isAxil: true,
  method: 'GET',
});

export const getUserOrganizations = ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: '/iam/orgs',
  isAxil: true,
  method: 'GET',
});

export const getOrganizationAreas = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: `/iam/orgs/${body}/assets`,
  method: 'GET',
  isAxil: true,
});

export const postImmediateReport = ({ accessToken, reportName, body }) => repositoryFetch({
  accessToken,
  endpoint: `/reporting/immediate/${reportName}`,
  isAxil: true,
  body,
});

export const submitScheduledReport = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: '/reporting/schedules',
  isAxil: true,
  body,
});

export const putScheduledReport = ({ accessToken, body }) => repositoryFetch({
  accessToken,
  endpoint: `/reporting/schedules/${body.userReportName.replaceAll(' ', '%20')}`,
  isAxil: true,
  method: 'PUT',
  body,
});

export const postScheduledReport = async ({ accessToken, body }) => {
  const response = {};
  try {
    const reqRes = await submitScheduledReport({ accessToken, body });
    response.status = reqRes.status;
    const bodyResponse = await reqRes.json();
    response.createdReport = body;
    response.message = bodyResponse.message;
  } catch (err) {
    response.status = err.status;
    response.message = 'Something went wrong';
  }
  return response;
};

export const editScheduledReport = async ({ accessToken, body }) => {
  const response = {};
  try {
    const reqRes = await putScheduledReport({ accessToken, body });
    response.status = reqRes.status;
    const responseBody = await reqRes.json();
    response.editedReport = body;
    response.message = responseBody.message;
  } catch (err) {
    response.status = err.status;
    response.message = 'Something went wrong';
  }
  return response;
};

export const getScheduledReports = async ({ accessToken }) => repositoryFetch({
  accessToken,
  endpoint: '/reporting/schedules',
  isAxil: true,
  method: 'GET',
});

export const deleteScheduledReport = ({ accessToken, userReportName }) => repositoryFetch({
  accessToken,
  endpoint: `/reporting/schedules/${userReportName.replaceAll(' ', '%20')}`,
  isAxil: true,
  method: 'DELETE',
});
